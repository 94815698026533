import React, { useEffect, useState } from "react";
import { Dropdown, Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import flecha from "../../assets/flecha-verde.png";
import llaveRoja from "../../assets/llave-roja.png";
import llaveVerde from "../../assets/llave-verde.png";
import triangleGreen from "../../assets/triangle-green.svg";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import ScenarioBar from "../../components/ScenarioBar";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	alertQuizAlreadyAnswered,
	modalBiblioReferences,
	modalCorrectIncorrectAnswerP4,
} from "../../utils/logger";

import { useSelector } from "react-redux";
import { getAnswerByQuizAndParticipant, postAnswer } from "../../api/answer";
import flechas from "../../assets/flechas.svg";
import iconPreguntaExtra from "../../assets/icono-pregunta-extra.png";
import NextButton from "../../components/NextButton";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import {
	getParticipantId,
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";

function Pregunta4Extra({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const [intentosRestantes, setIntentosRestantes] = useState(1);
	const [checkedAnswers, setCheckedAnswers] = useState(false);

	const WRONG_POINTS = 50;

	const SCENARIO_ID = SCENARIOS.SCENARIO_4;
	const QUIZ_ID = QUIZZES.SCENARIO4_ETIQUETAS;
	const NAVIGATE_TO = ROUTES.P4_EXTRA_VF;

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Acharya KR, Ackerman SJ.
					<span class="regular">
						Eosinophil granule proteins: form and function. J Biol
						Chem. 2014;289(25):17406-15.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Persson EK, Verstraete K, Heyndrickx I, et al.
					<span class="regular">
						Protein crystallization promotes type 2 immunity and is
						reversible by antibody treatment. Science.
						2019;364(6442):eaaw4295.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Bachert C, Marple B, Schlosser RJ, et al.
					<span class="regular">
						Adult chronic rhinosinusitis. Nat Rev Dis Primers.
						2020;6(1):86.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Lu Y, Huang Y, Li J, et al.
					<span class="regular">
						Eosinophil extracellular traps drive asthma progression
						through neuro-immune signals. Nat Cell Biol.
						2021;23(10):1060-72.
					</span>
				</span>
			</li>
		</ol>`;

	const [dropdownOptions, setDropwdownOptions] = useState([
		{
			id: 1,
			value: `<span class="bold">Efecto directo:</span> Activar a las células dendríticas para que inicien <span class="bold">respuestas adaptativas T2</span> (alérgicas)`,
			selectedDropdownId: null,
			correctDropdownId: 1,
		},
		{
			id: 2,
			value: `<span class="bold">Efecto directo:</span> Mediar la metaplasia a células caliciformes de las células ciliadas <span class="bold">(remodelación tisular e hipersecreción de moco)</span>`,
			selectedDropdownId: null,
			correctDropdownId: 3,
		},
		{
			id: 3,
			value: `<span class="bold">Efecto indirecto:</span> Aumentar la viscosidad del moco bronquial`,
			selectedDropdownId: null,
			correctDropdownId: 2,
		},
	]);

	const time = useState(getTimer());

	useEffect(() => {
		getAnswerByQuizAndParticipant(QUIZ_ID, getParticipantId())
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					alertQuizAlreadyAnswered({}).then((res) => {});
					setCheckedAnswers(true);
				}
			})
			.catch((error) => {});
	}, [location]);

	async function submitAnswer({ correct, score, penalty }) {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: score,
			time: parseInt(totalTime) - parseInt(time),
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					let penaltyScore = penalty || 0;

					dispatch(updateScore(score, SCORE_TYPES.SCORE));
					dispatch(
						updateScore(
							-Math.abs(penaltyScore),
							SCORE_TYPES.PENALTY
						)
					);

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						penalty: participant?.penalty - Math.abs(penaltyScore),
						lastVisited: NAVIGATE_TO,
					});
					setCheckedAnswers(true);
				}
			})
			.catch((error) => {});
	}

	function checkAnswers() {
		let correct = true;
		let loosedPoints = 0;

		dropdownOptions.forEach((option) => {
			if (option.selectedDropdownId !== option.correctDropdownId) {
				correct = false;
				loosedPoints += WRONG_POINTS;
			}
		});

		let text = correct
			? `<div>
				<p class="txt16">
					Has atribuido correctamente los efectos a sus mediadores.
				</p>
			</div>`
			: `<div>
				<p class="txt16">Esta es la solución correcta:</p>
			</div>`;

		modalCorrectIncorrectAnswerP4({
			title: correct ? "¡Muy bien!" : "Hay errores",
			textHtml: text,
			loosedPoints: loosedPoints,
			correct: correct,
		}).then((res) => {
			if (res.isConfirmed) navigate(NAVIGATE_TO);
		});

		submitAnswer({
			correct: correct,
			score: correct ? 0 : -loosedPoints,
			penalty: loosedPoints,
		});

		setIntentosRestantes(intentosRestantes - 1);
	}

	const DropdownComponent = ({ dropdownId }) => {
		const handleOptionSelect = (selectedId) => {
			let updatedOptions = dropdownOptions.map((o) =>
				o.id.toString() === selectedId.toString()
					? {
							...o,
							selectedDropdownId: dropdownId,
					  }
					: o.selectedDropdownId?.toString() === dropdownId.toString()
					? {
							...o,
							selectedDropdownId: null,
					  }
					: { ...o }
			);

			setDropwdownOptions(updatedOptions);
		};

		const value = dropdownOptions.find(
			(x) => x.selectedDropdownId === dropdownId
		)?.value;

		return (
			<Dropdown onSelect={handleOptionSelect}>
				<Dropdown.Toggle className="txt16">
					<div className="d-flex w-100 justify-content-between align-items-center">
						<div
							className={`dropdown-text regular txt11 ${
								!value ? "d-flex align-items-center" : ""
							} ${value?.length < 160 ? "pt-2" : ""}`}
							dangerouslySetInnerHTML={{
								__html: value || "Escoge una opción",
							}}
						/>
						<img
							className="ms-2"
							src={triangleGreen}
							width={20}
							alt="arrow"
						/>
					</div>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{dropdownOptions.map((option, index) => (
						<Dropdown.Item
							className="py-2 px-2 dropdown-item"
							key={index}
							eventKey={option.id}
							dangerouslySetInnerHTML={{
								__html: option.value,
							}}
						></Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	return (
		<div className="h-100 bg-pregunta-extra">
			<div className="container">
				<div className="row h-100">
					<div className="col-11 pt-2">
						<div className="d-flex flex-column justify-content-center align-items-center">
							<div className="d-flex align-items-start">
								<Image
									className="me-2"
									src={iconPreguntaExtra}
									width={52}
								></Image>
								<div>
									<div className="txt26 bold text-red">
										PRUEBA PUNTOS EXTRA
									</div>
									<div
										className="txt16 bold"
										style={{ maxWidth: "650px" }}
									>
										¿Sabrías etiquetar los efectos de estos
										mediadores liberados por los
										eosinófilos? Despliega el menú y escoge
										en cada caso la opción más adecuada y
										pulsa COMPROBAR
									</div>
								</div>
							</div>
						</div>
						<div className="d-flex flex-column justify-content-center align-items-center text-center pt-5">
							<div className="d-flex align-items-end">
								<div>
									<p
										className="bold my-0"
										style={{ marginLeft: "55%" }}
									>
										IL-5
									</p>
									<Image
										className="my-1"
										style={{ marginLeft: "55%" }}
										src={flecha}
										height={25}
									></Image>
									<p
										className="bold my-0"
										style={{ marginLeft: "40%" }}
									>
										Eosinófilo
									</p>
									<Image
										className="my-1"
										style={{ marginLeft: "55%" }}
										src={flecha}
										height={25}
									></Image>
									<p
										className="d-flex align-items-center"
										style={{ marginTop: "-15%" }}
									>
										<div className="bold">
											Cristales de Charcot-Leyden
										</div>
										<Image
											className="ms-4"
											src={llaveVerde}
											height={120}
										></Image>
									</p>

									<p
										className="d-flex align-items-center"
										style={{ marginTop: "-22%" }}
									>
										<div className="bold">
											Redes extracelulares de ADN
										</div>
										<Image
											className="ms-4"
											src={llaveRoja}
											height={120}
										></Image>
									</p>
								</div>
								<div className="ms-4 pb-3">
									<DropdownComponent
										dropdownId={1}
									></DropdownComponent>
									<div className="mt-2">
										<DropdownComponent
											dropdownId={2}
										></DropdownComponent>
									</div>
									<div className="mt-2">
										<DropdownComponent
											dropdownId={3}
										></DropdownComponent>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-1">
						<div className="d-flex flex-column align-items-end">
							<LifeIndicatorBar
								numIntentos={intentosRestantes}
							></LifeIndicatorBar>
						</div>
					</div>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{ position: "absolute", bottom: "10vh" }}
				>
					<div className="d-flex align-items-end justify-content-between mt-3">
						<div>
							<div className="d-flex align-items-center mt-2 txt12">
								<div className="bg-wrong-points-info me-1">
									- {WRONG_POINTS}
								</div>
								puntos por error
							</div>
							<div
								className="txt12 underline text-red cursor-pointer mt-4"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
						</div>
						<div>
							{!checkedAnswers ? (
								!dropdownOptions.some(
									(x) => x.selectedDropdownId === null
								) && (
									<button
										onClick={() => {
											checkAnswers();
										}}
										className="btn-iniciar p-2 px-4"
									>
										COMPROBAR
										<img
											className="ms-2"
											src={flechas}
										></img>
									</button>
								)
							) : (
								<NextButton
									navigateTo={NAVIGATE_TO}
								></NextButton>
							)}
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta4Extra);
