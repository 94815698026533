import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalBiblioReferences } from "../../utils/logger";

function Pregunta3Superada({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Persson EK, Verstraete K, Heyndrickx I, et al.
					<span class="regular">
						Protein crystallization promotes type 2 immunity and is
						reversible by antibody treatment. Science.
						2019;364(6442):eaaw4295.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Wang Z, Zhang S, Xiao Y, et al.
					<span class="regular">
						NLRP3 Inflammasome and Inflammatory Diseases. Oxid Med
						Cell Longev. 2020;2020:4063562.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Lambrecht BN, Hammad H.
					<span class="regular">
						The immunology of asthma. Nat Immunol. 2015;16(1):45-56.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Allen JE, Sutherland TE.
					<span class="regular">
						Crystal-clear Treatment for allergic disease. Science.
						2019;364(6442):738-9.
					</span>
				</span>
			</li>
		</ol>`;

	return (
		<>
			<div className="pt-4 h-100 bg-fin-escenario escenario-3">
				<div className="container h-100">
					<div className="col-12 text-red txt50 bold">
						¡Tercera parte superada!
					</div>

					<div className="row mt-3">
						<div className="col-6">
							<p className="txt16">
								El aumento de los niveles de IL5 en la vía aérea
								de María produce un aumento de cristales de
								Charcot-Leyden. Estos cristales activan los
								inflamasomas de las células dendríticas y de los
								macrófagos de la mucosa respiratoria
								<sup> 1,2</sup>, participando en la fase de
								sensibilización de la inflamación T2 alérgica
								<sup> 3,4</sup>.{" "}
								<span className="bold">
									Esto podría explicar en parte las
									exacerbaciones que ha presentado María.
								</span>
							</p>
							<p className="txt16 bold">
								María podría beneficiarse de Nucala®, que cuenta
								con efectividad demostrada en paciente con asma
								grave eosinofílica y alérgica.
							</p>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{
							position: "absolute",
							bottom: "12%",
						}}
					>
						<div className="d-flex align-items-center justify-content-between">
							<div
								className="txt12 underline text-red cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
							<div>
								<NextButton
									navigateTo={ROUTES.TRANSITION_SCENARIOS}
									navigateToAfter={ROUTES.P4_INTRO}
								/>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta3Superada);
