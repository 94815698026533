import {
	DndContext,
	MouseSensor,
	TouchSensor,
	useDraggable,
	useDroppable,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndParticipant, postAnswer } from "../../api/answer";
import iconArrastrar from "../../assets/arrastrar.png";
import flechaEsquema from "../../assets/flecha-esquema-2.png";
import flechas from "../../assets/flechas.svg";
import llave1 from "../../assets/flecha-esquema-1.png";
import llave2 from "../../assets/llave-esquema-1.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getParticipantId,
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertQuizAlreadyAnswered,
	modalCorrectIncorrectAnswer,
	modalIncorrectAnswerP1,
	modalQuestionInstructions,
} from "../../utils/logger";

function Pregunta1({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const touchSensor = useSensor(TouchSensor);
	const mouseSensor = useSensor(MouseSensor);

	const sensors = useSensors(touchSensor, mouseSensor);

	const ALERGICA_ID = 1;
	const INMUNIDAD_ADAPTATIVA_ID = 2;
	const REMODELACION_ID = 3;
	const INMUNIDAD_INNATA_ID = 4;
	const NO_ALERGICA_ID = 5;
	const EOSINOFILOS_ID = 6;
	const INMUNIDAD_INNATA_ID_2 = 7;

	const CORRECT_POINTS = 200;
	const EXTRA_POINTS = 100;

	const QUIZ_ID = QUIZZES.SCENARIO1_ALGORITMO_1;
	const SCENARIO_ID = SCENARIOS.SCENARIO_1;
	const NAVIGATE_TO = ROUTES.P1_PART2;

	const tagOptions = [
		{ id: ALERGICA_ID, value: "Alérgica" },
		{
			id: INMUNIDAD_ADAPTATIVA_ID,
			value: "Inmunidad adaptativa",
		},
		{ id: REMODELACION_ID, value: "Remodelación" },
		{ id: INMUNIDAD_INNATA_ID, value: "Inmunidad innata" },
		{ id: NO_ALERGICA_ID, value: "No alérgica" },
		{ id: EOSINOFILOS_ID, value: "Eosinófilos" },
		{ id: INMUNIDAD_INNATA_ID_2, value: "Inmunidad innata" },
	];

	const [tags, setTags] = useState(tagOptions);
	const OFFSET = tagOptions.length; //necessary for a proper behaviour of drag & drop library

	const [boxes, setBoxes] = useState([
		{
			id: ALERGICA_ID + OFFSET,
			tag: null,
			correctOptionsIds: [ALERGICA_ID],
		},
		{
			id: NO_ALERGICA_ID + OFFSET,
			tag: null,
			correctOptionsIds: [NO_ALERGICA_ID],
		},
		{
			id: INMUNIDAD_INNATA_ID + OFFSET,
			tag: null,
			correctOptionsIds: [INMUNIDAD_INNATA_ID, INMUNIDAD_INNATA_ID_2],
		},
		{
			id: INMUNIDAD_INNATA_ID_2 + OFFSET,
			tag: null,
			correctOptionsIds: [INMUNIDAD_INNATA_ID, INMUNIDAD_INNATA_ID_2],
		},
		{
			id: INMUNIDAD_ADAPTATIVA_ID + OFFSET,
			tag: null,
			correctOptionsIds: [INMUNIDAD_ADAPTATIVA_ID],
		},
		{
			id: EOSINOFILOS_ID + OFFSET,
			tag: null,
			correctOptionsIds: [EOSINOFILOS_ID],
		},
		{
			id: REMODELACION_ID + OFFSET,
			tag: null,
			correctOptionsIds: [REMODELACION_ID],
		},
	]);

	const [intentosRestantes, setIntentosRestantes] = useState(4);
	const [wrongOptions, setWrongOptions] = useState(null);
	const [quizCompleted, setQuizCompleted] = useState(null);

	const INTRUCCIONS = `<div>
			<p class="txt16">
				Aquí tienes una representación gráfica del proceso subyacente en
				la inflamación T2, pero… se han traspapelado las etiquetas. Coloca cada etiqueta en su lugar
			</p>
			<p>				
				<span class="txt16 bold text-red">
					4 INTENTOS.
				</span>
			</p>
		</div>`;

	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		quizCompleted ? null : 1000
	);

	useEffect(() => {
		getAnswerByQuizAndParticipant(QUIZ_ID, getParticipantId())
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					alertQuizAlreadyAnswered({}).then((res) => {});
					setQuizCompleted(true);
				}
			})
			.catch((error) => {
				modalQuestionInstructions({
					instruccionsTextHtml: INTRUCCIONS,
					questionNum: getScenarioByScreen(location.pathname),
				});
			});
	}, []);

	async function submitAnswer({ correct, score, bonus }) {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					let bonusScore = bonus || 0;
					dispatch(updateScore(score, SCORE_TYPES.SCORE));
					dispatch(updateScore(bonusScore, SCORE_TYPES.BONUS));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						bonus: participant?.bonus + Math.abs(bonusScore),
						lastVisited: NAVIGATE_TO,
					});
					setQuizCompleted(true);
				}
			})
			.catch((error) => {});
	}

	const TagBoxTarget = ({ boxId, tagId, defaultValue }) => {
		const box = boxes.find((x) => x.id === boxId + OFFSET);
		const tag = tags.find((x) => x.id === boxId);

		return box?.tag === null ? (
			<Droppable id={box?.id}>{tag?.value}</Droppable>
		) : (
			<Draggable id={box?.tag?.id}>{box?.tag?.value}</Draggable>
		);
	};

	function checkAnswers() {
		let normalizedBoxes = boxes.map((x) => ({ ...x, id: x.id - OFFSET }));
		let wrongOptions = normalizedBoxes.filter(
			(x) => !x.correctOptionsIds.includes(x.tag?.id)
		);

		setWrongOptions(wrongOptions);

		let correct = wrongOptions.length === 0;

		const finalScore =
			correct && intentosRestantes === 4
				? CORRECT_POINTS + EXTRA_POINTS
				: correct
				? CORRECT_POINTS
				: 0;

		const bonusScore = intentosRestantes === 4 ? EXTRA_POINTS : 0;

		if (correct) {
			let text = `<p class="txt16">
					Has resuelto ${
						intentosRestantes == 4
							? "al primer intento esta parte de la figura, pero fíjate en que la imagen está incompleta, falta un elemento fundamental…"
							: "esta parte de la figura, pero fíjate bien ¿no te falta un elemento fundamental?"
					}
				</p>`;
			modalCorrectIncorrectAnswer({
				title: intentosRestantes == 4 ? "¡Fantástico!" : "¡Ahora sí!",
				textHtml: text,
				points: finalScore,
				correct: true,
			}).then((res) => {
				if (res.isConfirmed) {
					navigate(NAVIGATE_TO);
				}
			});
		} else {
			if (intentosRestantes > 1) {
				let text = `<div>
						<p class="txt16">
							Hay <span class="text-red bold">${wrongOptions.length}</span>
							<span class="bold">etiquetas</span> colocadas
							erróneamente. Fíjate bien y prueba de nuevo.
						</p>
						<p class="txt16">
							Te quedan <span class="text-red bold">${intentosRestantes - 1}</span>
							<span class="bold">intentos</span>.
						</p>
					</div>`;
				modalCorrectIncorrectAnswer({
					title: "No es correcto",
					textHtml: text,
					allowBack: true,
					correct: false,
				});
			} else {
				let text = `<p class="txt16">
						Aquí tienes la figura correcta:
						<p class="txt16">
							Pero fíjate bien, ¿no te falta un elemento
							fundamental?
						</p>
					</p>`;
				modalIncorrectAnswerP1({
					title: "No es correcto",
					textHtml: text,
				}).then((res) => {
					if (res.isConfirmed) {
						navigate(NAVIGATE_TO);
					}
				});
			}

			const correctBoxes = boxes.map((b) =>
				b?.correctOptionsIds.includes(b?.tag?.id)
					? {
							...b,
					  }
					: { ...b, tag: null }
			);

			setBoxes(correctBoxes);

			const wrongTags = tagOptions.filter(
				(tagOption) =>
					!correctBoxes.some((x) => x?.tag?.id === tagOption.id)
			);

			setTags(wrongTags);
		}

		if (correct || intentosRestantes === 1)
			submitAnswer({
				correct: correct,
				score: finalScore,
				bonus: bonusScore,
			});

		setIntentosRestantes(intentosRestantes - 1);
	}

	const Draggable = (props) => {
		const { attributes, listeners, setNodeRef, transform } = useDraggable({
			id: props.id,
			disabled: false,
		});

		const style = {
			transform: CSS.Translate.toString(transform),
			lineHeight: "18px",
			zIndex: "10",
		};

		const box = boxes.find((x) => x.id === props?.id + OFFSET);

		const noDropped = tags.some((x) => x.id === props?.id);

		return (
			<div
				className={`col-lg-6 tag-box-p1 txt16 ${
					noDropped ? "me-2 mt-2" : ""
				}`}
				ref={setNodeRef}
				style={style}
				{...listeners}
				{...attributes}
			>
				{props.children}
			</div>
		);
	};

	const Droppable = (props) => {
		const { isOver, setNodeRef } = useDroppable({
			id: props.id,
			data: {
				origin: props.origin,
			},
		});

		const emptyTagOptionItem = props.origin;

		return (
			<>
				<div
					className={`tag-box-target-p1 ${
						emptyTagOptionItem ? "bg-transparent me-2 mt-2" : ""
					} txt16`}
					style={{
						lineHeight: "18px",
					}}
					ref={setNodeRef}
				>
					{props.children}
				</div>
			</>
		);
	};

	const handleDragEnd = ({ active, over }) => {
		window.scrollTo(0, 0);
		if (!active || !over) return;

		const draggedTag = tagOptions.find((x) => x.id === active?.id);
		const targetBox = boxes.find((x) => x.id === over?.id);

		const boxId = boxes.find((x) => x.tag?.id === draggedTag?.id)?.id;

		const updatedBoxes = boxes.map((b) =>
			b.id === targetBox?.id
				? {
						...b,
						tag: draggedTag,
				  }
				: b.id === boxId
				? { ...b, tag: null }
				: b
		);

		setBoxes(updatedBoxes);

		let updatedTags;

		if (over?.data?.current?.origin) {
			updatedTags = tagOptions.filter(
				(x) => tags.some((y) => y.id === x.id) || x.id === draggedTag.id
			);
		} else {
			updatedTags = tags.filter((x) => x.id !== draggedTag.id);
		}

		setTags(updatedTags);
	};

	return (
		<div className="h-100 bgprueba">
			<div className="container">
				<div className="row h-100">
					<DndContext
						onDragEnd={handleDragEnd}
						sensors={sensors}
						modifiers={[restrictToWindowEdges]}
					>
						<div className="col-5 ps-4 pe-5">
							<div className="row">
								{tagOptions?.map((tag, index) => {
									const isDropped = !tags.some(
										(x) => x?.id == tag?.id
									);
									return (
										<>
											{!isDropped ? (
												<Draggable id={tag.id}>
													{tag.value}
												</Draggable>
											) : (
												<Droppable
													id={tag.id}
													origin={true}
												>
													{
														tagOptions.find(
															(x) =>
																x.id === tag.id
														)?.value
													}
												</Droppable>
											)}
										</>
									);
								})}
							</div>
						</div>
						<div className="col-5">
							<div className="d-flex justify-content-center">
								<div className="bg-title">Inflamación T2</div>
							</div>
							<div className="d-flex justify-content-center">
								<img
									className="mb-1"
									src={llave1}
									width={"70%"}
								></img>
							</div>
							<div className="d-flex align-items-center justify-content-between">
								<div className="text-center me-2">
									<TagBoxTarget
										boxId={ALERGICA_ID}
										defaultValue="Alérgica"
									/>
									<img
										className="my-1"
										src={flechaEsquema}
										width={10}
									/>
								</div>
								<div className="text-center">
									<TagBoxTarget
										boxId={NO_ALERGICA_ID}
										defaultValue="No alérgica"
									/>
									<img
										className="my-1"
										src={flechaEsquema}
										width={10}
									/>{" "}
								</div>
							</div>
							<div className="d-flex align-items-start justify-content-between">
								<div className="text-center me-2">
									<TagBoxTarget
										boxId={INMUNIDAD_INNATA_ID}
										defaultValue="Inmunidad innata"
									/>
									<img
										className="my-1"
										src={flechaEsquema}
										width={10}
									/>{" "}
								</div>
								<TagBoxTarget
									boxId={INMUNIDAD_INNATA_ID_2}
									defaultValue="Inmunidad innata"
								/>
							</div>
							<div>
								<TagBoxTarget
									boxId={INMUNIDAD_ADAPTATIVA_ID}
									defaultValue="Inmunidad adaptativa"
								/>
							</div>
							<div className="d-flex justify-content-center">
								<img
									src={llave2}
									width={"70%"}
									className="mb-1"
									style={{ marginTop: "-22%" }}
								></img>
							</div>
							<div className="d-flex justify-content-center ms-4">
								<TagBoxTarget
									boxId={EOSINOFILOS_ID}
									defaultValue="Eosinófilos"
								/>
							</div>
							<div className="d-flex justify-content-center ms-4">
								<img
									className="my-1"
									src={flechaEsquema}
									width={10}
								/>{" "}
							</div>
							<div className="d-flex justify-content-center ms-4">
								<TagBoxTarget
									boxId={REMODELACION_ID}
									defaultValue="Remodelación"
								/>
							</div>
						</div>
						<div className="col-2">
							<div className="d-flex flex-column align-items-end">
								<LifeIndicatorBar
									numIntentos={intentosRestantes}
								></LifeIndicatorBar>
							</div>
						</div>
					</DndContext>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{
						position: "absolute",
						bottom: "10%",
					}}
				>
					<div className="d-flex justify-content-between align-items-end">
						<div className="txt12">
							<div>
								<div className="d-flex align-items-center">
									<div className="bg-points-info me-1">
										+{CORRECT_POINTS}
									</div>
									puntos
								</div>
								<div className="d-flex align-items-center mt-2">
									<div className="bg-extra-points-info me-1">
										+{EXTRA_POINTS}
									</div>
									puntos extras por resolver en el primer
									intento
								</div>
							</div>
							<div className="d-flex align-items-center mt-4">
								<img
									src={iconArrastrar}
									alt="arrastra"
									className="me-2"
								></img>
								<div>
									Arrastra cada etiqueta a su lugar
									correspondiente.
								</div>
							</div>
						</div>
						<div style={{ zIndex: "999" }}>
							{!quizCompleted ? (
								<button
									className="txt14 btn-iniciar p-2 px-4"
									onClick={() => checkAnswers()}
								>
									COMPROBAR{" "}
									<img className="ms-2" src={flechas}></img>
								</button>
							) : (
								<NextButton
									navigateTo={NAVIGATE_TO}
								></NextButton>
							)}
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta1);
