import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalBiblioReferences } from "../../utils/logger";

function Pregunta1Superada({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Khurana S, Brusselle GG, Bel EH, et al.
					<span class="regular">
						Long-term Safety and Clinical Benefit of Mepolizumab in
						Patients With the Most Severe Eosinophilic Asthma: The
						COSMEX Study. Clinical Ther. 2019;41(10):2041-56.e5.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Domingo Ribas C, Carrillo Díaz T, Blanco Aparicio M, et al.
					<span class="regular">
						REal worlD Effectiveness and Safety of Mepolizumab in a
						Multicentric Spanish Cohort of Asthma Patients
						Stratified by Eosinophils: The REDES Study. Drugs.
						2021;81(15):1763-74.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Ortega HG, Liu MC, Pavord ID, et al.
					<span class="regular">
						Mepolizumab treatment in patients with severe
						eosinophilic asthma. New Engl J Med.
						2014;371(13):1198-207.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Kobayashi K, Nagase H, Sugimoto N, et al.
					<span class="regular">
						Mepolizumab decreased the levels of serum galectin-10
						and eosinophil cationic protein in asthma. Asia Pac
						Allergy. 2021;11(3):e31.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Nagase H, Ueki S, Fujieda S.
					<span class="regular">
						The roles of IL-5 and anti-IL-5 treatment in
						eosinophilic diseases: asthma, eosinophilic
						granulomatosis with polyangiitis, and eosinophilic
						chronic rhinosinusitis. Allergol Int. 2020;69(2):178-86.
					</span>
				</span>
			</li>
		</ol>`;

	return (
		<>
			<div className="pt-4 h-100 bg-fin-escenario escenario-1">
				<div className="container h-75">
					<div className="col-12 text-red txt50 bold">
						¡Primera parte superada!
					</div>
					<div className="row mt-3">
						<div className="col-6">
							<div className="bold">
								Hemos visto que la eosinofilia de María en el
								último año está causada por el aumento de la
								producción de IL5.
							</div>
							<p>
								A este respecto, podríamos bloquear la IL5,
								normalizando la cifra de eosinófilos a niveles
								fisiológicos<sup>1-3</sup>.
							</p>
							<p>
								Al bloquear la IL5 con mepolizumab,{" "}
								<span className="bold">
									se evitaría la activación del eosinófilo con
									la liberación de sus gránulos
								</span>{" "}
								(inhibiendo la inflamación, el remodelado y el
								daño tisular)<sup>4,5</sup>.
							</p>
							<p className="bold mt-5">
								<i>Sigamos con el caso de María…</i>
							</p>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{
							position: "absolute",
							bottom: "10%",
						}}
					>
						<div className="d-flex align-items-center justify-content-between mt-4 pt-2">
							<div>
								<div
									className="txt12 underline text-red cursor-pointer"
									onClick={(e) => {
										e.stopPropagation();
										modalBiblioReferences({
											referencesHtml: REFERENCES,
										});
									}}
								>
									Ver referencias
								</div>
							</div>
							<div>
								<NextButton
									navigateTo={ROUTES.TRANSITION_SCENARIOS}
									navigateToAfter={ROUTES.P2_INTRO}
								></NextButton>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta1Superada);
