import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndParticipant, postAnswer } from "../../api/answer";
import flechas from "../../assets/flechas.svg";
import iconPreguntaExtra from "../../assets/icono-pregunta-extra.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import MultipleChoiceQuestion from "../../components/MultipleChoiceQuestion";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertQuizAlreadyAnswered,
	modalAnswerExplanationMultipleChoiceQuestion,
	modalBiblioReferences,
} from "../../utils/logger";

function Pregunta1Extra({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const currentQuestion = location.pathname === ROUTES.P1_EXTRA2 ? 2 : 1;

	const [answerChecked, setAnswerChecked] = useState(false);

	const QUIZ_ID =
		currentQuestion === 2
			? QUIZZES.SCENARIO1_TEST_2
			: QUIZZES.SCENARIO1_TEST_1;
	const SCENARIO_ID = SCENARIOS.SCENARIO_1;
	const NAVIGATE_TO =
		currentQuestion === 2 ? ROUTES.P1_EXTRA_VF : ROUTES.P1_EXTRA2;

	const [intentosRestantes, setIntentosRestantes] = useState(1);

	const CORRECT_POINTS = 25;
	const WRONG_POINTS = 25;

	const correctOptions = [
		{ question: 1, option: 3 },
		{ question: 2, option: 3 },
	];

	const [optionClicked, setOptionClicked] = useState(null);

	const questionReferences = [
		{
			question: 1,
			references: `<ol class="numbered-list">
					<li>
						<span class="reference-text">
							Heaney LG, Perez de Llano L, Al-Ahmad M, et al.
							<span class="regular">
								Eosinophilic and Noneosinophilic Asthma: An
								Expert Consensus Framework to Characterize
								Phenotypes in a Global Real-Life Severe Asthma
								Cohort. Chest. 2021;160(3):814-830.
							</span>
						</span>
					</li>
					<li>
						<span class="reference-text">
							Chung KF, Wenzel SE, Brozek JL, et al.
							<span class="regular">
								International ERS/ATS guidelines on definition,
								evaluation and treatment of severe asthma. Eur
								Respir J. 2014;43(2)343-73 [published correction
								appears in Eur Respir J. 2014;43(4):1216].
							</span>
						</span>
					</li>

					<li>
						<span class="reference-text">
							Wenzel SE.
							<span class="regular">
								Asthma phenotypes: the evolution from clinical
								to molecular approaches. Nat Med.
								2012;18(5):716-25.
							</span>
						</span>
					</li>
					<li>
						<span class="reference-text">
							Brusselle GG, Maes T, Bracke KR.
							<span class="regular">
								Eosinophils in the spotlight: Eosinophilic
								airway inflammation in nonallergic asthma. Nat
								Med. 2013;19(8):977-79.
							</span>
						</span>
					</li>
				</ol>`,
		},
		{
			question: 2,
			references: `<ol class="numbered-list">
					<li>
						<span class="reference-text">
							Lambrecht BN, Hammad H.
							<span class="regular">
								The immunology of asthma. Nat Immunol.
								2015;16(1):45-56.
							</span>
						</span>
					</li>
					<li>
						<span class="reference-text">
							Persson EK, Verstraete K, Heyndrickx I, et al.
							<span class="regular">
								Protein crystallization promotes type 2 immunity
								and is reversible by antibody treatment.
								Science. 2019;364(6442):eaaw4295.
							</span>
						</span>
					</li>

					<li>
						<span class="reference-text">
							Wang Z, Zhang S, Xiao Y, et al.
							<span class="regular">
								NLRP3 Inflammasome and Inflammatory Diseases.
								Oxid Med Cell Longev. 2020;2020:4063562.
							</span>
						</span>
					</li>
					<li>
						<span class="reference-text">
							Akdis M, Burgler S, Crameri R, et al.
							<span class="regular">
								Interleukins, from 1 to 37, and interferon-γ:
								receptors, functions, and roles in diseases. J
								Allergy Clin Immunol. 2011;127(3):701-21.e1-70.
							</span>
						</span>
					</li>
				</ol>`,
		},
	];

	const answersExplanationHtml = [
		{
			question: 2,
			explanationCorrect: `<div class="txt16">
					<p>
						La <span class="bold">IL5</span> es una citocina
						inflamatoria que media muchos procesos de la inflamación
						T2,
						<span class="bold">tanto innata como adaptativa.</span>
					</p>
					<p>
						La <span class="bold">IL5</span> (a través de los
						cristales de Charcot-Leyden, que activan el inflamasoma
						de las células dendríticas y de los macrófagos) y la
						<span class="bold">IL4</span>
						(que induce la diferenciación de las células
						<span class="bold">Th2</span> en el tejido linfoide
						secundario) son fundamentales en la
						<span class="bold">
							fase de sensibilización de la respuesta adaptativa
							T2.
						</span>
					</p>
					<p class="bold">
						Por tanto, la IL5 también actúa en la fase de
						sensibilización de la inflamación T2 alérgica.
					</p>
				</div>`,
			explanationWrong: `<div class="txt16">
					<p class="bold">
						La IL5 y los eosinófilos están implicados en la
						inmunidad adaptativa e innata y tienen un rol activo en
						las respuestas alérgica y no alérgica.
					</p>
					<p>
						La <span class="bold">IL5</span> es una citocina
						inflamatoria que media muchos procesos de la inflamación
						T2,
						<span class="bold">tanto innata como adaptativa.</span>
					</p>
					<p>
						La <span class="bold">IL5</span> (a través de los
						cristales de Charcot-Leyden, que activan el inflamasoma
						de las células dendríticas y de los macrófagos) y la
						<span class="bold">IL4</span>
						(que induce la diferenciación de las células
						<span class="bold">Th2</span> en el tejido linfoide
						secundario) son fundamentales en la
						<span class="bold">
							fase de sensibilización de la respuesta adaptativa
							T2.
						</span>
					</p>
					<p class="bold">
						Por tanto, la IL5 también actúa en la fase de
						sensibilización de la inflamación T2 alérgica.
					</p>
				</div>`,
		},
	];

	const answerOptions = [
		{
			question: 1,
			options: [
				{
					num: 1,
					value: "El 83,8% de los pacientes con asma grave se clasificaron con un fenotipo principalmente eosinofílico.",
				},
				{
					num: 2,
					value: "El asma eosinofílica grave se caracteriza por una inflamación eosinofílica persistente de las vías respiratorias e incluye enfermedad provocada por desencadenantes alérgicos o no alérgicos.",
				},
				{ num: 3, value: "Ambas son correctas." },
			],
		},
		{
			question: 2,
			options: [
				{
					num: 1,
					value: "La IL5 no tiene implicaciones en la inmunidad adaptativa y, por lo tanto, no participa en la respuesta alérgica.",
				},
				{
					num: 2,
					value: "La IL5 tiene implicaciones en la inmunidad adaptativa y en la inmunidad innata, pero no participa en la respuesta alérgica.",
				},
				{
					num: 3,
					value: "La IL5 y los eosinófilos están implicados en la inmunidad adaptativa e innata y tienen un rol activo en las respuestas alérgica y no alérgica.",
				},
				{
					num: 4,
					value: "La IL5 y los eosinófilos están implicados en la respuesta inmunitaria innata, pero no participan en la respuesta alérgica.",
				},
			],
		},
	];

	const [time, setTime] = useState(0);

	useEffect(() => {
		getAnswerByQuizAndParticipant(QUIZ_ID, participant?._id)
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					alertQuizAlreadyAnswered({}).then((res) => {});
					setAnswerChecked(true);
				}
			})
			.catch((error) => {});
	}, [location]);

	useTimer(
		() => {
			setTime(time + 1);
		},
		answerChecked ? null : 1000
	);

	async function submitAnswer({ correct, score, penalty }) {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					let penaltyScore = penalty || 0;
					dispatch(updateScore(score, SCORE_TYPES.SCORE));
					dispatch(
						updateScore(
							-Math.abs(penaltyScore),
							SCORE_TYPES.PENALTY
						)
					);

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						penalty: participant?.penalty - Math.abs(penaltyScore),
						lastVisited: NAVIGATE_TO,
					});
					setAnswerChecked(true);
				}
			})
			.catch((error) => {});
	}

	function checkAnswers() {
		let correctOption = correctOptions.find(
			(x) => x.question === currentQuestion
		)?.option;

		let correct = optionClicked === correctOption;
		let explanation =
			currentQuestion === 2
				? answersExplanationHtml.find(
						(x) => x.question === currentQuestion
				  )
				: answerOptions
						.find((x) => x.question === currentQuestion)
						?.options?.find((x) => x.num === correctOption)?.value;

		if (currentQuestion === 2)
			explanation = correct
				? explanation?.explanationCorrect
				: explanation?.explanationWrong;

		let correctOptionLetter = String.fromCharCode(
			"A".charCodeAt(0) + correctOption - 1
		);

		modalAnswerExplanationMultipleChoiceQuestion({
			title: correct ? "¡Respuesta correcta!" : "No es correcto",
			subtitle: correct
				? ""
				: `Respuesta correcta: ${correctOptionLetter}`,
			textHtml: explanation,
			points: correct ? CORRECT_POINTS : WRONG_POINTS,
			correct: correct,
		});

		submitAnswer({
			correct: correct,
			score: correct ? CORRECT_POINTS : -WRONG_POINTS,
			penalty: correct ? 0 : WRONG_POINTS,
		});
	}

	return (
		<div className="h-100 bg-pregunta-extra">
			<div className="container">
				<div className="row h-100">
					<div className="col-1"></div>
					<div className="col-10 pt-5">
						<div className="d-flex flex-column justify-content-center align-items-center">
							<div className="d-flex align-items-center">
								<Image
									src={iconPreguntaExtra}
									width={52}
								></Image>
								<div>
									<div className="txt26 bold text-red">
										PRUEBA PUNTOS EXTRA
									</div>
									<div className="txt16 bold">
										Escoge bien y gana puntos extra.
									</div>
								</div>
							</div>
						</div>
						<br />
						<br />
						<div className="row d-flex justify-content-center">
							<div className="col-9">
								<MultipleChoiceQuestion
									options={
										answerOptions.find(
											(x) =>
												x.question === currentQuestion
										)?.options
									}
									correctOptionNumber={
										correctOptions.find(
											(x) =>
												x.question === currentQuestion
										)?.option
									}
									onOptionClicked={(option) =>
										setOptionClicked(option)
									}
									clickedOption={optionClicked}
									answerChecked={answerChecked}
								></MultipleChoiceQuestion>
							</div>
						</div>
					</div>
					<div className="col-1">
						<div className="d-flex flex-column align-items-end">
							<LifeIndicatorBar
								numIntentos={intentosRestantes}
							></LifeIndicatorBar>
						</div>
					</div>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{ position: "absolute", bottom: "10%" }}
				>
					<div className="d-flex align-items-end justify-content-between">
						<div>
							<div className="d-flex align-items-center txt12">
								<div className="bg-points-info me-1">
									+{CORRECT_POINTS}
								</div>
								puntos
							</div>
							<div className="d-flex align-items-center mt-2 txt12">
								<div className="bg-wrong-points-info me-1">
									-{WRONG_POINTS}
								</div>
								puntos
							</div>
							<div
								className="txt12 underline text-red cursor-pointer mt-4"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: questionReferences.find(
											(x) =>
												x.question === currentQuestion
										)?.references,
									});
								}}
							>
								Ver referencias
							</div>
						</div>
						<div>
							{!answerChecked ? (
								<button
									onClick={() => {
										checkAnswers();
									}}
									className="btn-iniciar p-2 px-4"
								>
									COMPROBAR
									<img className="ms-2" src={flechas}></img>
								</button>
							) : (
								<div
									onClick={() => {
										setOptionClicked(null);
										setAnswerChecked(false);
										setTime(0);
									}}
								>
									<NextButton
										navigateTo={NAVIGATE_TO}
									></NextButton>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta1Extra);
