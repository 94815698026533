import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import iconoMemoria from "../../assets/icono-memoria.png";
import pregunta6Solucion from "../../assets/pregunta6-solucion.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalBiblioReferences } from "../../utils/logger";

function Pregunta6Solucion({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [intentosRestantes, setIntentosRestantes] = useState(null);

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Pelaia C, Paoletti G, Puggioni F, et al.
					<span class="regular">
						Interleukin-5 in the Pathophysiology of Severe Asthma.
						Front Physiol. 2019;10:1514.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Acharya KR, Ackerman SJ.
					<span class="regular">
						Eosinophil granule proteins: form and function. J Biol
						Chem. 2014;289(25):17406-15.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Bachert C, Marple B, Schlosser RJ, et al.
					<span class="regular">
						Adult chronic rhinosinusitis. Nat Rev Dis Primers.
						2020;6(1):86.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Persson EK, Verstraete K, Heyndrickx I, et al.
					<span class="regular">
						Protein crystallization promotes type 2 immunity and is
						reversible by antibody treatment. Science.
						2019;364(6442):eaaw4295.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Lu Y, Huang Y, Li J, et al.
					<span class="regular">
						Eosinophil extracellular traps drive asthma progression
						through neuro-immune signals. Nat Cell Biol.
						2021;23(10):1060-72.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Choi Y, Kim YM, Lee HR, et al.
					<span class="regular">
						Eosinophil extracellular traps activate type 2 innate
						lymphoid cells through stimulating airway epithelium in
						severe asthma. Allergy. 2020;75(1):95-103.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Barretto KT, Brockman-Schneider RA, Kuipers I, et al.
					<span class="regular">
						Human airway epithelial cells express a functional IL5
						receptor. Allergy. 2020;75(8):2127-30.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Al-Shaikhly T, Murphy RC, Parker A, et al.
					<span class="regular">
						Location of eosinophils in the airway wall is critical
						for specific features of airway hyperresponsiveness and
						T2 inflammation in asthma. Eur Respir J.
						2022;60(2):2101865.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Cho JY, Miller M, Baek KJ, et al.
					<span class="regular">
						Inhibition of airway remodeling in IL5-deficient mice. J
						Clin Invest. 2004;113(4):551-60.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Bajbouj K, AbuJabal R, Sahnoon L, et al.
					<span class="regular">
						IL5 receptor expression in lung fibroblasts: Potential
						role in airway remodeling in asthma. Allergy.
						2023;78(3):882-5.
					</span>
				</span>
			</li>
		</ol>`;

	return (
		<div className="pt-4 h-100 bg-memoria">
			<div className="container">
				<div className="row h-100 d-flex align-items-center">
					<div className="col-11">
						<div className="d-flex flex-column justify-content-center align-items-center">
							<Image src={iconoMemoria} width={52}></Image>
							<p className="txt26 bold text-red p-0 mb-1">
								PRUEBA DE MEMORIA
							</p>
							<Image src={pregunta6Solucion} width={730}></Image>
						</div>
					</div>
					<div className="col-1">
						<div className="d-flex flex-column align-items-end">
							<LifeIndicatorBar
								numIntentos={intentosRestantes}
							></LifeIndicatorBar>
						</div>
					</div>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{ position: "absolute", bottom: "10vh" }}
				>
					<div className="d-flex align-items-end justify-content-between">
						<div>
							<div
								className="txt12 underline text-red cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
						</div>
						<div>
							<NextButton navigateTo={ROUTES.P6_SUPERADA} />
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta6Solucion);
