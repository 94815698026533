import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import iconoMemoria from "../../assets/icono-memoria.png";
import pregunta6Memoria from "../../assets/pregunta6-memoria.png";
import relojTiempo from "../../assets/reloj-tiempo.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import { useSelector } from "react-redux";

function Pregunta6Explicacion({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const [intentosRestantes, setIntentosRestantes] = useState(4);
	const [remainingSeconds, setRemainingSeconds] = useState(20);

	useEffect(() => {
		const timer = setInterval(async () => {
			if (remainingSeconds > 0) {
				setRemainingSeconds(remainingSeconds - 1);
			} else {
				let totalTime = getTimer();
				await updateStats({
					...participant,
					time: totalTime,
					lastVisited: ROUTES.P6,
				});
				navigate(ROUTES.P6);
				clearInterval(timer);
			}
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [remainingSeconds]);

	return (
		<div className="pt-4 h-100 bg-memoria">
			<div className="container">
				<div className="row h-100">
					<div className="col-2">
						<div className="d-flex justify-content-start">
							<div className="position-relative">
								<Image src={relojTiempo} width={115}></Image>
								<div className="tiempo-reloj">
									00:
									{remainingSeconds < 10
										? "0" + remainingSeconds
										: remainingSeconds}
								</div>
							</div>
						</div>
					</div>
					<div className="col-9">
						<div className="d-flex flex-column justify-content-center align-items-center">
							<div className="d-flex align-items-start">
								<Image src={iconoMemoria} width={52}></Image>
								<div className="ms-2">
									<p className="txt26 bold text-red p-0 mb-1">
										PRUEBA DE MEMORIA
									</p>
									<div className="txt16 bold">
										Observa con atención este
										cuadro-resumen.
									</div>
									<div className="txt16 p-0 mb-2 bold">
										¡Solo tienes 20 segundos!
									</div>
								</div>
							</div>
							<Image
								className="pt-2"
								src={pregunta6Memoria}
								width={"100%"}
							></Image>
						</div>
					</div>
					<div className="col-1 pt-5">
						<div className="d-flex flex-column align-items-end">
							<LifeIndicatorBar
								numIntentos={null}
							></LifeIndicatorBar>
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta6Explicacion);
