import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndParticipant, postAnswer } from "../../api/answer";
import flechas from "../../assets/flechas.svg";
import iconZoom from "../../assets/icon-ampliar.png";
import iconAmpliar from "../../assets/icono-ampliar-info.png";
import iconPreguntaExtra from "../../assets/icono-pregunta-extra.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getParticipantId,
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertError,
	alertQuizAlreadyAnswered,
	modalBiblioReferences,
	modalCorrectIncorrectAnswer,
	modalQuestion5ZoomOption,
} from "../../utils/logger";

function Pregunta5Extra({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const CORRECT_POINTS = 50;
	const WRONG_POINTS = 50;

	const SCENARIO_ID = SCENARIOS.SCENARIO_5;
	const QUIZ_ID = QUIZZES.SCENARIO5_SELECCIONAR;
	const NAVIGATE_TO = ROUTES.P5_SUPERADA;

	const [intentosRestantes, setIntentosRestantes] = useState(1);
	const [checkedAnswers, setCheckedAnswers] = useState(false);

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Barretto KT, Brockman-Schneider RA, Kuipers I, et al.
					<span class="regular">
						Human airway epithelial cells express a functional IL5
						receptor. Allergy. 2020;75(8):2127-30.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Lu Y, Huang Y, Li J, et al.
					<span class="regular">
						Eosinophil extracellular traps drive asthma progression
						through neuro-immune signals. Nat Cell Biol.
						2021;23(10):1060-72.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Bachert C, Marple B, Schlosser RJ, et al.
					<span class="regular">
						Adult chronic rhinosinusitis. Nat Rev Dis Primers.
						2020;6(1):86.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Cho JY, Miller M, Baek KJ, et al.
					<span class="regular">
						Inhibition of airway remodeling in IL5-deficient mice. J
						Clin Invest. 2004;113(4):551-60.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Bajbouj K, AbuJabal R, Sahnoon L, et al.
					<span class="regular">
						IL5 receptor expression in lung fibroblasts: Potential
						role in airway remodeling in asthma. Allergy.
						2023;78(3):882-5.
					</span>
				</span>
			</li>
		</ol>`;

	const [options, setOptions] = useState([
		{
			id: 1,
			value: `<div>
					<span class="bold">
						Contribución de la IL5 al remodelado tisular en el asma
					</span>
					<div class="bold">Efecto directo</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Pérdida de la
						integridad del epitelio bronquial
					</div>
					<div class="bold">A través de los eosinófilos</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Metaplasia de
						células calciformes
					</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Disminución de
						la cantidad y viscosidad del moco
					</div>
					<div class="bold">A través de los fibroblastos</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Engrosamiento
						subepitelial
					</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Atrofia de la
						membrana basal
					</div>
				</div>`,
			selected: false,
			correct: false,
		},
		{
			id: 2,
			value: `<div>
					<span class="bold">
						Contribución de la IL5 al remodelado tisular en el asma
					</span>
					<div class="bold">Efecto directo</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Pérdida de la
						integridad del epitelio bronquial
					</div>
					<div class="bold">A través de los eosinófilos</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Metaplasia de
						células calciformes
					</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Aumento de la
						cantidad y viscosidad del moco
					</div>
					<div class="bold">A través de los fibroblastos</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Fibrosis
						subepitelial
					</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Engrosamiento
						de la membrana basal
					</div>
				</div>`,
			selected: false,
			correct: true,
		},
		{
			id: 3,
			value: `<div>
					<span class="bold">
						Contribución de la IL5 al remodelado tisular en el asma
					</span>
					<div class="bold">Efecto directo</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Reparación de
						la integridad epitelial
					</div>
					<div class="bold">A través de los eosinófilos</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Metaplasia de
						células calciformes
					</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Aumento de la
						cantidad y fluidez del moco
					</div>
					<div class="bold">A través de los fibroblastos</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Fibrosis
						subepitelial
					</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Engrosamiento
						de lámina propia
					</div>
				</div>`,
			selected: false,
			correct: false,
		},
		{
			id: 4,
			value: `<div>
					<span class="bold">
						Contribución de la IL5 al remodelado tisular en el asma
					</span>
					<div class="bold">Efecto directo</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Pérdida de la
						integridad del epitelio bronquial
					</div>
					<div class="bold">A través de los eosinófilos</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Hipertrofia de
						células calciformes
					</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Disminución de
						la cantidad y viscosidad del moco
					</div>
					<div class="bold">A través de los fibroblastos</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Fibrosis
						subepitelial
					</div>
					<div class="ms-4">
						<span class="text-red bold">•</span> Engrosamiento
						de la membrana basal
					</div>
				</div>`,
			selected: false,
			correct: false,
		},
	]);

	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		checkedAnswers ? null : 1000
	);

	useEffect(() => {
		getAnswerByQuizAndParticipant(QUIZ_ID, getParticipantId())
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					alertQuizAlreadyAnswered({}).then((res) => {});
					setCheckedAnswers(true);
				}
			})
			.catch((error) => {});
	}, [location]);

	async function submitAnswer({ correct, score, penalty }) {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					let penaltyScore = penalty || 0;

					dispatch(updateScore(score, SCORE_TYPES.SCORE));
					dispatch(
						updateScore(
							-Math.abs(penaltyScore),
							SCORE_TYPES.PENALTY
						)
					);

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						penalty: participant?.penalty - Math.abs(penaltyScore),
						lastVisited: NAVIGATE_TO,
					});
					setCheckedAnswers(true);
				}
			})
			.catch((error) => {});
	}

	const handleSelectOption = (id) => {
		if (checkedAnswers) return;
		const updatedOptions = options.map((option) =>
			option.id === id
				? { ...option, selected: !option.selected }
				: { ...option, selected: false }
		);
		setOptions(updatedOptions);
	};

	const CardOption = ({ optionId }) => {
		const option = options.find((x) => x.id === optionId);

		return (
			<>
				<div>
					<div
						className={`bg-selection-card p-3 txt11 cursor-pointer ${
							!checkedAnswers && option?.selected
								? "card-selected"
								: checkedAnswers && option?.correct
								? "card-selected"
								: checkedAnswers &&
								  option?.selected &&
								  !option?.correct
								? "card-selected wrong"
								: ""
						}`}
						onClick={() => handleSelectOption(optionId)}
					>
						<div
							dangerouslySetInnerHTML={{
								__html: option?.value,
							}}
						></div>
					</div>
					<div
						className="d-flex justify-content-center"
						style={{ marginTop: "-5px", cursor: "pointer" }}
					>
						<img
							src={iconAmpliar}
							width={90}
							onClick={() => {
								modalQuestion5ZoomOption({
									text: option?.value,
								});
							}}
						></img>
					</div>
				</div>
			</>
		);
	};

	function checkAnswers() {
		if (!options.some((x) => x.selected)) {
			alertError({
				customMessage: "Debes seleccionar almenos una descripción.",
			});
			return;
		}
		const correct = options.some(
			(option) => option.selected === true && option.correct === true
		);
		const finalScore = correct ? CORRECT_POINTS : WRONG_POINTS;

		const correctOption = options.find((x) => x.correct);

		let text = `<div>
				<div class="txt16">Esta es la opción correcta:</div>
				<div class="d-flex justify-content-center">
				<div class="bg-selection-card p-3 txt16 card-selected text-left w-fit-content h-fit-content mt-2">
					${correctOption?.value}
				</div>		
				</div>		
			</div>`;

		modalCorrectIncorrectAnswer({
			title: correct ? "¡Fantástico" : "No es correcto",
			textHtml: text,
			points: finalScore,
			correct: correct,
		});

		submitAnswer({
			correct: correct,
			score: correct ? finalScore : -finalScore,
			penalty: correct ? 0 : WRONG_POINTS,
		});

		setIntentosRestantes(intentosRestantes - 1);
	}

	return (
		<div className="pt-4 h-100 bg-pregunta-extra">
			<div className="container">
				<div className="row h-100">
					<div className="col-1"></div>
					<div className="col-10" style={{ zIndex: "10" }}>
						<div className="d-flex flex-column justify-content-center align-items-center">
							<div className="d-flex align-items-start">
								<Image
									src={iconPreguntaExtra}
									width={52}
								></Image>
								<div style={{ maxWidth: "550px" }}>
									<p className="txt26 bold text-red p-0 mb-1">
										PRUEBA PUNTOS EXTRA
									</p>
									<p className="txt16 bold">
										¿Cuál de las siguientes descripciones
										refleja correctamente la contribución de
										la IL5 al remodelado tisular en el asma?
										Escoge una opción.{" "}
										<span className="text-red">
											1 INTENTO
										</span>
									</p>
								</div>
							</div>
							<div className="d-flex flex justify-content-center align-items-center">
								<CardOption optionId={1}></CardOption>
								<div className="ms-2">
									<CardOption optionId={2}></CardOption>
								</div>
							</div>
							<div className="d-flex flex justify-content-center align-items-center mt-2">
								<CardOption optionId={3}></CardOption>
								<div className="ms-2">
									<CardOption optionId={4}></CardOption>
								</div>
							</div>
						</div>
					</div>
					<div className="col-1">
						<div className="d-flex flex-column align-items-end">
							<LifeIndicatorBar
								numIntentos={intentosRestantes}
							></LifeIndicatorBar>
						</div>
					</div>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{ position: "absolute", bottom: "7vh" }}
				>
					<div className="d-flex align-items-end justify-content-between mt-5">
						<div>
							<div className="d-flex align-items-center txt12">
								<div className="bg-points-info me-1">
									+{CORRECT_POINTS}
								</div>
								puntos
							</div>
							<div className="d-flex align-items-center mt-2 txt12">
								<div className="bg-wrong-points-info me-1">
									-{WRONG_POINTS}
								</div>
								puntos
							</div>
							<div className="d-flex align-items-center txt12 mt-2 w-75">
								<img
									width={39}
									src={iconZoom}
									alt="ampliar"
									className="me-1"
								></img>
								<div>
									Amplía las opciones para ver el detalle.
								</div>
							</div>
							<div
								className="txt12 underline text-red cursor-pointer mt-4"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
						</div>
						<div style={{ zIndex: "20" }}>
							{!checkedAnswers ? (
								<button
									onClick={() => {
										checkAnswers();
									}}
									className="btn-iniciar p-2 px-4"
								>
									COMPROBAR
									<img className="ms-2" src={flechas}></img>
								</button>
							) : (
								<NextButton
									navigateTo={NAVIGATE_TO}
								></NextButton>
							)}
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta5Extra);
