import React from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import mariaOk from "../assets/maria-ok.svg";
import mariaNeutral from "../assets/maria-neutral.svg";
import mariaBad from "../assets/maria-bad.svg";
import NextButton from "../components/NextButton";
import { ROUTES } from "../utils/constants";
import { useSelector } from "react-redux";

function FinalScore({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const participant = useSelector((state) => state?.group);

	const score = participant?.score;
	const correct = score >= 1500;
	const neutral = score >= 0 && score <= 1499;
	const bad = score < 0;

	function getTextFromScore() {
		return (
			<>
				<p
					className={`${
						bad ? "text-red" : "text-green"
					} bold txt22 mt-4`}
				>
					{score} PUNTOS
				</p>
				<div className="txt18">
					{correct ? (
						<>
							<p>
								María se encuentra mucho mejor gracias a tu
								habilidad y tus aciertos en las respuestas.
							</p>
							<p className="bold">
								¡Está encantada de que seas su médico!
							</p>
						</>
					) : neutral ? (
						<>
							<p>
								María ha tenido una exacerbación y ha vuelto a
								tomar corticoides orales.
							</p>
							<p>
								Sigue teniendo tos y disnea. Pero, en cuanto
								apliques lo que has aprendido en esta
								experiencia, seguro que mejorará rápidamente.
							</p>
						</>
					) : bad ? (
						<>
							<p>
								María ha empeorado aún más. Tose mucho y sus
								síntomas van a peor.
							</p>
							<p>
								Aplica lo que has aprendido en esta experiencia
								y seguro que mejora en poco tiempo.
							</p>
						</>
					) : (
						<></>
					)}
				</div>
			</>
		);
	}

	return (
		<>
			<div className="h-100 bg-results">
				<div className="row h-75 d-flex justify-content-center">
					<div className="d-flex align-items-center justify-content-center">
						<div className="text-center mt-3 w-50">
							<div className="txt28 bold gradient-text">
								PUNTUACIÓN FINAL
							</div>
							<div className="d-flex justify-content-center">
								<div className="final-score-img-bg mt-3 w-fit-content p-1">
									<div
										className="bg-white w-fit-content p-2"
										style={{ borderRadius: "50%" }}
									>
										<Image
											src={
												correct
													? mariaOk
													: neutral
													? mariaNeutral
													: bad
													? mariaBad
													: null
											}
											roundedCircle
											width={200}
										></Image>
									</div>
								</div>
							</div>
							{getTextFromScore()}
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "8vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<NextButton navigateTo={ROUTES.RESULTS}></NextButton>
				</div>
			</div>
		</>
	);
}

export default injectIntl(FinalScore);
