import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndParticipant, postAnswer } from "../../api/answer";
import flechas from "../../assets/flechas.svg";
import iconNoSeleccion from "../../assets/icono-no-seleccion.png";
import iconPreguntaExtra from "../../assets/icono-pregunta-extra.png";
import iconSeleccionCorrecta from "../../assets/icono-seleccion-correcta.png";
import iconSeleccion from "../../assets/icono-seleccion.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getParticipantId,
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertQuizAlreadyAnswered,
	modalBiblioReferences,
} from "../../utils/logger";

function Pregunta2Extra({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const [intentosRestantes, setIntentosRestantes] = useState(4);
	const [checkedAnswers, setCheckedAnswers] = useState(false);
	const [score, setScore] = useState(0);

	const CORRECT_POINTS = 25;
	const WRONG_POINTS = 50;

	const SCENARIO_ID = SCENARIOS.SCENARIO_2;
	const QUIZ_ID = QUIZZES.SCENARIO2_TEST;
	const NAVIGATE_TO = ROUTES.P2_SUPERADA;

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Acharya KR, Ackerman SJ.
					<span class="regular">
						Eosinophil granule proteins: form and function. J Biol
						Chem. 2014;289(25):17406-15.
					</span>
				</span>
			</li>
		</ol>`;

	const [options, setOptions] = useState([
		{
			id: 1,
			value: "Proteasas preformadas",
			selected: false,
			correct: true,
		},
		{
			id: 2,
			value: "Cristales de Charcot-Leyden",
			selected: false,
			correct: true,
		},
		{
			id: 3,
			value: "Redes extracelulares de ADN",
			selected: false,
			correct: true,
		},
		{
			id: 4,
			value: "Citoquinas y quimioquinas inmunorreguladoras",
			selected: false,
			correct: true,
		},
	]);

	const [time, setTime] = useState(0);

	useEffect(() => {
		getAnswerByQuizAndParticipant(QUIZ_ID, getParticipantId())
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					alertQuizAlreadyAnswered({}).then((res) => {});
					setCheckedAnswers(true);
				}
			})
			.catch((error) => {});
	}, []);

	useTimer(
		() => {
			setTime(time + 1);
		},
		checkedAnswers ? null : 1000
	);

	async function submitAnswer({ correct, score, penalty }) {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					let penaltyScore = penalty || 0;
					dispatch(updateScore(score, SCORE_TYPES.SCORE));
					dispatch(
						updateScore(
							-Math.abs(penaltyScore),
							SCORE_TYPES.PENALTY
						)
					);

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						penalty: participant?.penalty - Math.abs(penaltyScore),
						lastVisited: NAVIGATE_TO,
					});
					setCheckedAnswers(true);
				}
			})
			.catch((error) => {});
	}

	const handleSelectOption = (id) => {
		const updatedOptions = options.map((option) =>
			option.id === id
				? { ...option, selected: !option.selected }
				: option
		);
		setOptions(updatedOptions);
	};

	const SelectionOption = ({ number }) => {
		const option = options.find((x) => x.id === number);

		return (
			<>
				<Image
					className="cursor-pointer"
					src={
						option?.selected && option?.correct && checkedAnswers
							? iconSeleccionCorrecta
							: option?.selected
							? iconSeleccion
							: iconNoSeleccion
					}
					onClick={() => handleSelectOption(option?.id)}
				></Image>
				<span className="mx-3 text-red bold">{option?.id}.</span>
				<span className={option?.id === 1 ? "ms-1" : ""}>
					{option?.value}
				</span>
			</>
		);
	};

	function checkAnswers() {
		let correct = true;
		options.forEach((option) => {
			if (
				(option.selected && !option.correct) ||
				(!option.selected && option.correct)
			)
				correct = false;
		});

		const finalScore = correct ? CORRECT_POINTS : -WRONG_POINTS;
		const penaltyScore = correct ? 0 : WRONG_POINTS;

		setScore(finalScore);

		submitAnswer({
			correct: correct,
			score: finalScore,
			penalty: penaltyScore,
		});
	}

	return (
		<div className="h-100 bg-pregunta-extra">
			<div className="container">
				<div className="row h-100">
					<div className="col-1"></div>
					<div className="col-10 pt-4">
						<div className="d-flex flex-column justify-content-center align-items-center">
							<div className="d-flex flex-column justify-content-center align-items-center">
								<div className="d-flex align-items-start">
									<Image
										className="me-2"
										src={iconPreguntaExtra}
										width={52}
									></Image>
									<div>
										<div className="txt26 bold text-red">
											PRUEBA PUNTOS EXTRA
										</div>
										<div
											className="txt16 bold"
											style={{ maxWidth: "500px" }}
										>
											¿Cuáles de los siguientes son
											mediadores de los eosinófilos? Marca
											todas las opciones que consideres
											correctas.
										</div>
									</div>
								</div>
							</div>
							{checkedAnswers && (
								<div className="bold mt-5">
									Todos ellos son mediadores de los
									eosinófilos:
								</div>
							)}
							<div
								className={`d-flex flex-column justify-content-start align-items-start ${
									!checkedAnswers ? "mt-5 pt-5" : "pt-4"
								}`}
							>
								<div className="d-flex align-items-center">
									<SelectionOption
										number={1}
									></SelectionOption>
								</div>
								<div className="d-flex align-items-center mt-2">
									<SelectionOption
										number={2}
									></SelectionOption>
								</div>
								<div className="d-flex align-items-center mt-2">
									<SelectionOption
										number={3}
									></SelectionOption>
								</div>
								<div className="d-flex align-items-center mt-2">
									<SelectionOption
										number={4}
									></SelectionOption>
								</div>
							</div>
							{checkedAnswers && (
								<div className="mt-4 txt18 bold points-box-container">
									<div className="points-box">
										{score} puntos
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="col-1">
						<div className="d-flex flex-column align-items-end">
							<LifeIndicatorBar
								numIntentos={intentosRestantes}
							></LifeIndicatorBar>
						</div>
					</div>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{ position: "absolute", bottom: "12%" }}
				>
					<div className="d-flex align-items-end justify-content-between">
						<div>
							<div className="d-flex align-items-center txt12">
								<div className="bg-points-info me-1">
									+{CORRECT_POINTS}
								</div>
								puntos
							</div>
							<div className="d-flex align-items-center mt-2 txt12">
								<div className="bg-wrong-points-info me-1">
									-{WRONG_POINTS}
								</div>
								puntos
							</div>
							<div
								className="txt12 underline text-red cursor-pointer mt-4"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
						</div>
						<div>
							{!checkedAnswers ? (
								<button
									onClick={() => {
										checkAnswers();
									}}
									className="btn-iniciar p-2 px-4"
								>
									COMPROBAR
									<img className="ms-2" src={flechas}></img>
								</button>
							) : (
								<NextButton
									navigateTo={NAVIGATE_TO}
								></NextButton>
							)}
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta2Extra);
