import React, { useState } from "react";
import gsk from "../assets/gsk-white.svg";
import etapasPregunta1 from "../assets/scenariobar/etapas-pregunta1.png";
import etapasPregunta2 from "../assets/scenariobar/etapas-pregunta2.png";
import etapasPregunta3 from "../assets/scenariobar/etapas-pregunta3.png";
import etapasPregunta4 from "../assets/scenariobar/etapas-pregunta4.png";
import etapasPregunta5 from "../assets/scenariobar/etapas-pregunta5.png";
import etapasPregunta6 from "../assets/scenariobar/etapas-pregunta6.png";
import etapasPregunta7 from "../assets/scenariobar/etapas-pregunta7.png";
import icochevrondown from "../assets/scenariobar/ico-chevrondown.svg";
import icochevronup from "../assets/scenariobar/ico-chevronup.svg";

function ScenarioBar({ scenarioNumber, expanded, setScenarioBarExpanded }) {
	const [scenarioBarOpen, setScenarioBarOpen] = useState(expanded || false);

	function handleClick(e) {
		if (!scenarioBarOpen) setScenarioBarOpen(true);
		else setScenarioBarOpen(false);
		if (setScenarioBarExpanded) setScenarioBarExpanded(!scenarioBarOpen);
	}

	let stageImage =
		scenarioNumber === 1
			? etapasPregunta1
			: scenarioNumber === 2
			? etapasPregunta2
			: scenarioNumber === 3
			? etapasPregunta3
			: scenarioNumber === 4
			? etapasPregunta4
			: scenarioNumber === 5
			? etapasPregunta5
			: scenarioNumber === 6
			? etapasPregunta6
			: scenarioNumber === 7
			? etapasPregunta7
			: null;

	return (
		<div
			className={
				scenarioBarOpen
					? "scenarioBar p-3 active"
					: "scenarioBar inactive"
			}
		>
			<div className="buttonDown" onClick={handleClick}>
				{scenarioBarOpen && (
					<img src={icochevrondown} alt="Esconder menú" width={23} />
				)}
				{!scenarioBarOpen && (
					<div>
						<img src={icochevronup} alt="Mostrar menú" width={23} />
						<div className="d-flex justify-content-end text-white txt12 pe-1 mt-1">
							Escenarios
						</div>
					</div>
				)}
			</div>
			<div className="container">
				{scenarioBarOpen && (
					<div className="row d-flex align-items-center pt-1">
						<div className="col-4 d-flex justify-content-start">
							<img src={gsk} alt="GSK" />
						</div>
						<div className="col-4">
							<img
								src={stageImage}
								alt={"Escenario " + scenarioNumber}
								width={353}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
export default ScenarioBar;
