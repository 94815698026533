import {
	DndContext,
	MouseSensor,
	TouchSensor,
	useDraggable,
	useDroppable,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndParticipant, postAnswer } from "../../api/answer";
import iconArrastrar from "../../assets/arrastrar.png";
import flechas from "../../assets/flechas.svg";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getParticipantId,
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertQuizAlreadyAnswered,
	modalCorrectIncorrectAnswer,
	modalQuestionInstructions,
} from "../../utils/logger";

function Pregunta6({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const [intentos, setIntentos] = useState(1);
	const [penaltyScore, setPenaltyScore] = useState(0);
	const [quizCompleted, setQuizCompleted] = useState(false);

	const touchSensor = useSensor(TouchSensor);
	const mouseSensor = useSensor(MouseSensor);

	const sensors = useSensors(touchSensor, mouseSensor);

	const CORRECT_POINTS = 50;
	const WRONG_POINTS = 25;
	const PENALTY_SCORE_LIMIT = -300;

	const SCENARIO_ID = SCENARIOS.SCENARIO_6;
	const QUIZ_ID = QUIZZES.SCENARIO6_CUADRORESUMEN;
	const NAVIGATE_TO = ROUTES.P6_SOLUTION;

	const EOSINOFILOS_ID = 1;
	const DIFERENCIACION_ID = 2;
	const MASTOCITO_ID = 3;
	const REDES_ID = 4;
	const FIBROLASTOS_ID = 5;
	const PROTEINAS_ID = 6;
	const EPITELIO_ID = 7;
	const PERDIDA_ID = 8;
	const HIPERREACTIVIDAD_ID = 9;
	const VISCOSIDAD_ID = 10;
	const FIBROSIS_ID = 11;
	const INFLAMACION_ID = 12;
	const CRISTALES_ID = 13;
	const SECRECION_ID = 14;

	const tagOptions = [
		{ id: EOSINOFILOS_ID, value: "Eosinófilos" },
		{
			id: DIFERENCIACION_ID,
			value: "Diferenciación, maduración y supervivencia",
		},
		{ id: MASTOCITO_ID, value: "Mastocito" },
		{ id: REDES_ID, value: "Redes ADN" },
		{ id: FIBROLASTOS_ID, value: "Fibrolastos" },
		{ id: PROTEINAS_ID, value: "Proteínas de los gránulos" },
		{ id: EPITELIO_ID, value: "Epitelio" },
		{ id: PERDIDA_ID, value: "Pérdida de integridad" },
		{
			id: HIPERREACTIVIDAD_ID,
			value: "Hiperreactividad bronquial indirecta",
		},
		{
			id: VISCOSIDAD_ID,
			value: "Viscosidad del moco / Activación respuesta adaptativa",
		},
		{
			id: FIBROSIS_ID,
			value: "Fibrosis subepitelial / Engrosamiento de la membrana basal",
		},
		{
			id: INFLAMACION_ID,
			value: "Inflamación de las vías respiratorias / Remodelado tisular / Daño tisular",
		},
		{ id: CRISTALES_ID, value: "Cristales de Charcot-Leyden " },
		{
			id: SECRECION_ID,
			value: "Viscosidad del moco / Aumento secreción del moco / Hiperplasia células caliciformes / Activación respuesta innata",
		},
	];

	const [tags, setTags] = useState(
		tagOptions.filter(
			(x) => x.id !== EOSINOFILOS_ID && x.id !== DIFERENCIACION_ID
		)
	);
	const OFFSET = tagOptions.length; //necessary for a proper behaviour of drag & drop library

	const [boxes, setBoxes] = useState([
		{
			id: EOSINOFILOS_ID + OFFSET,
			tag: tagOptions.find((x) => x.id === EOSINOFILOS_ID),
		},
		{
			id: DIFERENCIACION_ID + OFFSET,
			tag: tagOptions.find((x) => x.id === DIFERENCIACION_ID),
		},
		{ id: MASTOCITO_ID + OFFSET, tag: null },
		{ id: REDES_ID + OFFSET, tag: null },
		{ id: FIBROLASTOS_ID + OFFSET, tag: null },
		{ id: PROTEINAS_ID + OFFSET, tag: null },
		{ id: EPITELIO_ID + OFFSET, tag: null },
		{ id: PERDIDA_ID + OFFSET, tag: null },
		{
			id: HIPERREACTIVIDAD_ID + OFFSET,
			tag: null,
		},
		{
			id: VISCOSIDAD_ID + OFFSET,
			tag: null,
		},
		{
			id: FIBROSIS_ID + OFFSET,
			tag: null,
		},
		{
			id: INFLAMACION_ID + OFFSET,
			tag: null,
		},
		{ id: CRISTALES_ID + OFFSET, tag: null },
		{
			id: SECRECION_ID + OFFSET,
			tag: null,
		},
	]);

	let instructionsHtml = `<div>
			<p class="txt16">
				Ahora, ¿sabrías completar el cuadro de memoria? Arrastra una
				etiqueta hasta la ubicación que creas que le corresponde.
			</p>
			<p class="txt16 text-red bold">MÚLTIPLES INTENTOS</p>
		</div>`;

	useEffect(() => {
		getAnswerByQuizAndParticipant(QUIZ_ID, getParticipantId())
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					alertQuizAlreadyAnswered({}).then((res) => {});
					setQuizCompleted(true);
				}
			})
			.catch((error) => {
				modalQuestionInstructions({
					instruccionsTextHtml: instructionsHtml,
					questionNum: getScenarioByScreen(location.pathname),
				});
			});
	}, [location]);

	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		quizCompleted ? null : 1000
	);

	async function submitAnswer({ correct, score, penalty }) {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					let penaltyScore = penalty || 0;

					dispatch(updateScore(score, SCORE_TYPES.SCORE));
					dispatch(
						updateScore(
							-Math.abs(penaltyScore),
							SCORE_TYPES.PENALTY
						)
					);

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						penalty: participant?.penalty - Math.abs(penaltyScore),
						lastVisited: NAVIGATE_TO,
					});
					setQuizCompleted(true);
				}
			})
			.catch((error) => {});
	}

	function checkAnswers() {
		let normalizedBoxes = boxes.map((x) => ({ ...x, id: x.id - OFFSET }));

		let correctOptions = normalizedBoxes.filter((x) => x.id === x.tag?.id);
		let wrongPlacedTags = normalizedBoxes.filter(
			(x) => x.tag && x.id !== x.tag?.id
		);
		let emptyBoxes = normalizedBoxes.filter((x) => !x.tag);

		let correct = wrongPlacedTags.length === 0 && emptyBoxes.length === 0;

		let newPenaltyScore =
			penaltyScore + wrongPlacedTags.length * -WRONG_POINTS;

		let newScore = correctOptions.length * CORRECT_POINTS + newPenaltyScore;

		setPenaltyScore(newPenaltyScore);

		let text =
			correct && intentos === 1
				? `<p class="txt16">
					Has obtenido el máximo de puntuación a la primera.
				</p>`
				: correct
				? `<div class="txt16">
					<p>Prueba superada.</p>
					<p>
						Has obtenido
						<span class="text-green bold">
							${newScore} puntos
						</span>
					</p>
				</div>`
				: `<div class="txt16">
					<p>
						Te recomendamos ejercitar tu memoria revisando la
						solución a continuación.
					</p>
					<p>
						Has obtenido
						<span class="text-red bold">
							${newScore} puntos
						</span>
					</p>
				</div>`;

		let submitAnswers = correct || newScore < PENALTY_SCORE_LIMIT;

		if (submitAnswers) {
			modalCorrectIncorrectAnswer({
				title: correct ? "¡Enhorabuena!" : "",
				textHtml: text,
				points: Math.max(newScore, PENALTY_SCORE_LIMIT),
				correct: correct,
			}).then((res) => {
				if (res.isConfirmed) {
					navigate(NAVIGATE_TO);
				}
			});
			submitAnswer({
				correct: correct,
				score: Math.max(newScore, PENALTY_SCORE_LIMIT),
				penalty: Math.max(newPenaltyScore, PENALTY_SCORE_LIMIT),
			});
		}

		const correctBoxes = boxes.map((b) =>
			b.id - OFFSET === b?.tag?.id
				? {
						...b,
				  }
				: { ...b, tag: null }
		);

		setBoxes(correctBoxes);

		const wrongTags = tagOptions.filter(
			(tagOption) =>
				!correctBoxes.some((x) => x?.tag?.id === tagOption.id)
		);

		setTags(wrongTags);
		setIntentos(intentos + 1);
	}

	const handleDragEnd = ({ active, over }) => {
		window.scrollTo(0, 0);
		if (!active || !over) return;

		const draggedTag = tagOptions.find((x) => x.id === active?.id);
		const targetBox = boxes.find((x) => x.id === over?.id);

		const boxId = boxes.find((x) => x.tag?.id === draggedTag?.id)?.id;

		const updatedBoxes = boxes.map((b) =>
			b.id === targetBox?.id
				? {
						...b,
						tag: draggedTag,
				  }
				: b.id === boxId
				? { ...b, tag: null }
				: b
		);

		setBoxes(updatedBoxes);

		let updatedTags;

		if (over?.data?.current?.origin) {
			updatedTags = tagOptions.filter(
				(x) => tags.some((y) => y.id === x.id) || x.id === draggedTag.id
			);
		} else {
			updatedTags = tags.filter((x) => x.id !== draggedTag.id);
		}

		setTags(updatedTags);
	};

	const Draggable = (props) => {
		const { attributes, listeners, setNodeRef, transform } = useDraggable({
			id: props.id,
			disabled: false,
		});

		const style = {
			transform: CSS.Translate.toString(transform),
			lineHeight: "18px",
		};

		const box = boxes.find((x) => x.id === props?.id + OFFSET);
		const isDropped = !tags.some((x) => x?.id == props?.id);

		return (
			<div
				className={`tag-box-memory p-1 ${
					isDropped ? "dropped w-100" : "ms-2"
				} txt11`}
				ref={setNodeRef}
				style={style}
				{...listeners}
				{...attributes}
			>
				{props.children}
			</div>
		);
	};

	const Droppable = (props) => {
		const { isOver, setNodeRef } = useDroppable({
			id: props.id,
			data: {
				classes: props.classes,
				origin: props.origin,
			},
		});

		const emptyTagListElem = props.origin;

		return (
			<>
				<div
					className={`tag-box-target-p6 ${
						emptyTagListElem ? "empty ms-2 p-2" : ""
					} txt11 ${props.classes}`}
					ref={setNodeRef}
				>
					{props.children}
				</div>
			</>
		);
	};

	const TagBoxTarget = ({ boxId, classes }) => {
		const box = boxes.find((x) => x.id === boxId + OFFSET);
		const tag = tags.find((x) => x.id === boxId);

		return box?.tag === null ? (
			<Droppable id={box?.id} classes={classes}>
				{tag?.value}
			</Droppable>
		) : (
			<Draggable id={box?.tag?.id}>{box?.tag?.value}</Draggable>
		);
	};

	const TagBox = ({ tagId, classes }) => {
		const tag = tagOptions.find((x) => x.id === tagId);
		const isDropped = !tags.some((x) => x?.id == tag?.id);

		return !isDropped ? (
			<Draggable id={tag?.id}>{tag?.value}</Draggable>
		) : (
			<Droppable id={tag?.id} origin={true}>
				{tag?.value}
			</Droppable>
		);
	};

	return (
		<div className="h-100 bg-memoria">
			<div className="container">
				<div className="row h-100 d-flex align-items-center">
					<div
						className="col-11 d-flex justify-content-center align-items-center"
						style={{ zIndex: "10" }}
					>
						<DndContext
							onDragEnd={handleDragEnd}
							sensors={sensors}
							modifiers={[restrictToWindowEdges]}
						>
							<div className="w-100">
								<div className="d-flex align-items-end justify-content-end">
									<div className="me-1 bg-light-blue d-flex align-items-center justify-content-center">
										<div className="bold">IL5</div>
									</div>
									<div style={{ width: "25%" }}>
										<div className="d-flex justify-content-center txt10 bold">
											MEDIADOR
										</div>
										<div className="d-flex flex-column align-items-center me-1">
											<div className="bg-pink d-flex align-items-center justify-content-center">
												<TagBoxTarget
													boxId={EOSINOFILOS_ID}
												/>
											</div>
											<div className="bg-tag-pink d-flex align-items-center justify-content-center mt-1 py-1">
												<TagBoxTarget
													boxId={EPITELIO_ID}
												/>
											</div>
											<div className="bg-tag-pink d-flex align-items-center justify-content-center mt-1 py-1">
												<TagBoxTarget
													boxId={MASTOCITO_ID}
												/>
											</div>
											<div className="bg-tag-pink d-flex align-items-center justify-content-center mt-1 py-1">
												<TagBoxTarget
													boxId={FIBROLASTOS_ID}
												/>
											</div>
										</div>
									</div>
									<div style={{ width: "50%" }}>
										<div className="d-flex justify-content-center txt10 bold">
											IMPLICACIONES
										</div>
										<div>
											<div className="bg-pink py-2">
												<div className="d-flex justify-content-center">
													<TagBoxTarget
														boxId={
															DIFERENCIACION_ID
														}
													/>
												</div>
												<div className="d-flex justify-content-center my-1">
													<div className="memory-separator"></div>
												</div>
												<div className="d-flex justify-content-between w-100 mt-1">
													<TagBoxTarget
														boxId={PROTEINAS_ID}
													/>
													<div className="memory-separator vertical mx-1"></div>
													<TagBoxTarget
														boxId={INFLAMACION_ID}
													/>
													{/*<div className="tag-box-target h-35 w-25"></div>
													<div className="tag-box-target h-35 w-75 ms-1"></div>*/}
												</div>
												<div className="d-flex justify-content-center my-1">
													<div className="memory-separator"></div>
												</div>
												<div className="d-flex justify-content-between w-100 mt-1">
													<TagBoxTarget
														boxId={CRISTALES_ID}
													/>
													<div className="memory-separator vertical mx-1"></div>
													<TagBoxTarget
														boxId={VISCOSIDAD_ID}
													/>
													{/*<div className="tag-box-target h-35 w-25"></div>
													<div className="tag-box-target h-35 w-75 ms-1"></div>*/}
												</div>
												<div className="d-flex justify-content-center my-1">
													<div className="memory-separator"></div>
												</div>
												<div className="d-flex justify-content-between w-100 mt-1">
													<TagBoxTarget
														boxId={REDES_ID}
													/>
													<div className="memory-separator vertical mx-1"></div>
													<TagBoxTarget
														boxId={SECRECION_ID}
													/>
													{/*<div className="tag-box-target h-35 w-25"></div>
													<div className="tag-box-target h-35 w-75 ms-1"></div>*/}
												</div>
											</div>
											<div className="bg-tag-pink d-flex align-items-center justify-content-center mt-1 py-1">
												<TagBoxTarget
													boxId={PERDIDA_ID}
												/>
												{/*<div className="tag-box-target h-35 w-100"></div>*/}
											</div>
											<div className="bg-tag-pink d-flex align-items-center justify-content-center mt-1 py-1">
												<TagBoxTarget
													boxId={HIPERREACTIVIDAD_ID}
												/>
												{/*<div className="tag-box-target h-35 w-100"></div>*/}
											</div>
											<div className="bg-tag-pink d-flex align-items-center justify-content-center mt-1 py-1">
												<TagBoxTarget
													boxId={FIBROSIS_ID}
												/>
												{/*<div className="tag-box-target h-35 w-100"></div>*/}
											</div>
										</div>
									</div>
								</div>
								<div className="d-flex align-items-center justify-content-end mt-2">
									<TagBox tagId={EOSINOFILOS_ID} />
									<TagBox tagId={DIFERENCIACION_ID} />
									<TagBox tagId={MASTOCITO_ID} />
									<TagBox tagId={REDES_ID} />
									<TagBox tagId={FIBROLASTOS_ID} />
									<TagBox tagId={PROTEINAS_ID} />
									<TagBox tagId={EPITELIO_ID} />
								</div>
								<div className="d-flex align-items-center justify-content-end mt-2">
									<TagBox tagId={PERDIDA_ID} />
									<TagBox tagId={HIPERREACTIVIDAD_ID} />
									<TagBox tagId={VISCOSIDAD_ID} />
								</div>
								<div className="d-flex align-items-center justify-content-end mt-2">
									<TagBox tagId={FIBROSIS_ID} />
									<TagBox tagId={INFLAMACION_ID} />
								</div>
								<div className="d-flex align-items-center justify-content-end mt-2">
									<TagBox tagId={CRISTALES_ID} />
									<TagBox tagId={SECRECION_ID} />
								</div>
							</div>
						</DndContext>
					</div>
					<div className="col-1">
						<div className="d-flex flex-column align-items-end pe-3">
							<LifeIndicatorBar
								numIntentos={null}
							></LifeIndicatorBar>
						</div>
					</div>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{ position: "absolute", bottom: "5vh" }}
				>
					<div className="d-flex align-items-end justify-content-between mt-3">
						<div>
							<div className="d-flex align-items-center txt12">
								<div className="bg-points-info me-1">
									+{CORRECT_POINTS}
								</div>
								puntos por etiqueta correcta
							</div>
							<div className="d-flex align-items-center mt-2 txt12">
								<div className="bg-wrong-points-info me-1">
									-{WRONG_POINTS}
								</div>
								puntos (hasta {PENALTY_SCORE_LIMIT} puntos)
							</div>
							<div className="d-flex align-items-center mt-3 txt12">
								<img
									src={iconArrastrar}
									alt="arrastra"
									width={37}
									className="me-1"
								></img>
								<div className="w-50">
									Arrastra cada etiqueta a su lugar
									correspondiente.
								</div>
							</div>
						</div>
						<div style={{ zIndex: "20" }}>
							{!quizCompleted ? (
								<button
									onClick={() => {
										checkAnswers();
									}}
									className="btn-iniciar p-2 px-4"
								>
									COMPROBAR
									<img className="ms-2" src={flechas}></img>
								</button>
							) : (
								<NextButton
									navigateTo={NAVIGATE_TO}
								></NextButton>
							)}
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta6);
