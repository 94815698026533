import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndParticipant, postAnswer } from "../../api/answer";
import flechas from "../../assets/flechas.svg";
import iconPreguntaExtra from "../../assets/icono-pregunta-extra.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import TrueOrFalseQuestion from "../../components/TrueOrFalseQuestion";
import { useTimer } from "../../hooks/useTimer";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getParticipantId,
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertQuizAlreadyAnswered,
	modalAnswerExplanationTrueFalseQuestion,
	modalBiblioReferences,
} from "../../utils/logger";

function Pregunta4ExtraVF({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const [answerChecked, setAnswerChecked] = useState(false);

	const [intentosRestantes, setIntentosRestantes] = useState(1);

	const CORRECT_POINTS = 25;
	const WRONG_POINTS = 25;

	const SCENARIO_ID = SCENARIOS.SCENARIO_4;
	const QUIZ_ID = QUIZZES.SCENARIO4_VF;
	const NAVIGATE_TO = ROUTES.P4_SUPERADA;

	const question = {
		value: "Existe una fuerte correlación entre la IL5 intraepitelial y la hiperreactividad bronquial indirecta en asma T2.",
		correctOption: true,
	};

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Al-Shaikhly T, Murphy RC, Parker A, et al.
					<span class="regular">
						Location of eosinophils in the airway wall is critical
						for specific features of airway hyperresponsiveness and
						T2 inflammation in asthma. Eur Respir J.
						2022;60(2):2101865.
					</span>
				</span>
			</li>
		</ol>`;

	const [optionClicked, setOptionClicked] = useState(null);

	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		answerChecked ? null : 1000
	);

	useEffect(() => {
		getAnswerByQuizAndParticipant(QUIZ_ID, getParticipantId())
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					alertQuizAlreadyAnswered({}).then((res) => {});
					setAnswerChecked(true);
				}
			})
			.catch((error) => {});
	}, [location]);

	async function submitAnswer({ correct, score, penalty }) {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					let penaltyScore = penalty || 0;

					dispatch(updateScore(score, SCORE_TYPES.SCORE));
					dispatch(
						updateScore(
							-Math.abs(penaltyScore),
							SCORE_TYPES.PENALTY
						)
					);

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						penalty: participant?.penalty - Math.abs(penaltyScore),
						lastVisited: NAVIGATE_TO,
					});
					setAnswerChecked(true);
				}
			})
			.catch((error) => {});
	}

	function checkAnswers() {
		const correct = optionClicked === question?.correctOption;

		modalAnswerExplanationTrueFalseQuestion({
			title: question?.value,
			points: CORRECT_POINTS,
			correct: correct,
			result: question?.correctOption,
		}).then((res) => {
			if (res.isConfirmed) navigate(NAVIGATE_TO);
		});

		const finalScore = correct ? CORRECT_POINTS : -WRONG_POINTS;

		submitAnswer({
			correct: correct,
			score: finalScore,
			penalty: correct ? 0 : WRONG_POINTS,
		});
	}

	return (
		<div className="h-100 bg-pregunta-extra">
			<div className="container">
				<div className="row h-100 d-flex align-items-center">
					<div className="col-1"></div>
					<div className="col-10 pt-5">
						<div className="d-flex flex-column justify-content-center align-items-center">
							<div className="d-flex align-items-start">
								<Image
									src={iconPreguntaExtra}
									width={52}
								></Image>
								<div className="ms-3">
									<div className="txt26 bold text-red">
										PRUEBA PUNTOS EXTRA
									</div>
									<div className="txt16 bold">
										¿Sabrías decir si esta afirmación es
										verdadera o falsa?
									</div>
								</div>
							</div>
						</div>
						<div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-4">
							<TrueOrFalseQuestion
								question={question?.value}
								onOptionClicked={(option) =>
									setOptionClicked(option)
								}
								clickedOption={optionClicked}
								answerChecked={answerChecked}
							></TrueOrFalseQuestion>
						</div>
					</div>
					<div className="col-1">
						<div className="d-flex flex-column align-items-end">
							<LifeIndicatorBar
								numIntentos={intentosRestantes}
							></LifeIndicatorBar>
						</div>
					</div>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{ position: "absolute", bottom: "10vh" }}
				>
					<div className="d-flex align-items-end justify-content-between mt-5">
						<div>
							<div className="d-flex align-items-center txt12">
								<div className="bg-points-info me-1">
									+{CORRECT_POINTS}
								</div>
								puntos
							</div>
							<div className="d-flex align-items-center mt-2 txt12">
								<div className="bg-wrong-points-info me-1">
									-{WRONG_POINTS}
								</div>
								puntos
							</div>
							<div
								className="txt12 underline text-red cursor-pointer mt-4"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
						</div>
						<div>
							{!answerChecked ? (
								<button
									onClick={() => {
										checkAnswers();
									}}
									className="btn-iniciar p-2 px-4"
								>
									COMPROBAR
									<img className="ms-2" src={flechas}></img>
								</button>
							) : (
								<NextButton
									navigateTo={NAVIGATE_TO}
								></NextButton>
							)}
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta4ExtraVF);
