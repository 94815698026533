import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";

function Pregunta7Superada({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	return (
		<>
			<div className="pt-4 h-100 bg-fin-escenario escenario-7">
				<div className="container h-100">
					<div className="col-12 text-red txt50 bold">
						¡Última parte superada!{" "}
					</div>
					<div className="text-red txt32">
						Avanza para conocer el resultado final.
					</div>

					<div className="row mt-1">
						<div className="col-7 txt16 mt-2">
							<p>
								Finalmente, el médico especialista que ha
								valorado a María decide iniciar tratamiento con
								<span className="bold">mepolizumab</span>{" "}
								(Nucala®) 100 mg s.c. cada 4 semanas.
							</p>
							<p>Nucala® actuará a través de:</p>
							<p className="d-flex align-items-start">
								<div>•</div>{" "}
								<div className="ms-2">
									Crecimiento, diferenciación, reclutamiento,
									activación y supervivencia de los
									eosinófilos: esperaremos una{" "}
									<span className="bold">
										reducción de las exacerbaciones
									</span>
									y la{" "}
									<span className="bold">
										eliminación de las tomas de corticoides
										orales.
									</span>
								</div>
							</p>
							<p className="d-flex align-items-start">
								<div>•</div>{" "}
								<div className="ms-2">
									Galectina-10, CLC y redes extracelulares de
									ADN producidas por los eosinófilos:{" "}
									<span className="bold">
										{" "}
										eficacia en pacientes con
										sensibilización alérgica
									</span>{" "}
									(y niveles elevados de eosinófilos),{" "}
									<span className="bold">
										mejoría de la función pulmonar y el
										remodelado tisular bronquial.
									</span>
								</div>
							</p>
							<p className="d-flex align-items-start">
								<div>•</div>{" "}
								<div className="ms-2">
									CLC y redes extracelulares de ADN producidas
									por los eosinófilos:{" "}
									<span className="bold">
										{" "}
										disminución de la cantidad y viscosidad
										del moco y reparación de la integridad
										epitelial
									</span>{" "}
									tanto bronquial como nasosinusal (con efecto
									también sobre la rinosinusitis crónica con
									pólipos nasales).
								</div>
							</p>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{ position: "absolute", bottom: "10%" }}
					>
						<div className="d-flex justify-content-end">
							<div>
								<NextButton
									navigateTo={ROUTES.IDEAS_CLAVE}
								></NextButton>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta7Superada);
