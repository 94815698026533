import Swal from "sweetalert2";
import { getGameBySessionCode } from "../api/game";
import audioDescriptionIcon from "../assets/audio-description-icon.svg";
import clapIcon from "../assets/clap-icon.svg";
import confirmationIcon from "../assets/confirmation-icon.svg";
import espiral from "../assets/espiral.svg";
import flechas from "../assets/flechas.svg";
import iconoBiblio from "../assets/icono-biblio.png";
import logo from "../assets/logov3.svg";
import mapaSolP1 from "../assets/mapa-solucion-p1.png";
import badThumbIcon from "../assets/register/bad-thumb.svg";
import goodThumbIcon from "../assets/register/good-thumb.svg";
import relojCorto from "../assets/reloj-juego-corto.svg";
import relojLargo from "../assets/reloj-largo.svg";
import solucionP4 from "../assets/solucion-p4-dropdown.png";
import backArrow from "../assets/volver.svg";
import { LONG_GAME_DURATION, SHORT_GAME_DURATION } from "./constants";
import { formatCode, isCodeFormatValid } from "./helpers";

export const logError = ({
	error,
	customMessage = "No custom message from backoffice",
}) => {
	error?.response?.status
		? console.log(
				"ERROR\n" +
					`Status: ${error.response.status}.\n` +
					`Status error: ${error.response.statusText}.\n` +
					`API Message: ${error.response.data?.message}\n` +
					`Backoffice Message: ${customMessage}`
		  )
		: console.log(
				"ERROR\n" +
					`Backoffice message: ${customMessage}\n` +
					`${error}`
		  );
};

export const logFormData = (formData) => {
	console.group("FormData");
	for (let pair of formData.entries())
		console.log("key:", pair[0] + ", value: " + pair[1]);

	console.groupEnd("FormData");
};

export const alertError = ({ error, customMessage }) => {
	const message =
		error?.response?.data?.message ||
		error?.response?.statusText ||
		error ||
		customMessage ||
		"Error has no message";

	let html = `<div class="d-flex flex-column align-items-center">
	<img src=${badThumbIcon} alt='Bad'"/>
	<br />
	<div class="w-75 text-center">
	<p class="modal-register-title">${customMessage}</p>
	</div></div>`;

	const params = {
		html: html,
		allowOutsideClick: false,
		allowEscapeKey: false,
		allowEnterKey: false,
		showCloseButton: true,
		confirmButtonText: `<div class="d-flex align-items-center">
		<img class="me-2 mt-1" src=${backArrow} alt="">VOLVER
	  	</div>`,
		buttonsStyling: false,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-register-container",
			title: "modal-register-title",
			confirmButton: "btn-modal-register",
			closeButton: "modal-btn-close",
		},
	};

	Swal.fire(params);
};

export const alertSuccess = ({ title, customMessage } = {}) => {
	console.log("alertSuccess called");
	const message = customMessage || "Successfull operation!";

	let html = `<div class="d-flex flex-column align-items-center">
	<img src=${goodThumbIcon} alt='Bad'"/>
	<br />
	<div class="w-75 text-center">
	<p class="modal-register-title">${title}</p>
	</div></div>`;

	const params = {
		html: html,
		allowOutsideClick: false,
		allowEscapeKey: false,
		allowEnterKey: false,
		confirmButtonText: "¡A JUGAR!",
		buttonsStyling: false,
		customClass: {
			popup: "modal-register-container",
			title: "modal-register-title",
			confirmButton: "btn-modal-register",
		},
	};

	return Swal.fire(params);
};

export const alertResume = ({} = {}) => {
	let textHtml = `<div>¿Quieres seguir con la partida guardada?</div>`;
	const params = {
		icon: "question",
		html: textHtml || "",
		confirmButtonText: "Sí, continuar partida",
		cancelButtonText: "No, jugar otra",
		showCancelButton: true,
		reverseButtons: true,
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		buttonsStyling: false,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			confirmButton: "btn-modal-register red w-75 mt-3",
			cancelButton: "btn-modal-register w-75",
			popup: "modal-resume-container",
			closeButton: "modal-btn-close",
		},
	};
	return Swal.fire(params);
};

export const alertQuizAlreadyAnswered = ({} = {}) => {
	let textHtml = `<div>
			<div class="">Ya has resuelto esta prueba anteriormente.</div>
			<div class=" mt-2">¡Sigue adelante!</div>
		</div>`;

	const params = {
		icon: "warning",
		html: textHtml || "",
		confirmButtonText: "Continuar",
		reverseButtons: true,
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		buttonsStyling: false,
		customClass: {
			confirmButton: "btn-modal-register red",
			popup: "modal-resume-container",
		},
	};
	return Swal.fire(params);
};

export const alertGroup = ({ data } = {}) => {
	const html = `
	<div class='text-start'>
		<div class='bold'>Nombre: <span class='regular'>${
			data.group.fullName
		}</span></div>
		<div class='bold'>Mesa: <span class='regular'>${
			data.group.tableNum
		}</span></div>
		<div class='bold'>Participantes:<br/> ${data.members
			.map((member) => {
				return `<span class='ps-3 my-1 regular'>${member.fullName}<br/></span>
		`;
			})
			.join("")}</div>
	</div>`;
	const params = {
		icon: "question",
		html: html,
		title: "¿Es este tu grupo?",
		confirmButtonText: "Sí, continuar partida",
		cancelButtonText: "No, escoger otro",
		showCancelButton: true,
		reverseButtons: true,
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		customClass: {
			confirmButton: "bgYellow modal-btn-trap",
			cancelButton: "bgGrey modal-btn-trap",
		},
	};
	return Swal.fire(params);
};

export const alertCaso = ({ caso, completed } = {}) => {
	const message = completed
		? `¡Ya has finalizado el ${caso.fullName}!`
		: caso.status === "not-started"
		? `¡El ${caso.fullName} caso aún no ha empezado!`
		: `¡El ${caso.fullName} ya ha terminado!`;

	const params = {
		icon: "warning",
		title: message,
		confirmButtonText: "Cerrar",
		customClass: {
			confirmButton: "bgYellow modal-btn-trap",
		},
	};
	return Swal.fire(params);
};

export const modalText = ({ title, html } = {}) => {
	const params = {
		title: title || "",
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-text-container",
			htmlContainer: "modal-text-html",
			title: "modal-text-title",
			closeButton: "modal-btn-close",
		},
	};
	Swal.fire(params);
};

export const modalBiblioReferences = ({ referencesHtml } = {}) => {
	let html = `<div class="text-center">
			<div class="d-flex align-items-center justify-content-center">
				<img class="" src=${iconoBiblio} alt="GSK" height="56" />
				<div class="txt18 bold text-red ms-3">Referencias bibliográficas</div>
			</div>
			<br />
		${referencesHtml}	
		</div>`;

	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-references-container",
			closeButton: "modal-btn-close",
		},
	};
	Swal.fire(params);
};

export const modalQuestion5ZoomOption = ({ text } = {}) => {
	let html = `<div class="txt16 text-left">${text}</div>`;

	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-references-container",
			closeButton: "modal-btn-close",
		},
	};
	Swal.fire(params);
};

export const modalHistorial = ({ title, textHtml, image } = {}) => {
	let html = `<div class="text-center">
			<div class="d-flex align-items-center justify-content-center">
				<img src=${image} alt="GSK" height="56" />
				<div class="txt18 bold text-red ms-3">${title}</div>
			</div>
			<p>${textHtml}</p>
		</div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-references-container",
			closeButton: "modal-btn-close",
		},
	};
	Swal.fire(params);
};

export const modalCorrectIncorrectAnswer = ({
	title,
	points,
	textHtml,
	correct,
	allowBack,
	loosedPoints,
} = {}) => {
	let html = `<div class="d-flex flex-column align-items-center text-center">
			<div class="d-flex justify-content-center align-items-center text-center"><p class="${
				correct ? "text-green" : "text-red"
			} txt22 bold">${title}</p></div>
				${
					correct
						? `<br /><p class="bg-points-modal px-3 text-white txt22 bold">
							+${points} puntos
						</p>`
						: ""
				}
				${
					loosedPoints
						? `<br /><p class="bg-wrong-points-modal px-3 text-white txt22 bold">
							-${points} puntos
						</p>`
						: ""
				}
			</div>
			<div class="mt-2">${textHtml}</div>
		</div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showConfirmButton: !allowBack,
		showCloseButton: allowBack,
		showCancelButton: allowBack,
		allowOutsideClick: allowBack,
		buttonsStyling: false,
		confirmButtonText: `<div class="d-flex justify-content-center align-items-center">
			AVANZAR<img class="ms-1" src=${flechas} alt=""></img>
	  	</div>`,
		cancelButtonText: `<div class="d-flex justify-content-center align-items-center">
				<img class="me-2 mt-1" src=${backArrow} alt="" />
				VOLVER
			</div>`,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: `modal-answer-explanation-container ${
				correct ? "correct" : ""
			}`,
			confirmButton: "btn-modal-respuesta txt14",
			closeButton: "modal-btn-close",
			cancelButton: "btn-modal-register",
		},
	};
	return Swal.fire(params);
};

export const modalCorrectIncorrectAnswerP4 = ({
	title,
	textHtml,
	correct,
	loosedPoints,
} = {}) => {
	let html = `<div class="d-flex flex-column align-items-center text-center">
			<div class="d-flex justify-content-center align-items-center text-center"><p class="${
				correct ? "text-green" : "text-red"
			} txt22 bold">${title}</p></div>				
			</div>
			${textHtml}
			<img src=${solucionP4} width="90%"/>
			<br />
			<br />
			${
				!correct
					? `<p class="txt16">Has perdido <span class="text-red bold">${loosedPoints} puntos</span>.</p>`
					: ""
			}
		</div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: false,
		allowOutsideClick: false,
		confirmButtonText: `<div class="d-flex justify-content-center align-items-center">
		AVANZAR<img class="ms-1" src=${flechas} alt=""></img>
	  	</div>`,
		customClass: {
			popup: `modal-answer-explanation-container ${
				correct ? "correct" : ""
			}`,
			confirmButton: "btn-modal-respuesta txt14",
		},
	};
	return Swal.fire(params);
};

export const modalAnswerExplanationMultipleChoiceQuestion = ({
	title,
	subtitle,
	points,
	textHtml,
	correct,
} = {}) => {
	let html = `<div class="d-flex flex-column align-items-center text-center">
	  <p class="${correct ? "bold text-green" : "bold text-red"} txt22">${title}</p>
	  <p class="txt18 bold">${subtitle}</p>
	  <p class="${
			correct ? "bg-green" : "bg-red"
		} text-white txt20 bold px-3" style="border-radius: 16px;">${
		correct ? "+" : "-"
	}${points} puntos</p>
	${textHtml || ""}
  </div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: false,
		allowOutsideClick: false,
		confirmButtonText: `<div class="d-flex justify-content-center align-items-center">
		AVANZAR<img class="ms-1" src=${flechas} alt="">
	  	</div>`,
		customClass: {
			popup: `modal-answer-explanation-container ${
				correct ? "correct" : ""
			}`,
			confirmButton: "btn-modal-respuesta txt14",
		},
	};
	return Swal.fire(params);
};

export const modalAnswerExplanationTrueFalseQuestion = ({
	title,
	points,
	info,
	referencesHtml,
	correct,
	result,
} = {}) => {
	let html = `<div class="d-flex flex-column align-items-center text-center">
	<p class="txt16 bold">${title || ""}</p>
	  <div class="d-flex justify-content-center align-items-center text-center text-black">
	<p class=txt22">${correct ? "¡Efectivamente!" : ""} Esta afirmación es ${
		result ? "verdadera" : "falsa"
	}</p></div>
	  <div class="d-flex flex-column align-items-center text-center">
	  <p class="${
			correct ? "bg-green" : "bg-red"
		} text-white txt20 bold px-3" style="border-radius: 16px;">${
		correct ? "+" : "-"
	}${points} puntos</p>
	</div>
	${info ? `<br/><div class="txt16">${info}</div>` : ""}
  </div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: false,
		allowOutsideClick: false,
		confirmButtonText: `<div class="d-flex justify-content-center align-items-center">
		AVANZAR<img class="ms-1" src=${flechas} alt="">
	  	</div>`,
		customClass: {
			popup: `modal-answer-explanation-container ${
				correct ? "correct" : ""
			}`,
			confirmButton: "btn-modal-respuesta txt14",
			//footer: "d-flex justify-content-center align-items-center text-center",
		},
		//footer: referencesHtml,
	};
	return Swal.fire(params);
};

export const modalQuestionInstructions = ({
	instruccionsTextHtml,
	questionNum,
} = {}) => {
	let html =
		`<div class="d-flex flex-column align-items-center text-center">
				<img class="my-4" src=` +
		logo +
		` alt="GSK" width=100 />
			<div class="mt-2">${instruccionsTextHtml}</div>
		</div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: `modal-instruccions-container escenario-${questionNum}`,
			closeButton: "modal-btn-close",
		},
	};
	Swal.fire(params);
};

export const modalIncorrectAnswerP1 = ({ title, textHtml } = {}) => {
	let html = `<div class="row">
			<div class="col-6 d-flex flex-column align-items-center text-center">
				<div>
					<p class="bold text-red txt22">${title}</p>
				</div>
				<br />${textHtml}
			</div>
			<div class="col-6 d-flex flex-column align-items-center text-center">
			<img src=${mapaSolP1} alt="">
			</div>
		</div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: false,
		confirmButtonText: `<div class="d-flex justify-content-center align-items-center">
		AVANZAR<img class="ms-1" src=${flechas} alt="">
	  	</div>`,
		customClass: {
			htmlContainer: "modal-html-container",
			popup: "modal-references-container",
			confirmButton: "btn-modal-respuesta txt14",
		},
	};
	return Swal.fire(params);
};

export const modalConfirmGroupGame = ({ textHtml } = {}) => {
	let html =
		`<div class="text-center"><div><img class="my-4" src=` +
		espiral +
		` alt='GSK'/></div>${textHtml || ""}</div>`;
	const params = {
		html: html || "",
		allowOutsideClick: false,
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: true,
		showCloseButton: true,
		reverseButtons: true,
		showCancelButton: true,
		confirmButtonText: "Continuar",
		cancelButtonText: `<div class="d-flex align-items-center">
		<img class="me-2 mt-1" src=${backArrow} alt="">VOLVER
	  	</div>`,
		buttonsStyling: false,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			confirmButton: "btn-modal-register red ms-3",
			cancelButton: "btn-modal-register",
			popup: "modal-unirse-partida-container",
			closeButton: "modal-btn-close",
		},
	};
	return Swal.fire(params);
};

export const modalUnirsePartida = ({} = {}) => {
	let html =
		`<div class="text-center"><div><img class="my-4" src=` +
		espiral +
		` alt='GSK'/></div></div>`;
	const params = {
		html: html || "",
		allowOutsideClick: false,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		confirmButtonText: "Crear una nueva partida",
		cancelButtonText: "Unirse a partida",
		showCancelButton: true,
		buttonsStyling: false,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			confirmButton: "btn-modal-crear-partida",
			cancelButton: "btn-modal-unirse-partida",
			popup: "modal-unirse-partida-container",
			closeButton: "modal-btn-close",
		},
	};
	return Swal.fire(params);
};

export const modalChooseGameDuration = ({} = {}) => {
	let html =
		`<div class="text-center"><div><img class="my-4" src=` +
		espiral +
		` alt='GSK'/></div><p class="modal-crear-partida-text">Selecciona en qué modalidad quieres jugar</p></div>`;
	const params = {
		html: html || "",
		allowOutsideClick: false,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		confirmButtonText: `<div class="d-flex justify-content-center align-items-center">
		<img class="me-2 mt-1" src=${relojCorto} alt="">JUEGO ${SHORT_GAME_DURATION} MIN
	  	</div>`,
		cancelButtonText: `<div class="d-flex justify-content-center align-items-center">
		<img class="me-2 mt-1" src=${relojLargo} alt="">JUEGO ${LONG_GAME_DURATION} MIN
	  	</div>`,
		showCancelButton: true,
		buttonsStyling: false,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-duracion-juego-container",
			confirmButton: "btn-duracion-juego",
			cancelButton: "btn-duracion-juego",
			closeButton: "modal-btn-close",
		},
	};
	return Swal.fire(params);
};

export const modalIntroducirCodigoPartida = ({} = {}) => {
	let html =
		`<div class="text-center"><div><img class="my-4" src=` +
		espiral +
		` alt='GSK'/></div><p class="modal-introducir-codigo-partida-text">Introduce el <strong>código de la partida</strong></p></div>`;
	const params = {
		html: html || "",
		input: "text",
		inputPlaceholder: "XXXX",
		allowOutsideClick: false,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		confirmButtonText: "Continuar",
		buttonsStyling: false,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			confirmButton: "btn-modal-introducir-codigo-partida",
			popup: "modal-introducir-codigo-partida-container",
			closeButton: "modal-btn-close",
			input: "input-codigo-partida",
		},
		inputAttributes: {
			autocomplete: "off",
		},
		inputValidator: async (value) => {
			let val = value.replace(/\s/g, "");
			if (!val) return "Introduce el código";
			if (!isCodeFormatValid(val)) return "Código no válido";
			try {
				const response = await getGameBySessionCode(val);
			} catch (error) {
				return "Código no válido";
			}
		},
	};
	return Swal.fire(params);
};

export const modalCrearPartida = ({ codigo } = {}) => {
	let code = formatCode(codigo);
	let html =
		`<div class="text-center">
			<div>
				<img class="my-4" src=` +
		espiral +
		` alt='GSK'/>
			</div>			
			<p class="modal-introducir-codigo-partida-text">
				El código de partida es <strong style="color: #D81925; font-weight: 900;">${code}</strong>
			</p>
			<div class="d-flex justify-content-center">
			<p class="modal-crear-partida-text">
				A continuación, completa los datos 
				con el nombre del hospital
			</p>
			</div>
		</div>`;
	const params = {
		html: html || "",
		input: "text",
		inputPlaceholder: "Escribe el nombre del Hospital",
		allowOutsideClick: false,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		confirmButtonText: "Continuar",
		buttonsStyling: false,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-crear-partida-container",
			confirmButton: "btn-modal-introducir-codigo-partida",
			closeButton: "modal-btn-close",
			input: "input-codigo-partida",
		},
		inputAttributes: {
			autocomplete: "off",
		},
		inputValidator: (value) => {
			if (!value) {
				return "Introduce el nombre del hospital";
			}
		},
	};
	return Swal.fire(params);
};

export const modalAudio = ({ title, html } = {}) => {
	let icon = `<img src=${audioDescriptionIcon} alt='test' class="modal-audio-icon"/>`;
	const params = {
		title: title || "",
		html: icon.concat(html) || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		backdrop: "rgba(36,40,79, .85)",
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-audio-container",
			htmlContainer: "modal-audio-html",
			title: "modal-audio-title",
			closeButton: "modal-btn-close",
		},
	};
	return Swal.fire(params);
};

export const modalQuizCompleted = ({
	title,
	html,
	confirmText,
	cancelText,
} = {}) => {
	let icon = `<img src=${clapIcon} alt='test' class="modal-quizCompleted-icon"/>`;
	const params = {
		title: title,
		html: icon.concat(html) || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		reverseButtons: true,
		confirmButtonText: confirmText,
		cancelButtonText: cancelText,
		showCancelButton: true,
		backdrop: "rgba(36,40,79, .85)",
		customClass: {
			popup: "modal-quizCompleted-container",
			title: "modal-quizCompleted-title",
			htmlContainer: "modal-quizCompleted-html",
			confirmButton: "bgYellow modal-btn-trap",
			cancelButton: "bgYellow modal-btn-trap",
		},
	};
	return Swal.fire(params);
};

export const modalTest = ({ type, title, html, extraBtnText } = {}) => {
	const params = {
		title: title,
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		reverseButtons: true,
		confirmButtonText: "CERRAR",
		showCancelButton: extraBtnText,
		cancelButtonText: extraBtnText,
		backdrop: "rgba(36,40,79, .85)",
		customClass: {
			popup: "modal-test-container",
			title: `modal-test-title modal-test-title-${type} d-flex align-items-center justify-content-center`,
			htmlContainer: "modal-test-html",
			confirmButton: "bgYellow modal-btn-trap",
			cancelButton: "bgGrey modal-btn-trap",
		},
	};
	return Swal.fire(params);
};

export const modalImage = ({
	title,
	image,
	fullscreen,
	paddingTop,
	containerClass,
	references,
	imageWidth,
} = {}) => {
	let html = `<div>
			<div class="bold text-left" style="max-width: 150px; position: absolute;">${
				title || ""
			}</div>
			<img src=${image} width=${imageWidth || "80%"}></img>
			<div class="d-flex justify-content-center"><div class="txt12 text-center mt-3" style="width: ${
				imageWidth || "80%"
			};">${references || ""}</div></div>
		</div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: containerClass || "modal-image-container",
			closeButton: "modal-btn-close",
			image: `modal-image-image${
				fullscreen ? "fullscreen" : ""
			} ${paddingTop}`,
		},
	};
	return Swal.fire(params);
};

export const modalImage2 = ({
	title,
	image,
	fullscreen,
	paddingTop,
	containerClass,
	references,
} = {}) => {
	let html = `<div>
			<div class="bold text-left"">${title || ""}</div>
			<img class="mt-3" src=${image} width="900"></img>
			<div class="txt12 text-left mt-3" style="width: 900px">${references || ""}</div>
		</div>`;
	const params = {
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: containerClass || "modal-image-container",
			closeButton: "modal-btn-close",
			image: `modal-image-image${
				fullscreen ? "fullscreen" : ""
			} ${paddingTop}`,
		},
	};
	return Swal.fire(params);
};

export const modalAnswer = ({
	title,
	type,
	html,
	btnText,
	containerClass,
} = {}) => {
	const params = {
		title: title,
		html: html,
		allowEnterKey: false,
		allowEscapeKey: false,
		confirmButtonText: btnText || "AVANZAR",
		backdrop: "rgba(36,40,79, .85)",
		customClass: {
			popup: containerClass || "modal-answer-container",
			title: `modal-answer-title modal-${type}`,
			htmlContainer: "modal-answer-html",
			confirmButton: "bgYellow modal-btn-trap",
		},
	};
	return Swal.fire(params);
};

export const modalConfirm = ({ text, titleClass } = {}) => {
	let icon = `<img src=${confirmationIcon} alt='test' class="modal-confirm-icon"/>`;
	const params = {
		title: text || "¿Estás seguro de tu elección?",
		html: icon || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		reverseButtons: true,
		confirmButtonText: "ACEPTAR",
		cancelButtonText: "CANCELAR",
		showCancelButton: true,
		backdrop: "rgba(36,40,79, .85)",
		customClass: {
			popup: "modal-confirm-container",
			title: titleClass || "modal-confirm-title",
			confirmButton: "bgYellow modal-btn-trap",
			cancelButton: "bgGrey modal-btn-trap",
		},
	};
	return Swal.fire(params);
};

export const modalConfirmTest = ({ html }) => {
	let icon = `<img src=${confirmationIcon} alt='test' class="modal-confirm-icon"/>`;
	const params = {
		title: "¿Seguro que quieres solicitar esta prueba?",
		html: html.concat(icon) || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		reverseButtons: true,
		confirmButtonText: "ACEPTAR",
		cancelButtonText: "CANCELAR",
		showCancelButton: true,
		backdrop: "rgba(36,40,79, .85)",
		customClass: {
			popup: "modal-confirm-container",
			title: "modal-confirm-title pt-5 mt-4",
			confirmButton: "bgYellow modal-btn-trap",
			cancelButton: "bgGrey modal-btn-trap",
		},
	};
	return Swal.fire(params);
};

export const modalPDF = () => {
	let html = `<iframe
			id='pdfviewer'
			src='https://docs.google.com/gview?embedded=true&url=https://mtxperience-api.owius.com/uploads/fichatecnica.pdf&amp;embedded=true'
			frameborder='0'
			width='90%'
			height='550px'
			type='application/pdf'
			></iframe>`;
	const params = {
		title: "FICHA TÉCNICA",
		html: html || "",
		allowEnterKey: false,
		allowEscapeKey: false,
		showCloseButton: true,
		showConfirmButton: false,
		backdrop: "rgba(36,40,79, .85)",
		closeButtonHtml: `<div class="modal-btn-close"></div>`,
		customClass: {
			popup: "modal-pdf-container",
			title: "modal-text-title",
			closeButton: "modal-btn-close",
		},
	};
	Swal.fire(params);
};
