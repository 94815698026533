import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import NextButton from "../components/NextButton";
import { useLang } from "../translations/i18n";
import { ROUTES } from "../utils/constants";

function TransitionScenarios({ intl }) {
	const lang = useLang();
	const game = useSelector((state) => state.game);
	const group = useSelector((state) => state.group);
	const prevState = useLocation();

	const [isCompleted, setIsCompleted] = useState(false);
	const { state } = useLocation();
	const { completed } = state || false;

	const actualScenario = useSelector((state) => state?.group?.actualScenario);
	const activeScenario = prevState?.state?.activeScenario || actualScenario;

	let next =
		activeScenario === 1
			? ROUTES.P2_INTRO
			: activeScenario === 2
			? ROUTES.P3_INTRO
			: activeScenario === 3
			? ROUTES.P4_INTRO
			: activeScenario === 4
			? ROUTES.P5_INTRO
			: activeScenario === 5
			? ROUTES.P6_INTRO
			: activeScenario === 6
			? ROUTES.P7_INTRO
			: "";

	const navigateTo = prevState?.state?.navigateTo || next;

	return (
		<div className="bgdoors">
			<div className="container h-100">
				<div className="row h-100">
					<div
						style={{
							position: "absolute",
							bottom: "5vh",
							right: "-20%",
						}}
					>
						<div className="d-flex justify-content-center">
							<NextButton
								navigateTo={navigateTo || ROUTES.HISTORIAL}
							></NextButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(TransitionScenarios);
