import { uploadSingleFile } from "../files";
import { API, baseClient } from "../index";

export const postIndividualParticipant = async (participant, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "participants");
		participant.image = response?.data?.fileURL;
	}
	return baseClient().post(`${API}/participant/individual`, participant);
};

export const postGroupParticipant = async (group, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "participants");
		group.image = response?.data?.fileURL;
	}
	return baseClient().post(`${API}/participant/group`, group);
};

export const updateParticipant = async (id, group, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "participants");
		group.image = response?.data?.fileURL;
	}
	return baseClient().put(`${API}/participant/${id}`, group);
};

export const getParticipantById = async (id) => {
	return baseClient().get(`${API}/participant/by-id/${id}`);
};

export const getParticipantsByGame = async (id) => {
	return baseClient().get(`${API}/participant/game/${id}`);
};
