import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import espiral from "../assets/espiral.svg";
import {
	default as iconoHistorial,
	default as iconoHistorialBlanco,
} from "../assets/icono-historial.png";
import {
	default as iconoResultado,
	default as iconoResultadoBlanco,
} from "../assets/icono-resultados.png";
import logo from "../assets/logov2.svg";
import NextButton from "../components/NextButton";
import { ROUTES } from "../utils/constants";
import { modalHistorial } from "../utils/logger";

function StartPacienteHistorial({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();
	const participant = useSelector((state) => state?.group);

	const textHistorial = `<div class="paciente-historial-text">
			<p>Exfumadora desde hace 15 años.</p>
			<p>
				Diagnosticada de
				<strong>asma</strong>
				grave hace 15 años, con sensibilidad a ácaros. Tratamiento:
				GCI/LABA+ LAMA en dosis altas.
			</p>
			<p>
				Valorada por ORL hace 5 años con diagnóstico de
				<strong>rinosinusitis crónica con pólipos nasales</strong>, en
				tratamiento con lavados nasales y corticoide intranasal.
			</p>
			<p>
				<strong>
					Empeoramiento de los síntomas de asma desde hace un año
				</strong>
				: tos, opresión torácica, sibilancias y expectoración mucosa
				sobre todo al despertar y al acostarse.
			</p>
			<p>
				Ha tenido 2 exacerbaciones tratadas con ciclos de COs en el
				último año en época de alergias estacionarias.
			</p>
			<p class="txt13">
				CO: corticoides orales; GCI: glucocorticoides inhalados; LABA:
				agonista β2 de larga duración; LAMA: anticolinérgico de larga
				duración; ORL: otorrinolaringología.
			</p>
		</div>`;

	const textResultados = `<div class="paciente-historial-text">
			<p>
				<strong>Eosinófilos basales</strong>: 450 células/mm3
			</p>
			<p>
				<strong>Espirometría basal</strong>
			</p>
			<p class="ms-4">
				<span class="text-red bold me-2">•</span> FVC: 3350 ml (75 %)
			</p>
			<p class="ms-4">
				<span class="text-red bold me-2">•</span> FEV1: 2850 ml (68 %
				respecto al predicho)
			</p>
			<p>
				<strong>Prueba broncodilatadora</strong>: +280 ml (+15 %)
			</p>
			<p>
				<strong>IgE total</strong>: 240 UI/ml;
				<strong>IgE D. pteronyssinus</strong>: 3,8 KUA/l
			</p>
			<p class="txt13">
				FEV1: volumen espiratorio forzado en el primer segundo; FVC: capacidad vital forzada;
				IgE: inmunoglobulina E
			</p>
		</div>`;

	return (
		<div className="pt-4 h-100">
			<div className="container h-100">
				<div className="container d-flex justify-content-between legalGebro pb-3">
					<div>
						<img
							src={logo}
							alt="MTxperience"
							className="img-fluid mw170"
						/>
					</div>
				</div>
				<div className="d-flex justify-content-center align-items-center h-75">
					<div className="text-center selection">
						<img className="my-4" src={espiral} alt="GSK" />
						<h1 className="text-white">
							<span className="bold">María 45 años</span>
						</h1>
						<div className="my-5">
							<div className="d-flex justify-content-center">
								<div
									className="bg-historial-item p-3"
									style={{ width: "190px" }}
								>
									<div className="d-flex flex-column align-items-center">
										<img
											src={iconoHistorialBlanco}
											height={90}
										></img>
										<button
											className="btn-iniciar mt-4 py-2 d-flex justify-content-center"
											style={{ width: "140px" }}
											onClick={(event) => {
												event.stopPropagation();
												modalHistorial({
													title: "Historial clínico",
													textHtml: textHistorial,
													image: iconoHistorial,
												});
											}}
										>
											Historial
										</button>
									</div>
								</div>
								<div
									className="bg-historial-item p-3 ms-5"
									style={{ width: "190px" }}
								>
									<div className="d-flex flex-column align-items-center">
										<img
											src={iconoResultadoBlanco}
											height={90}
										></img>
										<button
											className="btn-iniciar mt-4 py-2 d-flex justify-content-center"
											style={{ width: "140px" }}
											onClick={(event) => {
												event.stopPropagation();
												modalHistorial({
													title: "Resultados pruebas",
													textHtml: textResultados,
													image: iconoResultado,
												});
											}}
										>
											Resultados
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div
						className="d-flex justify-content-end"
						style={{ marginTop: "-5vh" }}
					>
						<div>
							<NextButton navigateTo={ROUTES.START}></NextButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(StartPacienteHistorial);
