import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import iconoIdeasClave from "../../assets/icono-ideas-clave.png";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalBiblioReferences } from "../../utils/logger";

function IdeasClave({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const REFERENCES = `<ol class="numbered-list txt12">
			<li>
				<span class="reference-text txt12">
					Domingo Ribas C, Carrillo Díaz T, Blanco Aparicio M, et al.
					<span class="regular">
						REal worlD Effectiveness and Safety of Mepolizumab in a
						Multicentric Spanish Cohort of Asthma Patients
						Stratified by Eosinophils: The REDES Study. Drugs.
						2021;81(15):1763-74.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text txt12">
					Arismendi E, Cisneros C, Blanco-Aparicio M, et al.
					<span class="regular">
						REDES study: Mepolizumab is effective in patients with
						severe eosinophilic asthma and comorbid nasal polyps.
						Abstract presentado en The European Respiratory Society
						(ERS) International Congress 2022. 4-6 sept., 2022;
						Barcelona (España).
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text txt12">
					Domingo Ribas C, Pavord I, Price RG, et al.
					<span class="regular">
						Mepolizumab Treatment Leads to Clinical Remission in
						Patients with Severe Eosinophilic Asthma: Results from
						the Real-World REDES Study. Am J Respir Crit Care Med.
						2022;205:A4834.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text txt12">
					Impact 4. Domingo-Ribas C, Pavord I, Bañas Conejero D, et
					al.
					<span class="regular">
						Impact of baseline characteristics on clinical remission
						achievement in severe asthma. Abstract presentado en The
						European Respiratory Society (ERS) International
						Congress 2022. 4-6 sept., 2022; Barcelona (España).
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text txt12">
					González-Barcala FJ, Blanco-Aparicio M, Gómez-Bastero A, et
					al.
					<span class="regular">
						Influence of baseline FEV1 on improvement in severe
						eosinophilic asthma with mepolizumab. Póster presentado
						en The European Respiratory Society (ERS) International
						Congress 2022. 4-6 sept., 2022; Barcelona (España).
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text txt12">
					Quirce S, Carrillo T, Bobolea I, et al.
					<span class="regular">
						REDES study outcomes: influence of atopy and BMI in
						severe asthma. Abstract presentado en EAACI (The
						European Academy of Allergy and Clinical Immunology)
						Hybrid Congress 2022. 1-3 jul., 2022. Praga (República
						Checa) y online.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text txt12">
					Domingo-Ribas C, Díaz-Campos R, Hermida-Valverde T, et al.
					<span class="regular">
						Clinical improvement in REDES study stratified by blood
						eosinophil reduction after mepolizumab treatment.
						Abstract presentado en The European Respiratory Society
						(ERS) International Congress 2022. 4-6 sept., 2022;
						Barcelona (España).
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text txt12">
					Khurana S, Brusselle GG, Bel EH, et al.
					<span class="regular">
						Long-term Safety and Clinical Benefit of Mepolizumab in
						Patients With the Most Severe Eosinophilic Asthma: The
						COSMEX Study. Clin Ther. 2019;41(10):2041-56.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text txt12 regular">Ficha técnica Nucala® polvo.</span>
			</li>
			<li>
				<span class="reference-text txt12 regular">
					Ficha técnica Nucala® líquido.
				</span>
			</li>
		</ol>`;

	return (
		<>
			<div className="h-100 bgprueba">
				<div className="container d-flex justify-content-center align-items-center h-50">
					<div className="row h-100 px-5 d-flex justify-content-center align-items-center pt-4">
						<div className="col-12 px-5 d-flex flex-column align-items-center">
							<div className="d-flex align-items-center">
								<img src={iconoIdeasClave} width={45}></img>
								<div className="text-red txt26 ms-2 bold">
									IDEAS CLAVE
								</div>
							</div>
							<div className="col-8 mt-4">
								<div className="d-flex justify-content-start bold">
									Nucala®:
								</div>
								<p className="d-flex align-items-start mt-3">
									<div>•</div>{" "}
									<div className="ms-2">
										Ha mostrado eficacia sostenida tanto en
										ensayos clínicos como en vida real
										demostrada en múltiples perfiles de
										pacientes<sup>1-8</sup>.
									</div>
								</p>
								<p className="d-flex align-items-start">
									<div>•</div>{" "}
									<div className="ms-2">
										Ha mostrado tener un perfil de seguridad
										favorable a 4,8 años en ensayos clínicos
										y en vida real<sup>1,8-10</sup>.
									</div>
								</p>
								<p className="d-flex align-items-start">
									<div>•</div>{" "}
									<div className="ms-2">
										Actúa con precisión sobre la IL5
										reduciendo los eosinófilos en sangre a
										niveles normales<sup>1,8-10</sup>.
									</div>
								</p>
							</div>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{ position: "absolute", bottom: "10vh" }}
					>
						<div className="d-flex align-items-end justify-content-between">
							<div
								className="txt12 underline text-red cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
							<div>
								<NextButton
									navigateTo={ROUTES.END}
								></NextButton>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(IdeasClave);
