import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import dibujoMujer from "../../assets/dibujo-mujer.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalBiblioReferences } from "../../utils/logger";
import NextButton from "../../components/NextButton";

function Pregunta2Solucion({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [intentosRestantes, setIntentosRestantes] = useState(1);

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Travers J, Rothenberg ME.
					<span class="regular">
						Eosinophils in mucosal immune responses. Mucosal
						Immunol. 2015;8(3):464-75.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Tworek D, Antczak A.
					<span class="regular">
						Eosinophilic COPD - a distinct phenotype of the disease.
						Adv Respir Med. 2017;85(5):271-6.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Sastre B, Rodrigo-Muñoz JM, Garcia-Sanchez DA, et al.
					<span class="regular">
						Eosinophils: Old Players in a New Game. J Investig
						Allergol Clin Immunol. 2018;28(5):289-304.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Furuta S, Iwamoto T, Nakajima H.
					<span class="regular">
						Update on eosinophilic granulomatosis with polyangiitis.
						Allergol Int. 2019;68(4):430-6.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Ramirez GA, Yacoub M-R, Ripa M, et al.
					<span class="regular">
						Eosinophils from Physiology to Disease: A Comprehensive
						Review. Biomed Res Int. 2018;9095275.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Long H, Zhang G, Wang L, et al.
					<span class="regular">
						Eosinophilic Skin Diseases: A Comprehensive Review.
						Clinic Rev Allergy Immunol. 2016;50(2):189-213.
					</span>
				</span>
			</li>
		</ol>`;

	return (
		<>
			<div className="h-100 bgprueba">
				<div className="container">
					<div className="row h-100">
						<div className="col-11">
							<p className="col-7 txt16">
								He aquí algunas patologías que cursan con
								eosinofilia; no necesariamente son la causa de
								la enfermedad, sino un signo de ella (que, a su
								vez, puede agravar la enfermedad):
							</p>
							<div className="d-flex justify-content-around align-items-center pt-4">
								<div>
									<div className="tag-box-solved bg-transparent txt14 w-175">
										<span>
											Asma
											<sup> 1</sup>
										</span>
									</div>
									<div className="tag-box-solved bg-transparent txt13 mt-2 w-175">
										<span>
											Gastroenteritis/colitis eosinofílica
											<sup> 1</sup>
										</span>
									</div>
									<div className="tag-box-solved bg-transparent txt14 mt-2 w-175">
										<span>
											EPOC eosinofílica<sup> 2</sup>
										</span>
									</div>
									<div className="tag-box-solved bg-transparent txt13 mt-2 w-175">
										<span>
											Neumonía eosinofílica
											<sup> 3</sup>
										</span>
									</div>
									<div className="tag-box-solved bg-transparent txt12 mt-2 w-175">
										<span>
											Aspergilosis broncopulmonar alérgica
											<sup> 3</sup>
										</span>
									</div>
								</div>
								<div>
									<img src={dibujoMujer} height={350}></img>
								</div>
								<div>
									<div className="tag-box-solved bg-transparent txt12 w-175">
										<span>
											Granulomatosis eosinofílica con
											poliangitis
											<sup> 4</sup>
										</span>
									</div>
									<div className="tag-box-solved bg-transparent txt13 mt-2 w-175">
										<span>
											Rinosinusitis crónica con pólipos
											nasales
											<sup> 5</sup>
										</span>
									</div>
									<div className="tag-box-solved bg-transparent txt14 mt-2 w-175">
										<span>
											Esofagitis eosinofílica<sup> 1</sup>
										</span>
									</div>
									<div className="tag-box-solved bg-transparent txt14 mt-2 w-175">
										<span>
											Síndrome hipereosinofílico
											<sup> 3</sup>
										</span>
									</div>
									<div className="tag-box-solved bg-transparent txt13 mt-2 w-175">
										<span>
											Dermatosis eosinofílica
											<sup> 6</sup>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className="col-1">
							<div className="d-flex flex-column align-items-end">
								<LifeIndicatorBar
									numIntentos={intentosRestantes}
								></LifeIndicatorBar>
							</div>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{
							position: "absolute",
							bottom: "10vh",
						}}
					>
						<div className="d-flex align-items-center justify-content-between mt-4 pt-2">
							<div>
								<div
									className="txt12 underline text-red cursor-pointer"
									onClick={(e) => {
										e.stopPropagation();
										modalBiblioReferences({
											referencesHtml: REFERENCES,
										});
									}}
								>
									Ver referencias
								</div>
							</div>
							<div>
								<NextButton
									navigateTo={ROUTES.P2_PART2}
								></NextButton>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta2Solucion);
