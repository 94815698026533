import { getGameById } from "../api/game";
import { updateParticipant } from "../api/participant";
import { setGame, setGroup } from "../redux/actions";
import { NO_TIME_PROGRESS_SCREENS, ROUTES } from "./constants";

export function formatScore(score) {
	if (!score) return "0000";
	else if (score < 0) {
		return (
			(score > -10 ? "-00" : score > -100 ? "-0" : "-") + Math.abs(score)
		);
	} else {
		return (
			(score < 10
				? "000"
				: score < 100
				? "00"
				: score < 1000
				? "0"
				: "") + score
		);
	}
}

export function formatScoreTopBar(score) {
	if ((score === undefined) | isNaN(score)) return "0000";
	if (score < 0) {
		return (
			(score > -10
				? "- 000"
				: score > -100
				? "- 00"
				: score > -1000
				? "- 0"
				: "- ") + Math.abs(score)
		);
	} else {
		return (
			(score < 10
				? "000"
				: score < 100
				? "00"
				: score < 1000
				? "0"
				: "") + score
		);
	}
}

export function formatTime(time) {
	if (!time) return ` 0' 00"`;
	else
		return `${Math.floor(time / 60)}' ${
			time - Math.floor(time / 60) * 60
		}"`;
}

export function formatMinutes(time, timeout, finished) {
	if (time === undefined || isNaN(time) || finished) return "00";
	let remaining = (timeout - time) / 60;
	if (remaining <= 0)
		return (
			"-" + (remaining > -9 ? "0" : "") + Math.floor(Math.abs(remaining))
		);
	return (remaining < 10 ? "0" : "") + Math.floor(remaining);
}
export function formatSeconds(time, timeout, finished) {
	if (time === undefined || isNaN(time) || finished) return "00";
	let remaining = (timeout - time) % 60;
	if (remaining < 0)
		return (remaining > -10 ? "0" : "") + Math.abs(remaining);
	return (remaining < 10 ? "0" : "") + remaining;
}

export function getTimer() {
	return localStorage.getItem("total");
}

export function updateTimer(value) {
	localStorage.setItem("total", value);
}

export function clearTimer() {
	localStorage.removeItem("total");
}

export function getParticipantId() {
	return localStorage.getItem("participantId");
}

export function setParticipantId(participantId) {
	localStorage.setItem("participantId", participantId);
}

export function clearParticipant() {
	localStorage.removeItem("participantId");
}

export async function caseIsActive(gameId, caso) {
	return getGameById(gameId)
		.then((res) => {
			if (res.status === 200) {
				return res.data.clinicCases[caso].status === "running";
			}
		})
		.catch((error) => {
			return false;
		});
}

export async function updateStats(participant) {
	delete participant.life;
	await updateParticipant(participant?._id, participant);
}

export function getTimePenalty(score) {
	let penalty = 0;

	penalty = Math.round(0.25 * score);

	return penalty;

	/*

	if (score > 0 && time >= 2400) {
		if (time >= 2700) penalty = score * 0.5;
		else penalty = score * 0.25; //time >= 2400

		if (penalty % 5 === 0 || penalty % 0 === 0) return penalty;
		return Math.round(penalty / 5) * 5;
	}
	return penalty;*/
}

export function formatCode(code) {
	let codigo = code.toString();
	codigo = codigo.trim();

	return codigo;
}

export function isCodeFormatValid(code) {
	const pattern = /^[0-9]+$/;

	return pattern.test(code.toString());
}

export function getScenarioByScreen(path) {
	let scenario1Paths = [
		ROUTES.P1,
		ROUTES.P1_EXPLICATION,
		ROUTES.P1_EXPLICATION_EXTRA,
		ROUTES.P1_EXTRA,
		ROUTES.P1_EXTRA2,
		ROUTES.P1_EXTRA_VF,
		ROUTES.P1_EXTRA_VF2,
		ROUTES.P1_EXTRA_VF_EXTRA,
		ROUTES.P1_INTRO,
		ROUTES.P1_PART2,
		ROUTES.P1_SUPERADA,
	];

	let scenario2Paths = [
		ROUTES.P1_SUPERADA,
		ROUTES.P2_INTRO,
		ROUTES.P2,
		ROUTES.P2_PART2,
		ROUTES.P2_EXTRA,
		ROUTES.P2_SOLUTION,
		ROUTES.P2_SUPERADA,
	];
	let scenario3Paths = [
		ROUTES.P2_SUPERADA,
		ROUTES.P3_INTRO,
		ROUTES.P3,
		ROUTES.P3_EXPLICATION,
		ROUTES.P3_SOLUTION,
		ROUTES.P3_SUPERADA,
	];
	let scenario4Paths = [
		ROUTES.P3_SUPERADA,
		ROUTES.P4,
		ROUTES.P4_EXPLICATION,
		ROUTES.P4_EXTRA,
		ROUTES.P4_EXTRA_VF,
		ROUTES.P4_INTRO,
		ROUTES.P4_SOLUTION,
		ROUTES.P4_SUPERADA,
	];
	let scenario5Paths = [
		ROUTES.P4_SUPERADA,
		ROUTES.P5,
		ROUTES.P5_AMPLIAR,
		ROUTES.P5_EXTRA,
		ROUTES.P5_INTRO,
		ROUTES.P5_SUPERADA,
	];
	let scenario6Paths = [
		ROUTES.P5_SUPERADA,
		ROUTES.P6,
		ROUTES.P6_AMPLIAR,
		ROUTES.P6_EXPLICATION,
		ROUTES.P6_INTRO,
		ROUTES.P6_INTRO,
		ROUTES.P6_SOLUTION,
		ROUTES.P6_SUPERADA,
	];
	let scenario7Paths = [
		ROUTES.P6_SUPERADA,
		ROUTES.P7,
		ROUTES.P7_INTRO,
		ROUTES.P7_SOLUTION,
		ROUTES.P7_SUPERADA,
	];

	if (scenario1Paths.includes(path)) return 1;
	else if (scenario2Paths.includes(path)) return 2;
	else if (scenario3Paths.includes(path)) return 3;
	else if (scenario4Paths.includes(path)) return 4;
	else if (scenario5Paths.includes(path)) return 5;
	else if (scenario6Paths.includes(path)) return 6;
	else if (scenario7Paths.includes(path)) return 7;
	else return 1;
}

export function getViewport() {
	const width = Math.max(
		document.documentElement.clientWidth,
		window.innerWidth || 0
	);
	if (width <= 576) return "xs";
	if (width <= 768) return "sm";
	if (width <= 992) return "md";
	if (width <= 1200) return "lg";
	return "xl";
}

export function reconnect({ participant, dispatch, navigate, navigateTo }) {
	dispatch(setGroup(participant));
	dispatch(setGame(participant?.game));
	setParticipantId(participant?._id);

	if (participant?.lastVisited) {
		if (
			!NO_TIME_PROGRESS_SCREENS.includes(participant?.lastVisited) &&
			!participant?.finished
		) {
			updateTimer(participant?.time);
		}
		navigate(navigateTo ? navigateTo : participant?.lastVisited);
	} else navigate(ROUTES.INDIVIDUAL_GRUPAL);
}
