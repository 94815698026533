import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logov3.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";

function IntroPregunta7({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [scenarioBarExpanded, setScenarioBarExpanded] = useState(true);

	return (
		<>
			<div className="pt-4 h-100 bg-intro-prueba">
				<div className="container d-flex justify-content-between legalGebro pb-3">
					<div>
						<img src={logo} alt="gsk" className="img-fluid mw170" />
					</div>
				</div>
				<div
					className={`container bg-maria-scenario1-intro ${
						!scenarioBarExpanded ? "expanded" : ""
					} h-100`}
				>
					<div className="row pt-4">
						<div className="col-9 py-2">
							<div className="row d-flex align-items-center title">
								<div className="col-1">
									<div className="question-num-bg">7</div>
								</div>
								<div className="col-8 ms-2">
									<span>
										Mepolizumab: <br />
										datos de eficacia
									</span>
								</div>
							</div>
							<br />
							<div className="paciente-historial-text">
								<p className="w-50">
									Ya hemos valorado a María y ahora hay que
									pautarle medidas terapéuticas. Con todo lo
									que acabamos de ver,{" "}
									<span className="bold">
										¿cuáles serían los datos de eficacia de
										mepolizumab?
									</span>
								</p>
							</div>
						</div>
					</div>
					<div style={{ position: "absolute", bottom: "30%" }}>
						<NextButton navigateTo={ROUTES.P7}></NextButton>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
					expanded={true}
					setScenarioBarExpanded={setScenarioBarExpanded}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(IntroPregunta7);
