import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import individualPlaceholder from "../assets/individual-placeholder.png";
import groupPlaceholder from "../assets/group-profile-placeholder.png";
import logoGSK from "../assets/gsk.svg";
import logoVivactis from "../assets/logo-vivactis.png";
import bonusIcon from "../assets/results/bonus-icon.svg";
import penaltyIcon from "../assets/results/penalty-icon.svg";
import scoreIcon from "../assets/results/score-icon.svg";
import timeIcon from "../assets/results/time-icon.svg";
import NextButton from "../components/NextButton";
import { useLang } from "../translations/i18n";
import { ROUTES } from "../utils/constants";
import { formatScore } from "../utils/helpers";
import { SERVER_URL } from "../api";

function Results({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const participant = useSelector((state) => state.group);
	const score = participant?.score || 0;
	const bonus = participant?.bonus || 0;
	const penalty = participant?.penalty || 0;
	const time = participant?.time || 0;

	const formatTime = (timeInSeconds) => {
		let minutes = Math.floor(timeInSeconds / 60);
		let seconds = timeInSeconds - minutes * 60;
		if (minutes) return `${minutes} min ${seconds} s`;
		return `${seconds} s`;
	};

	return (
		<div className="h-100 bg-results">
			<div className="container-fluid h-100">
				<div className="row text-center pt-4 justify-content-center">
					<div className="txt28 bold gradient-text">
						RESULTADOS PARTICIPACIÓN INDIVIDUAL
					</div>
				</div>
				<div className="row text-center mt-5 justify-content-center">
					<div className="col-8">
						<div className="d-flex align-items-start">
							<div className="final-score-img-bg mt-3 w-fit-content p-1">
								<div
									className="bg-white w-fit-content p-2"
									style={{ borderRadius: "50%" }}
								>
									<Image
										src={
											participant?.image
												? `${SERVER_URL}/${participant?.image}`
												: participant?.individual
												? individualPlaceholder
												: groupPlaceholder
										}
										roundedCircle
										width={170}
										height={170}
										style={{ objectFit: "cover" }}
									></Image>
								</div>
							</div>
							<div className="px-5 w-75">
								<div className="d-flex justify-content-between">
									<img
										src={timeIcon}
										alt=""
										className="score-icon"
									/>
									<div className="d-flex w-100 justify-content-between align-items-end">
										<div className="txt18 text-black ms-3">
											Tiempo de juego
										</div>
										<div className="txt18 text-black">
											{formatTime(time)}
										</div>
									</div>
								</div>
								<div className="separator mt-2"></div>
								<div className="d-flex justify-content-between mt-3">
									<img
										src={scoreIcon}
										alt=""
										className="score-icon"
									/>
									<div className="d-flex w-100 justify-content-between align-items-end">
										<div className="txt18 text-black ms-3">
											Puntos obtenidos
										</div>
										<div className="txt18 text-black">
											{score + Math.abs(penalty) - bonus}
										</div>
									</div>
								</div>
								<div className="separator mt-2"></div>
								<div className="d-flex justify-content-between mt-3">
									<img
										src={bonusIcon}
										alt=""
										className="score-icon"
									/>
									<div className="d-flex w-100 justify-content-between align-items-end">
										<div className="txt18 text-black ms-3">
											Bonificaciones
										</div>
										<div className="txt18 text-black">
											{bonus}
										</div>
									</div>
								</div>
								<div className="separator mt-2"></div>
								<div className="d-flex justify-content-between mt-3">
									<img
										src={penaltyIcon}
										alt=""
										className="score-icon"
									/>
									<div className="d-flex w-100 justify-content-between align-items-end">
										<div className="txt18 text-black ms-3">
											Penalizaciones
										</div>
										<div className="txt18 text-black">
											{penalty}
										</div>
									</div>
								</div>
								<div className="separator mt-2"></div>
								<div className="d-flex justify-content-end mt-5">
									<div className="txt22 bold">
										Puntuación total{" "}
										<span className="ms-5">
											{formatScore(score)}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="w-100 d-flex legalGebro position-absolute justify-content-center align-items-start"
				style={{ bottom: "5%" }}
			>
				<div className="d-flex flex-column align-items-center">
					<div className="mb-4 text-black txt12">Organizado por:</div>
					<img src={logoGSK} alt="GSK" />
				</div>
				<div className="d-flex flex-column align-items-center ms-5">
					<div className="mb-2 text-black txt12">
						Desarrollado por:
					</div>
					<img
						src={logoVivactis}
						alt="Vivactis Euromedice"
						width={110}
					/>
				</div>
			</div>
			<div
				className="container"
				style={{ position: "absolute", bottom: "8vh", right: "20%" }}
			>
				<div className="d-flex justify-content-end">
					<NextButton
						navigateTo={
							participant && !participant?.individual
								? ROUTES.RANKING
								: ROUTES.FINES_JUEGO
						}
						text={
							participant && !participant?.individual
								? "VER RANKING"
								: "AVANZAR"
						}
					></NextButton>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Results);
