import React from "react";
import { injectIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import espiral from "../assets/espiral.svg";
import logoGSK from "../assets/logo-gsk.svg";
import logo from "../assets/logov2.svg";
import relojCorto from "../assets/reloj-juego-corto.svg";
import relojLargo from "../assets/reloj-largo.svg";
import {
	LONG_GAME_DURATION,
	POLITICA_PRIVACIDAD_URL,
	ROUTES,
	SHORT_GAME_DURATION,
	TERMINOS_USO_URL,
} from "../utils/constants";

function ShortOrLongMode({ intl }) {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<div className="pt-4 h-100">
			<div className="container-fluid bgBlue h-100">
				<div className="container d-flex justify-content-between legalGebro pb-3">
					<div>
						<img
							src={logo}
							alt="MTxperience"
							className="img-fluid mw170"
						/>
					</div>
					<div>
						<img
							src={logoGSK}
							alt="20 years Gebro Pharma"
							className="img-fluid"
						/>
					</div>
				</div>
				<div className="d-flex justify-content-center align-items-center h-75">
					<div className="text-center selection">
						<img className="my-4" src={espiral} alt="GSK" />
						<h1 className="text-white txt28">
							<strong>¿Cómo quieres participar?</strong>
						</h1>
						<h2 className="text-white mb-4 txt22">
							Selecciona en qué modalidad quieres jugar
						</h2>
						<div className="my-3">
							<button
								className="txt18"
								onClick={() =>
									navigate(ROUTES.REGISTER_INDIVIDUAL, {
										state: {
											duration: SHORT_GAME_DURATION,
										},
									})
								}
							>
								<img src={relojCorto} alt="modo individual" />{" "}
								JUEGO {SHORT_GAME_DURATION} MIN
							</button>
						</div>
						<div className="my-3">
							<button
								className="txt18"
								onClick={() =>
									navigate(ROUTES.REGISTER_INDIVIDUAL, {
										state: {
											duration: LONG_GAME_DURATION,
										},
									})
								}
							>
								<img
									src={relojLargo}
									alt="modo grupal"
									className=""
								/>{" "}
								JUEGO {LONG_GAME_DURATION} MIN
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="legalGebro position-absolute w-100 mb-4">
				<div className="d-flex justify-content-center txtWhite">
					<div
						className="btn-home mx-2"
						style={{ cursor: "pointer" }}
						onClick={(event) => {
							event.stopPropagation();
							window.open(TERMINOS_USO_URL, "_blank");
						}}
					>
						{"Aviso Legal"}
					</div>
					|
					<div
						className="btn-home mx-2"
						style={{ cursor: "pointer" }}
						onClick={(event) => {
							event.stopPropagation();
							window.open(POLITICA_PRIVACIDAD_URL, "_blank");
						}}
					>
						{"Política de Privacidad"}
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(ShortOrLongMode);
