import { API, baseClient } from "../index";

export const postAnswer = async (answer) => {
	return baseClient().post(`${API}/answer`, answer);
};

export const getAnswerByQuizAndParticipant = async (quizId, participantId) => {
	return baseClient().get(
		`${API}/answer/quiz/${quizId}/participant/${participantId}`
	);
};
