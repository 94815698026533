import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import flechas from "../assets/flechas.svg";
import { setGroup, updateTime } from "../redux/actions";
import { ROUTES } from "../utils/constants";
import { getScenarioByScreen, getTimer, updateStats } from "../utils/helpers";

function NextButton({
	navigateTo,
	navigateToAfter,
	activeScenario,
	text,
	classes,
}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state?.group);
	const gameId = useSelector((state) => state.game)?._id;

	const scenario =
		activeScenario ||
		location?.state?.activeScenario ||
		getScenarioByScreen(location.pathname);

	async function handleNext(e) {
		e.preventDefault();
		let totalTime = getTimer() || 0;
		dispatch(updateTime(totalTime));

		if (navigateTo === ROUTES.END) {
			dispatch(setGroup({ ...participant, finished: true }));

			updateStats({
				...participant,
				score: participant?.score,
				actualScenario: scenario || participant?.actualScenario,
				time: totalTime,
				lastVisited: navigateTo,
				finished: true,
			});
		} else {
			updateStats({
				...participant,
				score: participant?.score,
				actualScenario: scenario || participant?.actualScenario,
				time: totalTime,
				lastVisited: navigateTo,
			});
		}

		navigate(navigateTo, {
			state: {
				navigateTo: navigateToAfter,
				activeScenario: scenario,
			},
		});
	}

	return (
		<button
			onClick={(e) => handleNext(e)}
			className={`btn-iniciar p-2 px-4 ${classes} `}
		>
			{text || "AVANZAR"}
			<img className="ms-2" src={flechas}></img>
		</button>
	);
}
export default NextButton;
