import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import iconoFActivo from "../assets/icono-false-activo.png";
import iconoFInactivo from "../assets/icono-false-inactivo.png";
import iconoVActivo from "../assets/icono-true-activo.png";
import iconoVInactivo from "../assets/icono-true-inactivo.png";

function TrueOrFalseQuestion({
	question,
	questionNumber,
	answerChecked,
	onOptionClicked,
	clickedOption,
	totalQuestions,
}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<>
			<div
				className="d-flex align-items-center"
				style={{ position: "relative" }}
			>
				{questionNumber ? (
					<>
						<span className="txt32 bold text-red">
							{questionNumber}
						</span>
						<span className="txt20">/{totalQuestions || 4}</span>
						<span
							className="txt16 ms-4 ps-2"
							dangerouslySetInnerHTML={{ __html: question }}
						></span>
						<div className="vertical-separator"></div>
					</>
				) : (
					<>
						<span
							className="txt16"
							dangerouslySetInnerHTML={{ __html: question }}
						></span>
					</>
				)}
			</div>
			<br />
			<div className="d-flex justify-content-center">
				<img
					className="cursor-pointer mx-3"
					src={clickedOption === true ? iconoVActivo : iconoVInactivo}
					onClick={() => {
						if (!answerChecked) {
							onOptionClicked(true);
						}
					}}
				></img>
				<img
					className="cursor-pointer mx-3"
					src={
						clickedOption === false ? iconoFActivo : iconoFInactivo
					}
					onClick={() => {
						if (!answerChecked) {
							onOptionClicked(false);
						}
					}}
				></img>
			</div>
		</>
	);
}
export default TrueOrFalseQuestion;
