import {
	DndContext,
	MouseSensor,
	TouchSensor,
	useDraggable,
	useDroppable,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndParticipant, postAnswer } from "../../api/answer";
import iconArrastrar from "../../assets/arrastrar.png";
import llave1 from "../../assets/flecha-esquema-1.png";
import flechaEsquema from "../../assets/flecha-esquema-2.png";
import flechas from "../../assets/flechas.svg";
import llave2 from "../../assets/llave-esquema-1.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertQuizAlreadyAnswered,
	modalBiblioReferences,
	modalCorrectIncorrectAnswer,
	modalIncorrectAnswerP1,
	modalQuestionInstructions,
} from "../../utils/logger";

function Pregunta1Part2({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const touchSensor = useSensor(TouchSensor);
	const mouseSensor = useSensor(MouseSensor);
	const sensors = useSensors(touchSensor, mouseSensor);

	const CORRECT_POINTS = 200;
	const EXTRA_POINTS = 100;

	const QUIZ_ID = QUIZZES.SCENARIO1_ALGORITMO_2;
	const SCENARIO_ID = SCENARIOS.SCENARIO_1;
	const NAVIGATE_TO = ROUTES.P1_EXPLICATION;

	const IL4 = 1;
	const IL5 = 2;
	const IL5_2 = 3;
	const IL5_3 = 4;
	const IL3 = 5;

	const tagOptions = [
		{ id: IL4, value: "IL4" },
		{
			id: IL5,
			value: "IL5",
		},
		{ id: IL5_2, value: "IL5" },
		{ id: IL5_3, value: "IL5" },
		{ id: IL3, value: "IL3" },
	];

	const [tags, setTags] = useState(tagOptions);
	const OFFSET = tagOptions.length; //necessary for a proper behaviour of drag & drop library

	const [boxes, setBoxes] = useState([
		{
			id: IL4 + OFFSET,
			tag: null,
			correctOptionsIds: [IL4, IL5, IL5_2, IL5_3],
		},
		{
			id: IL5 + OFFSET,
			tag: null,
			correctOptionsIds: [IL4, IL5, IL5_2, IL5_3],
		},
		{
			id: IL5_2 + OFFSET,
			tag: null,
			correctOptionsIds: [IL5, IL5_2, IL5_3],
		},
		{
			id: IL5_3 + OFFSET,
			tag: null,
			correctOptionsIds: [IL3, IL5, IL5_2, IL5_3],
		},
		{
			id: IL3 + OFFSET,
			tag: null,
			correctOptionsIds: [IL3, IL5, IL5_2, IL5_3],
		},
	]);

	const [intentosRestantes, setIntentosRestantes] = useState(4);
	const [quizCompleted, setQuizCompleted] = useState(false);

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Barretto KT, Brockman-Schneider RA, Kuipers I, et al.
					<span class="regular">
						Human airway epithelial cells express a functional IL5
						receptor. Allergy. 2020;75(8):2127-30.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Akdis CA.
					<span class="regular">
						Does the epithelial barrier hypothesis explain the
						increase in allergy, autoimmunity and other chronic
						conditions? Nat Rev Immunol. 2021;21(11):739-51.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Lambrecht BN, Hammad H.
					<span class="regular">
						The immunology of asthma. Nat Immunol. 2015;16(1):45-56.
					</span>
				</span>
			</li>
		</ol>`;

	const INSTRUCCIONS = `<div>
			<p class="txt16">
				Este elemento fundamental son las
				<span class="bold">INTERLEUCINAS</span>. Aquí tienes una
				representación gráfica del proceso. Coloca cada etiqueta en su
				lugar.
			</p>
			<p class="txt16">
				Coloca las etiquetas en el lugar adecuado.
			</p>
			<p class="txt16 bold text-red">
				4 INTENTOS.
			</p>			
		</div>`;

	useEffect(() => {
		getAnswerByQuizAndParticipant(QUIZ_ID, participant?._id)
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					alertQuizAlreadyAnswered({}).then((res) => {});
					setQuizCompleted(true);
				}
			})
			.catch((error) => {
				modalQuestionInstructions({
					instruccionsTextHtml: INSTRUCCIONS,
					questionNum: getScenarioByScreen(location.pathname),
				});
			});
	}, []);

	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		quizCompleted ? null : 1000
	);

	async function submitAnswer({ correct, score, bonus }) {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					let bonusScore = bonus || 0;
					dispatch(updateScore(score, SCORE_TYPES.SCORE));
					dispatch(updateScore(bonusScore, SCORE_TYPES.BONUS));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						bonus: participant?.bonus + Math.abs(bonusScore),
						lastVisited: NAVIGATE_TO,
					});
					setQuizCompleted(true);
				}
			})
			.catch((error) => {});
	}

	const TagBoxTarget = ({ boxId, tagId, defaultValue }) => {
		const box = boxes.find((x) => x.id === boxId + OFFSET);
		const tag = tags.find((x) => x.id === boxId);

		return box?.tag === null ? (
			<Droppable id={box?.id}>{tag?.value}</Droppable>
		) : (
			<Draggable id={box?.tag?.id}>{box?.tag?.value}</Draggable>
		);
	};

	const Draggable = (props) => {
		const { attributes, listeners, setNodeRef, transform } = useDraggable({
			id: props.id,
			disabled: false,
		});

		const style = {
			transform: CSS.Translate.toString(transform),
			lineHeight: "18px",
			zIndex: "10",
		};

		const box = boxes.find((x) => x.id === props?.id + OFFSET);

		return (
			<div
				className="col-lg-6 tag-box-p1-p2 txt16"
				ref={setNodeRef}
				style={style}
				{...listeners}
				{...attributes}
			>
				{props.children}
			</div>
		);
	};

	const Droppable = (props) => {
		const { isOver, setNodeRef } = useDroppable({
			id: props.id,
			data: {
				origin: props.origin,
			},
		});

		const emptyTagOptionItem = props.origin;

		return (
			<>
				<div
					className={`tag-box-target-p1-p2 ${
						emptyTagOptionItem ? "bg-transparent" : ""
					} txt16`}
					style={{
						lineHeight: "18px",
					}}
					ref={setNodeRef}
				>
					{props.children}
				</div>
			</>
		);
	};

	const handleDragEnd = ({ active, over }) => {
		window.scrollTo(0, 0);
		if (!active || !over) return;

		const draggedTag = tagOptions.find((x) => x.id === active?.id);
		const targetBox = boxes.find((x) => x.id === over?.id);

		const boxId = boxes.find((x) => x.tag?.id === draggedTag?.id)?.id;

		const updatedBoxes = boxes.map((b) =>
			b.id === targetBox?.id
				? {
						...b,
						tag: draggedTag,
				  }
				: b.id === boxId
				? { ...b, tag: null }
				: b
		);

		setBoxes(updatedBoxes);

		let updatedTags;

		if (over?.data?.current?.origin) {
			updatedTags = tagOptions.filter(
				(x) => tags.some((y) => y.id === x.id) || x.id === draggedTag.id
			);
		} else {
			updatedTags = tags.filter((x) => x.id !== draggedTag.id);
		}

		setTags(updatedTags);
	};

	const TagBoxSolved = ({ defaultValue }) => {
		return <div className={"tag-box-solved-p1-p2 p-1"}>{defaultValue}</div>;
	};

	function checkAnswers() {
		let normalizedBoxes = boxes.map((x) => ({ ...x, id: x.id - OFFSET }));
		let wrongOptions = normalizedBoxes.filter(
			(x) => !x.correctOptionsIds.includes(x.tag?.id)
		);

		let correct = wrongOptions.length === 0;

		const finalScore =
			correct && intentosRestantes == 4
				? CORRECT_POINTS + EXTRA_POINTS
				: correct
				? CORRECT_POINTS
				: 0;

		const bonusScore = intentosRestantes === 4 ? EXTRA_POINTS : 0;

		if (correct) {
			let text = `<p class="txt16">
						Has ubicado correctamente todas las interleucinas.
					</p>
					<p class="txt16">
						Vamos a ver la figura completa de una forma más visual.
					</p>`;

			modalCorrectIncorrectAnswer({
				title: intentosRestantes == 4 ? "¡Fantástico!" : "¡Ahora sí!",
				textHtml: text,
				points: finalScore,
				correct: true,
			}).then((res) => {
				if (res.isConfirmed) {
					navigate(NAVIGATE_TO);
				}
			});
		} else {
			if (intentosRestantes > 1) {
				let text = `<div>
						<p class="txt16">
							Hay <span class="text-red bold">${wrongOptions.length}</span>
							<span class="bold">etiquetas</span> colocadas
							erróneamente. Fíjate bien y prueba de nuevo.
						</p>
						<p class="txt16">
							Te quedan <span class="text-red bold">${intentosRestantes - 1}</span>
							<span class="bold">intentos</span>.
						</p>
					</div>`;

				modalCorrectIncorrectAnswer({
					title: "No es correcto",
					textHtml: text,
					allowBack: true,
					correct: false,
				});
			} else {
				let text = `<p class="txt16">
						Aquí tienes el esquema correcto con las interleucinas:
						<p class="txt16">
							Vamos a ver la figura completa de una forma más
							visual.
						</p>
					</p>`;

				modalIncorrectAnswerP1({
					title: "No es correcto",
					textHtml: text,
				}).then((res) => {
					if (res.isConfirmed) {
						navigate(NAVIGATE_TO);
					}
				});
			}

			const correctBoxes = boxes.map((b) =>
				b?.correctOptionsIds.includes(b?.tag?.id)
					? {
							...b,
					  }
					: { ...b, tag: null }
			);

			setBoxes(correctBoxes);

			const wrongTags = tagOptions.filter(
				(tagOption) =>
					!correctBoxes.some((x) => x?.tag?.id === tagOption.id)
			);

			setTags(wrongTags);
		}

		if (correct || intentosRestantes === 1) {
			submitAnswer({
				correct: correct,
				score: finalScore,
				bonus: bonusScore,
			});
		}

		setIntentosRestantes(intentosRestantes - 1);
	}

	return (
		<div className="h-100 bgprueba">
			<div className="container">
				<div className="row h-100">
					<DndContext
						onDragEnd={handleDragEnd}
						sensors={sensors}
						modifiers={[restrictToWindowEdges]}
					>
						<div className="col-4">
							<div className="d-flex flex-column align-items-start">
								{tagOptions?.map((tag, index) => {
									const isDropped = !tags.some(
										(x) => x.id == tag.id
									);
									return (
										<>
											<div
												className={`${
													index !== 0 ? "mt-2" : ""
												}`}
											>
												{!isDropped ? (
													<Draggable id={tag.id}>
														{tag.value}
													</Draggable>
												) : (
													<Droppable
														id={tag.id}
														origin={true}
													>
														{
															tagOptions.find(
																(x) =>
																	x.id ===
																	tag.id
															)?.value
														}
													</Droppable>
												)}
											</div>
										</>
									);
								})}
							</div>
						</div>
						<div className="col-5">
							<div>
								<div className="d-flex justify-content-center">
									<div className="bg-title">
										Inflamación T2
									</div>
								</div>
								<div className="d-flex justify-content-center">
									<img
										className="mb-1"
										src={llave1}
										width={"70%"}
									></img>
								</div>
								<div className="d-flex align-items-center justify-content-between">
									<div className="text-center">
										<TagBoxSolved defaultValue="Alérgica" />
										<img
											className="my-1"
											src={flechaEsquema}
											width={10}
										/>
									</div>
									<div className="text-center">
										<TagBoxSolved defaultValue="No alérgica" />
										<img
											className="my-1"
											src={flechaEsquema}
											width={10}
										/>
									</div>
								</div>
								<div className="d-flex align-items-start justify-content-between">
									<div className="text-center">
										<TagBoxSolved defaultValue="Inmunidad innata" />
										<img
											className="my-1"
											src={flechaEsquema}
											width={10}
										/>{" "}
									</div>
									<TagBoxSolved defaultValue="Inmunidad innata" />
								</div>
								<div
									className="d-flex align-items-center"
									style={{ marginLeft: "-85px" }}
								>
									<TagBoxTarget boxId={IL4} />
									<TagBoxSolved defaultValue="Inmunidad adaptativa" />
									<TagBoxTarget boxId={IL5} />
								</div>
								<div className="d-flex justify-content-center">
									<img
										src={llave2}
										width={"70%"}
										className="mb-1"
										style={{ marginTop: "-22%" }}
									></img>
								</div>
								<div
									className="d-flex align-items-center justify-content-center"
									style={{ marginLeft: "120px" }}
								>
									<TagBoxSolved defaultValue="Eosinofilia" />
									<TagBoxTarget boxId={IL5_2} />
								</div>
								<div className="d-flex justify-content-center ms-4 ps-1">
									<img
										className="my-1"
										src={flechaEsquema}
										width={10}
									/>{" "}
								</div>
								<div className="d-flex justify-content-center align-items-center ms-4 ps-1">
									<TagBoxTarget boxId={IL5_3} />
									<TagBoxSolved defaultValue="Remodelación" />
									<TagBoxTarget boxId={IL3} />
								</div>
							</div>
						</div>
						<div className="col-3">
							<div className="d-flex flex-column align-items-end">
								<LifeIndicatorBar
									numIntentos={intentosRestantes}
								></LifeIndicatorBar>
							</div>
						</div>
					</DndContext>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{ position: "absolute", bottom: "10vh" }}
				>
					<div className="d-flex justify-content-between align-items-end">
						<div className="txt12">
							<div>
								<div className="d-flex align-items-center">
									<div className="bg-points-info me-1">
										+{CORRECT_POINTS}
									</div>
									puntos
								</div>
								<div className="d-flex align-items-center mt-2">
									<div className="bg-extra-points-info me-1">
										+{EXTRA_POINTS}
									</div>
									<div className="w-50">
										puntos extras por resolver en el primer
										intento
									</div>
								</div>
							</div>
							<div className="d-flex align-items-center mt-2">
								<img
									src={iconArrastrar}
									alt="arrastra"
									className="me-1"
								></img>
								<div className="w-50">
									Arrastra cada etiqueta a su lugar
									correspondiente.
								</div>
							</div>
							<div
								className="txt12 underline text-red cursor-pointer mt-4"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
						</div>
						<div style={{ zIndex: "99" }}>
							{!quizCompleted ? (
								<button
									className="txt14 btn-iniciar p-2 px-4"
									onClick={() => checkAnswers()}
								>
									COMPROBAR{" "}
									<img className="ms-2" src={flechas}></img>
								</button>
							) : (
								<NextButton
									navigateTo={NAVIGATE_TO}
								></NextButton>
							)}
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta1Part2);
