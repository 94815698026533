import React from "react";
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import openDoor from "../assets/open-door.mov";
import NextButton from "../components/NextButton";
import { useLang } from "../translations/i18n";
import { ROUTES } from "../utils/constants";

function Start({ intl }) {
	const lang = useLang();
	const game = useSelector((state) => state.game);

	return (
		<div className="bgdoors">
			<audio id="audioPlayer" src={openDoor} autoPlay></audio>
			<div className="container h-100">
				<div className="row h-100">
					<div
						style={{
							position: "absolute",
							bottom: "5vh",
							right: "-20%",
						}}
					>
						<div className="d-flex justify-content-center">
							<NextButton
								navigateTo={ROUTES.INSTRUCTIONS}
								text={"INICIAR EL JUEGO"}
							></NextButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Start);
