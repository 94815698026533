import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postAnswer } from "../../api/answer";
import iconoClickExtra from "../../assets/bg-extra-click-info-2.png";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import { modalBiblioReferences } from "../../utils/logger";

function Pregunta5Superada({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const SCENARIO_ID = SCENARIOS.SCENARIO_5;
	const QUIZ_ID = QUIZZES.SCENARIO5_CURIOSIDAD;
	const NAVIGATE_TO = ROUTES.P5_AMPLIAR;

	const EXTRA_POINTS = 25;

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Barretto KT, Brockman-Schneider RA, Kuipers I, et al.
					<span class="regular">
						Human airway epithelial cells express a functional IL-5
						receptor. Allergy. 2020;75(8):2127-30.
					</span>
				</span>
			</li>
		</ol>`;

	async function submitAnswer() {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: EXTRA_POINTS,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					dispatch(updateScore(EXTRA_POINTS, SCORE_TYPES.SCORE));
					dispatch(updateScore(EXTRA_POINTS, SCORE_TYPES.BONUS));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + EXTRA_POINTS,
						bonus: participant?.bonus + EXTRA_POINTS,
						lastVisited: NAVIGATE_TO,
					});
				}
			})
			.catch((error) => {});
	}

	return (
		<>
			<div className="pt-4 h-100 bg-fin-escenario escenario-5">
				<div className="container bgBlue h-75">
					<div className="col-12 text-red txt50 bold">
						¡Quinta parte superada!
					</div>
					<div className="row mt-1">
						<div className="col-7 txt16 mt-2">
							<p>
								La{" "}
								<span className="bold">
									hiperreactividad bronquial indirecta de
									María
								</span>{" "}
								se puede explicar en parte por la{" "}
								<span className="bold">
									inflamación bronquial T2
								</span>{" "}
								favorecida por la{" "}
								<span className="bold">
									liberación de IL5 e IL13
								</span>{" "}
								por parte de los mastocitos infiltrados en el
								epitelio bronquial.
							</p>
							<p>
								Uno de los mecanismos subyacentes al
								empeoramiento clínico de María durante este
								último año podría ser la{" "}
								<span className="bold">
									pérdida de integridad del epitelio bronquial
									(y nasosinusal).
								</span>
							</p>
							<div>
								<img
									className="grow-animation cursor-pointer pt-5"
									src={iconoClickExtra}
									width={125}
									onClick={async () => {
										await submitAnswer();
										navigate(NAVIGATE_TO);
									}}
								></img>
							</div>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{ position: "absolute", bottom: "10%" }}
					>
						<div className="d-flex align-items-end justify-content-between">
							<div
								className="txt12 underline text-red cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
							<div>
								<NextButton
									navigateTo={ROUTES.TRANSITION_SCENARIOS}
									navigateToAfter={ROUTES.P6_INTRO}
								></NextButton>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta5Superada);
