import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import iconoDudaRojo from "../../assets/icono-duda-rojo.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalBiblioReferences } from "../../utils/logger";

function Pregunta1ExtraVFExtra({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();
	const location = useLocation();

	const [intentosRestantes, setIntentosRestantes] = useState(1);

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Pelaia C, Paoletti G, Puggioni F, et al.
					<span class="regular">
						Interleukin-5 in the Pathophysiology of Severe Asthma.
						Front Physiol. 2019;10:1514.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Barretto KT, Brockman-Schneider RA, Kuipers I, et al.
					<span class="regular">
						Human airway epithelial cells express a functional IL-5
						receptor. Allergy. 2020;75(8):2127-30.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Altman MC, Lai Y, Nolin JD, et al.
					<span class="regular">
						Airway epithelium-shifted mast cell infiltration
						regulates asthmatic inflammation via IL-33 signaling. J
						Clin Invest. 2019;129(11):4979-91.
					</span>
				</span>
			</li>
		</ol>`;

	return (
		<div className="h-100 bg-ampliar-info">
			<div className="container">
				<div className="row h-100">
					<div className="col-10">
						<div className="d-flex align-items-center">
							<img src={iconoDudaRojo} width={56}></img>
							<div className="ms-3">
								<div className="text-red bold">Has ganado</div>
								<div className="bg-points-info">+25 puntos</div>
							</div>
						</div>
						<div className="mt-5 pt-5 ms-5 ps-5">
							<p>
								Hasta ahora la función más conocida de la IL5
								era la de ser{" "}
								<span className="bold">
									responsable del crecimiento, diferenciación,
									reclutamiento, activación y supervivencia de
									los eosinófilos.
								</span>{" "}
								Recientemente, se han publicado nuevas funciones
								que posicionan la{" "}
								<span className="bold">IL5</span> como una{" "}
								<span className="bold">
									citocina con efectos que van más allá.
								</span>{" "}
								En concreto se ha visto que ejerce funciones:
							</p>
							<div>
								<div className="row">
									<div className="col-1 w-fit-content">•</div>
									<div className="col-11">
										En el eosinófilo.
									</div>
								</div>
								<div className="row">
									<div className="col-1 w-fit-content">•</div>
									<div className="col-11">
										En el epitelio.
									</div>
								</div>
								<div className="row">
									<div className="col-1 w-fit-content">•</div>
									<div className="col-11">
										En los mastocitos.
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-2">
						<div className="d-flex flex-column align-items-end">
							<LifeIndicatorBar
								numIntentos={intentosRestantes}
							></LifeIndicatorBar>
						</div>
					</div>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{ position: "absolute", bottom: "10%" }}
				>
					<div className="d-flex justify-content-between align-items-center mt-5 pt-4">
						<div>
							<div
								className="txt12 underline text-red cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
						</div>
						<div>
							<NextButton
								navigateTo={ROUTES.P1_SUPERADA}
							></NextButton>
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta1ExtraVFExtra);
