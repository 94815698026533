import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postAnswer } from "../../api/answer";
import iconAmpliar from "../../assets/ampliar-foto.png";
import bgExtraInfoClick from "../../assets/bg-extra-click-info-2.png";
import detalleEosinofilo from "../../assets/detalle-eosinofilo.png";
import dibujoEosinofilo from "../../assets/dibujo-eosinofilo-frases.png";
import graficoRealitia from "../../assets/grafico-realitia.png";
import iconAmpliarInfo from "../../assets/icono-ampliar-info.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	modalBiblioReferences,
	modalImage,
	modalImage2,
} from "../../utils/logger";

function Pregunta3Solucion({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const [intentosRestantes, setIntentosRestantes] = useState(null);

	const EXTRA_POINTS = 25;

	const SCENARIO_ID = SCENARIOS.SCENARIO_3;
	const QUIZ_ID = QUIZZES.SCENARIO3_CURIOSIDAD;
	const NAVIGATE_TO = ROUTES.P3_SUPERADA;

	async function submitAnswer() {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: EXTRA_POINTS,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					dispatch(updateScore(EXTRA_POINTS, SCORE_TYPES.SCORE));
					dispatch(updateScore(EXTRA_POINTS, SCORE_TYPES.BONUS));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + EXTRA_POINTS,
						bonus: participant?.bonus + EXTRA_POINTS,
						lastVisited: NAVIGATE_TO,
					});
				}
			})
			.catch((error) => {});
	}

	const sentences = [
		`La IL5 provoca la activación de los eosinófilos<sup>1</sup>. Esta activación induce la liberación de diferentes mediadores, entre ellos la Galectina-10. La galectina 10 es una de las proteínas citoplasmáticas más abundantes del eosinófilo y es la responsable de la formación de los CLC<sup>2</sup>.`,
		`Estos cristales están implicados en procesos de reparación y defensa frente a parásitos, pero también se asocian a enfermedades como el asma<sup>3</sup>. Estos cristales están presentes en las mucosas de las vías respiratorias bronquiales y nasosinusales<sup>2</sup>.`,
		`En el asma, los CLC activan los inflamasomas de las células dendríticas y de los macrófagos de la mucosa respiratoria<sup>2,4</sup>.`,
		`Las células dendríticas captan el antígeno y lo presentan a los linfocitos T naïve, que se activan para dar lugar a células Th2 y células B productoras de IgE<sup>5</sup>.`,
		`Por lo tanto, la IL5 también participa en la fase de sensibilización de la inflamación T2 alérgica<sup>5</sup>.`,
		`Esto explicaría porqué mepolizumab, en el estudio español en vida real REDES, redujo la tasa de exacerbaciones en un 80 % en pacientes con asma alérgica (n = 193) y un 76% en pacientes con asma no alérgica (n = 125)<sup>6</sup>.`,
	];

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Pelaia C, Paoletti G, Puggioni F, et al.
					<span class="regular">
						Interleukin-5 in the Pathophysiology of Severe Asthma.
						Front Physiol. 2019;10:1514.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Persson EK, Verstraete K, Heyndrickx I, et al.
					<span class="regular">
						Protein crystallization promotes type 2 immunity and is
						reversible by antibody treatment. Science.
						2019;364(6442):eaaw4295.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Aegerter H, Smole U, Heyndrickx I, et al.
					<span class="regular">
						Charcot-Leyden crystals and other protein crystals
						driving type 2 immunity and allergy. Curr Opin Immunol.
						2021;72:72-8.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Wang Z, Zhang S, Xiao Y, et al.
					<span class="regular">
						NLRP3 Inflammasome and Inflammatory Diseases. Oxid Med
						Cell Longev. 2020;2020:4063562.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Lambrecht BN, Hammad H.
					<span class="regular">
						The immunology of asthma. Nat Immunol. 2015;16(1):45-56.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					González-Pérez R, Poza-Guedes P, Mederos-Luis E, et al.
					<span class="regular">
						Real-Life Performance of Mepolizumab in T2-High Severe
						Refractory Asthma with the Overlapping
						Eosinophilic-Allergic Phenotype. Biomedicines.
						2022;10(10):2635.
					</span>
				</span>
			</li>
		</ol>`;

	return (
		<>
			<div className="h-100 bgprueba">
				<div className="container">
					<div className="row h-100">
						<div className="col-2">
							<div className="d-flex align-items-center">
								<Image
									src={detalleEosinofilo}
									width={76}
								></Image>
								<Image
									style={{
										marginLeft: "-10%",
										cursor: "pointer",
									}}
									onClick={() => {
										modalImage({
											image: dibujoEosinofilo,
										});
									}}
									src={iconAmpliar}
									width={90}
								></Image>
							</div>
							<div className="mt-2">
								<Image
									className="grow-animation"
									style={{ cursor: "pointer" }}
									src={bgExtraInfoClick}
									onClick={async () => {
										await submitAnswer();
										modalImage2({
											title: "Efectividad demostrada por Nucala® en paciente con asma grave eosinofílica y alérgica",
											image: graficoRealitia,
											references: `<span class="bold">Fuente: Lee JK, Pollar S, Liu MC, et al.</span> Influence of Baseline Total IgE and History of Previous Omalizumab Use on the Impact of Mepolizumab in Reducing Rate of Severe Asthma Exacerbations: Results From the Real-World REALITI-A Study. Poster No. 574 presented at the American Academy of Allergy Asthma & Immunology annual meeting; 2022 February 25-28.`,
										});
									}}
									width={125}
								></Image>
							</div>
						</div>
						<div className="col-9">
							<div className="pt-4 pe-3">
								{sentences.map((sentence, index) => {
									return (
										<>
											<div className="d-flex align-items-start">
												<div className="text-red bold">
													{index + 1}.
												</div>
												<p
													className="txt16 ms-3"
													dangerouslySetInnerHTML={{
														__html: sentence,
													}}
												></p>
											</div>
										</>
									);
								})}
							</div>
							<div className="d-flex justify-content-center">
								<img
									src={iconAmpliarInfo}
									width={140}
									style={{
										cursor: "pointer",
										zIndex: "10",
									}}
									onClick={() => {
										modalImage2({
											title: "Efectividad demostrada por Nucala® en paciente con asma grave eosinofílica y alérgica",
											image: graficoRealitia,
											references: `<span class="bold">Fuente: Lee JK, Pollar S, Liu MC, et al.</span> Influence of Baseline Total IgE and History of Previous Omalizumab Use on the Impact of Mepolizumab in Reducing Rate of Severe Asthma Exacerbations: Results From the Real-World REALITI-A Study. Poster No. 574 presented at the American Academy of Allergy Asthma & Immunology annual meeting; 2022 February 25-28.`,
										});
									}}
								></img>
							</div>
						</div>
						<div className="col-1">
							<div className="d-flex flex-column align-items-end">
								<LifeIndicatorBar
									numIntentos={intentosRestantes}
								></LifeIndicatorBar>
							</div>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{ position: "absolute", bottom: "10%" }}
					>
						<div className="d-flex justify-content-between align-items-end">
							<div
								className="txt12 underline text-red cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
							<NextButton navigateTo={NAVIGATE_TO}></NextButton>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta3Solucion);
