import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getParticipantById } from "../api/participant";
import espiral from "../assets/espiral.svg";
import flechas from "../assets/flechas.svg";
import logoGSK from "../assets/logo-gsk.svg";
import logo from "../assets/logo.svg";
import {
	LONG_GAME_DURATION,
	POLITICA_PRIVACIDAD_URL,
	ROUTES,
	SHORT_GAME_DURATION,
	TERMINOS_USO_URL,
} from "../utils/constants";
import {
	clearParticipant,
	clearTimer,
	getParticipantId,
	getViewport,
	reconnect,
} from "../utils/helpers";
import { alertResume } from "../utils/logger";

function Home({ intl }) {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const SHORT_MODE = location.pathname === ROUTES.HOME_SHORT;
	const LONG_MODE = location.pathname === ROUTES.HOME_LONG;

	useEffect(() => {
		let participantId = getParticipantId();
		if (participantId) {
			alertResume().then((res) => {
				if (res.isConfirmed) {
					getParticipantById(participantId).then((res) => {
						if (res.status === 200)
							reconnect({
								participant: res.data,
								dispatch: dispatch,
								navigate: navigate,
								navigateTo: res.data?.finished
									? ROUTES.RESULTS
									: null,
							});
					});
				} else {
					clearParticipant();
					clearTimer();
				}
			});
		} else {
			clearTimer();
		}
	}, []);

	return (
		<>
			<div className="startbg text-white">
				<div className="container start-logos">
					<div className="d-flex justify-content-end mt-5" style={{}}>
						<img
							src={logoGSK}
							alt="20 years Gebro Pharma"
							className="img-fluid"
						/>
					</div>
				</div>

				<div className="row h-100">
					<div className="col-4 offset-4 my-auto text-center logodiv txtWhite">
						<img
							src={logo}
							alt="gsk"
							className="img-fluid w-50 my-5"
						/>
						<div className="startText">
							Buscando una salida eficaz
							<br />
							para el asma grave <img src={espiral} alt="GSK" />
						</div>
						<button
							onClick={() => {
								navigate(ROUTES.INDIVIDUAL_GRUPAL, {
									state: {
										duration: LONG_MODE
											? LONG_GAME_DURATION
											: SHORT_MODE
											? SHORT_GAME_DURATION
											: null,
									},
								});
							}}
						>
							EMPEZAR <img src={flechas} alt="empezar" />
						</button>
					</div>
				</div>

				<div className="legalGebro position-absolute w-100 mb-4">
					<div className="d-flex justify-content-center txtWhite">
						<div
							className="btn-home mx-2"
							style={{ cursor: "pointer" }}
							onClick={(event) => {
								event.stopPropagation();
								window.open(TERMINOS_USO_URL, "_blank");
							}}
						>
							{"Aviso Legal"}
						</div>
						|
						<div
							className="btn-home mx-2"
							style={{ cursor: "pointer" }}
							onClick={(event) => {
								event.stopPropagation();
								window.open(POLITICA_PRIVACIDAD_URL, "_blank");
							}}
						>
							{"Política de Privacidad"}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default injectIntl(Home);
