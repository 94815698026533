import React from "react";
import { injectIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { generateGameSessionCode } from "../api/game";
import espiral from "../assets/espiral.svg";
import icogrupal from "../assets/ico-grupal.svg";
import icoindividual from "../assets/ico-individual.svg";
import logoGSK from "../assets/logo-gsk.svg";
import logo from "../assets/logov2.svg";
import {
	LONG_GAME_DURATION,
	POLITICA_PRIVACIDAD_URL,
	ROUTES,
	SHORT_GAME_DURATION,
	TERMINOS_USO_URL,
} from "../utils/constants";
import {
	modalChooseGameDuration,
	modalConfirmGroupGame,
	modalCrearPartida,
	modalIntroducirCodigoPartida,
	modalUnirsePartida,
} from "../utils/logger";

function IndividualOrGrupal({ intl }) {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const gameId = searchParams.get("game");

	const GAME_DURATION = location?.state?.duration;

	function handleGenerateCode(duration) {
		generateGameSessionCode().then((res) => {
			if (res.status === 200) {
				let codigo = res.data;
				modalCrearPartida({
					codigo: codigo,
				}).then((res) => {
					if (res.isConfirmed) {
						navigate(ROUTES.REGISTER_GROUP, {
							state: {
								duration: duration,
								hospital: res.value,
								codigoPartida: codigo,
							},
						});
					}
				});
			}
		});
	}

	const handleConfirmGroupGame = async () => {
		const response = await modalConfirmGroupGame({
			textHtml: `<div class="txt14">
				<p>La modalidad de juego grupal está destinada a eventos en los que participen varios grupos.</p>
				<p>¿Estás seguro de que quieres continuar?</p>
			</div>`,
		});

		if (response.isConfirmed) {
			await handleUnirsePartida();
		}
	};

	const handleUnirsePartida = async () => {
		const response = await modalUnirsePartida({});

		if (response.isConfirmed) {
			if (GAME_DURATION) handleGenerateCode(GAME_DURATION);
			else await handleChooseGameDuration();
		} else if (response.dismiss === Swal.DismissReason.cancel) {
			await handleIntroducirCodigoPartida();
		}
	};

	const handleChooseGameDuration = async () => {
		const response = await modalChooseGameDuration();
		let duration = null;

		if (response.isConfirmed) {
			duration = SHORT_GAME_DURATION;
		} else if (response.dismiss === Swal.DismissReason.cancel) {
			duration = LONG_GAME_DURATION;
		}

		if (duration) {
			handleGenerateCode(duration);
		}
	};

	const handleIntroducirCodigoPartida = async () => {
		const response = await modalIntroducirCodigoPartida();

		if (response.isConfirmed && response.value) {
			navigate(ROUTES.REGISTER_GROUP, {
				state: {
					codigoPartida: response.value.replace(/\s/g, ""),
				},
			});
		}
	};

	return (
		<div className="pt-4 h-100">
			<div className="container-fluid bgBlue h-100">
				<div className="container d-flex justify-content-between legalGebro pb-3">
					<div>
						<img
							src={logo}
							alt="MTxperience"
							className="img-fluid mw170"
						/>
					</div>
					<div>
						<img
							src={logoGSK}
							alt="20 years Gebro Pharma"
							className="img-fluid"
						/>
					</div>
				</div>
				<div className="d-flex justify-content-center align-items-center h-75">
					<div className="text-center selection">
						<img className="my-4" src={espiral} alt="GSK" />
						<h1 className="text-white txt28">
							<strong>¿Cómo quieres participar?</strong>
						</h1>
						<h2 className="text-white mb-4 txt22">
							Selecciona en qué modalidad quieres jugar
						</h2>
						<div className="my-3 txt18">
							<button
								className="txt18"
								onClick={() => {
									if (GAME_DURATION) {
										navigate(ROUTES.REGISTER_INDIVIDUAL, {
											state: {
												duration: GAME_DURATION,
											},
										});
									} else {
										navigate(ROUTES.SHORT_OR_LONG);
									}
								}}
							>
								<img
									src={icoindividual}
									alt="modo individual"
								/>{" "}
								INDIVIDUAL
							</button>
						</div>
						<div className="my-3">
							<button
								className="txt18"
								onClick={(event) => {
									event.stopPropagation();
									handleConfirmGroupGame();
								}}
							>
								<img
									src={icogrupal}
									alt="modo grupal"
									className=""
								/>{" "}
								GRUPAL
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="legalGebro position-absolute w-100 mb-4">
				<div className="d-flex justify-content-center txtWhite">
					<div
						className="btn-home mx-2"
						style={{ cursor: "pointer" }}
						onClick={(event) => {
							event.stopPropagation();
							window.open(TERMINOS_USO_URL, "_blank");
						}}
					>
						{"Aviso Legal"}
					</div>
					|
					<div
						className="btn-home mx-2"
						style={{ cursor: "pointer" }}
						onClick={(event) => {
							event.stopPropagation();
							window.open(POLITICA_PRIVACIDAD_URL, "_blank");
						}}
					>
						{"Política de Privacidad"}
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(IndividualOrGrupal);
