import React, { useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import icoVideoPlayer from "../assets/ico-video-player.svg";
import initialVideo from "../assets/sample-video.mp4";
import { useLang } from "../translations/i18n";
import { ROUTES } from "../utils/constants";
import { updateStats } from "../utils/helpers";

function Start({ intl }) {
	const lang = useLang();
	const game = useSelector((state) => state.game);
	const participant = useSelector((state) => state?.group);
	const { state } = useLocation();
	const navigate = useNavigate();

	const [videoSize, setVideoSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const handleResize = () => {
			setVideoSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const videoRef = useRef(null);

	const handlePlayButtonClick = () => {
		if (videoRef.current) {
			if (videoRef.current.paused) {
				videoRef.current.play();
				setVideoPlaying(true);
			} else {
				videoRef.current.pause();
				setVideoPlaying(false);
			}
		}
	};

	const [videoPlaying, setVideoPlaying] = useState(false);

	return (
		<>
			<div
				className="d-flex justify-content-center align-items-center"
				style={{ height: videoSize.height - 87 }} //87px height of top bar
			>
				<video
					ref={videoRef}
					className="video-player"
					onPlay={() => setVideoPlaying(true)}
					onClick={handlePlayButtonClick}
					onEnded={() => {
						updateStats({
							...participant,
							lastVisited: ROUTES.HISTORIAL,
						});
						navigate(ROUTES.HISTORIAL);
					}}
					width={videoSize.width}
					height="100%"
					id="video"
				>
					<source src={initialVideo} type="video/mp4" />
				</video>
				{!videoPlaying && (
					<img
						src={icoVideoPlayer}
						width={80}
						className="play-button cursor-pointer"
						onClick={handlePlayButtonClick}
					></img>
				)}
			</div>
		</>
	);
}

export default injectIntl(Start);
