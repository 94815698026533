import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import centroInfo from "../assets/centro-info-gsk.png";
import logo from "../assets/logov2.svg";
import qrLiquido from "../assets/qr-nucala-liquido.png";
import qrPolvo from "../assets/qr-nucala-polvo.png";

function EndQR({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="pt-4 h-100">
			<div className="container d-flex justify-content-between legalGebro pb-3">
				<div>
					<img src={logo} alt="gsk" className="img-fluid mw170" />
				</div>
			</div>
			<div className="container h-100" style={{ paddingTop: "7vh" }}>
				<div className="row d-flex justify-content-center align-items-center">
					<div
						className="col-11 text-white"
						style={{ zIndex: "9999" }}
					>
						<div>
							<div className="d-flex justify-content-between px-5">
								<div className="d-flex align-items-start">
									<div className="me-2">
										Escanee el QR o haga click{" "}
										<a
											className="text-yellow cursor-pointer"
											style={{ textDecoration: "none" }}
											href="https://gskpro.com/content/dam/global/hcpportal/es_ES/pdf/ft-promocional-nucala-liquido-reducida.pdf"
											target="_blank"
										>
											aquí
										</a>{" "}
										para acceder a la ficha técnica{" "}
										<span className="bold">
											Nucala® líquido CIMA
										</span>
									</div>
									<img src={qrLiquido}></img>
								</div>
								<div className="d-flex align-items-start ms-5">
									<div className="me-2">
										Escanee el QR o haga click{" "}
										<a
											className="text-yellow cursor-pointer"
											style={{ textDecoration: "none" }}
											href="https://gskpro.com/content/dam/global/hcpportal/es_ES/pdf/ft-nucala-polvo-reducida.pdf"
											target="_blank"
										>
											aquí
										</a>{" "}
										para acceder a la ficha técnica{" "}
										<span className="bold">
											Nucala® polvo CIMA
										</span>
									</div>
									<img src={qrPolvo}></img>
								</div>
							</div>
							<div className="d-flex justify-content-between mt-5">
								<div className="txt13">
									Para notificar una sospecha de reacción
									adversa, contacte con farmacovigilancia de
									GSK a través de GSK{" "}
									<a
										className="text-yellow cursor-pointer"
										style={{ textDecoration: "none" }}
										href="https://es.gsk.com/es-es/contacto/#unidad-de-farmacovigilancia"
										target="_blank"
									>
										https://es.gsk.com/es-es/contacto/#unidad-de-farmacovigilancia
									</a>{" "}
									o con el Sistema Español de
									Farmacovigilancia a través de{" "}
									<a
										className="text-yellow cursor-pointer"
										style={{ textDecoration: "none" }}
										href="http://www.notificaRAM.es"
										target="_blank"
									>
										www.notificaRAM.es
									</a>
								</div>
								<img className="ms-4" src={centroInfo}></img>
							</div>
							<div className="txt11">
								<div>
									<span className="bold">
										CONDICIONES DE PRESCRIPCIÓN Y
										DISPENSACIÓN
									</span>
									<div>
										Medicamento sujeto a prescripción
										médica. Diagnóstico hospitalario sin
										cupón precinto. Reembolsable por el
										Sistema Nacional de Salud.
									</div>
								</div>
								<div className="mt-2">
									<span className="bold">
										PRESENTACIONES Y PRECIO
									</span>
									<div>
										Nucala 40 mg solución inyectable en
										jeringa precargada, 1 jeringa precargada
										PVL: 434,40€ PVP: 485,31€ PVP IVA:
										504,72€ Nucala 100 mg solución para
										inyección en pluma precargada, 1 jeringa
										precargada. Nucala 100 mg solución para
										inyección en jeringa precargada, 1
										jeringa precargada. PVL: 1.086€ PVP
										1.141,91 € PVP IVA 1.187,59€
									</div>
								</div>{" "}
								<div className="mt-2">
									<span className="bold">
										PRESENTACIONES Y PRECIO
									</span>
									<div>
										Nucala 100 mg polvo para solución
										inyectable PVL: 1.086€ PVP 1.141,91€ PVP
										IVA 1.187,59€
									</div>
									<div className="mt-3">
										La información detallada de este
										medicamento está disponible en la página
										web de la Agencia Europea de
										Medicamentos{" "}
										<a
											className="text-yellow cursor-pointer"
											style={{ textDecoration: "none" }}
											href="http://www.ema.europa.eu"
											target="_blank"
										>
											http://www.ema.europa.eu
										</a>
									</div>
								</div>{" "}
							</div>
						</div>
					</div>
				</div>
				<div
					className="container"
					style={{ position: "absolute", bottom: "5%" }}
				>
					<div className="d-flex justify-content-end">
						<div className="d-flex flex-column align-items-end">
							<div className="d-flex justify-content-end">
								<div
									className="txt11 text-white"
									style={{
										marginRight: "-50%",
										marginBottom: "40vh",
										whiteSpace: "nowrap",
										transform: "rotate(270deg)",
									}}
								>
									Presentación para uso en reuniones
									organizadas por GSK
								</div>
							</div>
							<div className="text-white txt11 mt-4">
								PM-ES-MPL-EDTL-230005 (v1) 01/2024{" "}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(EndQR);
