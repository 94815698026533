import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import iconoDudaRojo from "../../assets/icono-duda-rojo.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalBiblioReferences } from "../../utils/logger";

function Pregunta5AmpliarInfo({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [intentosRestantes, setIntentosRestantes] = useState(4);

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Barretto KT, Brockman-Schneider RA, Kuipers I, et al.
					<span class="regular">
						Human airway epithelial cells express a functional IL-5
						receptor. Allergy. 2020;75(8):2127-30.
					</span>
				</span>
			</li>
		</ol>`;

	return (
		<>
			<div className="h-100 bg-ampliar-info">
				<div className="container">
					<div className="row h-100">
						<div className="col-2">
							<div className="d-flex align-items-center">
								<img src={iconoDudaRojo} width={56}></img>
								<div className="ms-3">
									<div className="text-red bold">
										Has ganado
									</div>
									<div className="bg-points-info">
										+25 puntos
									</div>
								</div>
							</div>
						</div>
						<div className="col-10 pt-5">
							<div className="row d-flex align-items-center">
								<div className="col-11">
									<div className="d-flex flex-column justify-content-center align-items-center">
										<p className="col-9 txt16 mt-5">
											Las células del epitelio bronquial y
											nasosinusal expresan de forma
											constitutiva receptores de IL5. La
											IL5, al unirse a estos receptores,
											provoca que{" "}
											<span className="bold">
												las uniones célula a célula y
												célula a matriz celular se
												debiliten
											</span>
											, y con ello{" "}
											<span className="bold">
												la integridad de la barrera
												epitelial
											</span>
											, lo que permite el paso de agentes
											externos (alérgenos, contaminación,
											microorganismos) y la activación de
											la inmunidad T2 tanto alérgica como
											no alérgica.
										</p>
									</div>
								</div>
								<div className="col-1 pe-4">
									<div className="d-flex flex-column align-items-end">
										<LifeIndicatorBar
											numIntentos={null}
										></LifeIndicatorBar>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{ position: "absolute", bottom: "10vh" }}
					>
						<div className="d-flex align-items-end justify-content-between">
							<div
								className="txt12 underline text-red cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
							<div>
								<NextButton
									navigateTo={ROUTES.TRANSITION_SCENARIOS}
									navigateToAfter={ROUTES.P6_INTRO}
								></NextButton>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta5AmpliarInfo);
