import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import caraFeliz from "../assets/cara-feliz.svg";
import caraTriste from "../assets/cara-triste.svg";
import espiral from "../assets/espiral.svg";
import iconoCrono from "../assets/icono-crono.svg";
import iconoMas from "../assets/icono-mas.svg";
import iconoTiempo from "../assets/icono-tiempo.svg";
import NextButton from "../components/NextButton";
import { ROUTES } from "../utils/constants";
import { updateTimer } from "../utils/helpers";
import { useSelector } from "react-redux";

function Instructions({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state?.group);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleStartGame = () => {
		let time = participant?.time ? parseInt(participant?.time) : 0;
		updateTimer(time);
	};

	return (
		<div className="pt-4 h-100">
			<div className="container h-100">
				<div className="d-flex justify-content-center align-items-center h-75">
					<div className="d-flex flex-column align-items-center text-center selection">
						<img className="my-4" src={espiral} alt="GSK" />
						<h1 className="text-white">
							<span className="bold">
								Es tu turno de ayudar a María.
							</span>
						</h1>
						<div className="text-white" style={{ maxWidth: "80%" }}>
							Al otro lado de esta puerta te esperan una serie de
							preguntas y retos que pondrán a prueba tus
							conocimientos sobre la IL-5 en el asma grave.
						</div>
						<div className="my-5">
							<div className="d-flex justify-content-center">
								<div
									className="bg-historial-item p-3 txt16"
									style={{ maxWidth: "178px" }}
								>
									<div className="d-flex flex-column align-items-center">
										<img src={caraFeliz}></img>
										<div className="mt-2">
											Cada{" "}
											<span className="bold">
												respuesta correcta
											</span>{" "}
											o{" "}
											<span className="bold">
												prueba superada
											</span>{" "}
											ayudarán a María en su tratamiento
											contra esta enfermedad.{" "}
										</div>
									</div>
								</div>
								<div
									className="bg-historial-item p-3 txt16 ms-3"
									style={{ maxWidth: "178px" }}
								>
									<div className="d-flex flex-column align-items-center">
										<img src={caraTriste}></img>
										<div className="mt-2">
											Presta atención porque{" "}
											<span className="bold">
												tus fallos harán que empeore.
											</span>
										</div>
									</div>
								</div>
								<div
									className="bg-historial-item p-3 txt16 ms-3"
									style={{ maxWidth: "178px" }}
								>
									<div className="d-flex flex-column align-items-center">
										<img src={iconoMas}></img>
										<div className="mt-2">
											<span className="bold">
												La curiosidad tiene premio:
											</span>{" "}
											¡puntos extra!
										</div>
									</div>
								</div>
								<div
									className="bg-historial-item p-3 txt16 ms-3"
									style={{ maxWidth: "178px" }}
								>
									<div className="d-flex flex-column align-items-center">
										<img
											src={iconoCrono}
											className="mt-4"
										></img>
										<div className="mt-4 pt-1">
											Tiempo para llegar al final del caso
										</div>
									</div>
								</div>
								<div
									className="bg-historial-item p-3 txt16 ms-3"
									style={{ maxWidth: "178px" }}
								>
									<div className="d-flex flex-column align-items-center">
										<img src={iconoTiempo}></img>
										<div className="mt-2">
											<span className="bold">
												Tiempo extra: + 5 minutos= - 25
												%
											</span>{" "}
											de tu puntuación total
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="d-flex justify-content-end"
					onClick={handleStartGame}
				>
					<NextButton navigateTo={ROUTES.P1_INTRO}></NextButton>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Instructions);
