export const SHORT_GAME_DURATION = 20; //min
export const LONG_GAME_DURATION = 45; //min
export const EXTRA_TIME = 5; //min

export const TERMINOS_USO_URL =
	"https://terms.gsk.com/es-es/pharmaceuticals/default/";

export const POLITICA_PRIVACIDAD_URL =
	"https://privacy.gsk.com/es-es/privacy-notice/";

/*export const AVISO_LEGAL = `<div class="txt14">
		<p class="bold">Actualizado el 23 de febrero de 2018</p>
		<p>
			Este sitio
			web y los seminarios web de GSK organizados o promovidos en este
			sitio web (conjuntamente denominados el «Sitio») son propiedad de
			GlaxoSmithKline, S.A., A-28228526, sociedad española con domicilio
			social en C/ Severo Ochoa, 2 (PTM), 28760 – Tres Cantos (Madrid),
			registrada en el Registro Mercantil de Madrid, en el Volumen 2248,
			Hoja 126, Página M-39705, Entrada 53 («GSK»). Al hacer uso de este
			Sitio, usted acepta las presentes condiciones de uso. Si no está de
			acuerdo, no utilice este Sitio.
		</p>
		<p>
			GSK se reserva el derecho, a su propia discreción, de cambiar,
			modificar, añadir o eliminar partes de estas Condiciones de Uso en
			cualquier momento. Es responsabilidad de usted realizar revisiones
			periódicas de las Condiciones de Uso por posibles cambios. Puede
			acceder a las Condiciones de Uso a través del hipervínculo situado
			al final de la página web. El uso continuado del Sitio que usted
			realice tras la actualización de las Condiciones de Uso implica que
			acepta cumplir con dichos cambios.
		</p>
		<p>
			La información dispuesta en este Sitio se proporciona solo de manera
			general y para fines educativos. El Sitio puede proporcionar
			información sobre enfermedades y su tratamiento. Si usted es un
			profesional sanitario, la información que aparece en este sitio web
			no pretende actuar como un sustituto de su propio juicio médico. Si
			padece una afección médica, debe solicitar asesoramiento médico de
			un profesional sanitario cualificado. La información que se muestra
			en este Sitio no debe interpretarse por sí sola como la prestación
			de ningún tipo de recomendación o consejo médicos
		</p>

		<p class="txt18 text-orange bold">Usuarios permitidos</p>
		<p>
			Este sitio web está destinado únicamente a profesionales sanitarios.
			Al utilizar esta plataforma, certifica que dispone de la titulación
			necesaria para actuar como profesional sanitario en el país de
			destino de este Sitio
		</p>
		<p>
			Este sitio web cuenta con una sección específica para el público en
			general, otra para los pacientes y otra para los profesionales
			sanitarios. «Profesional sanitario» se refiere a un individuo que,
			en el curso de sus actividades profesionales, puede prescribir,
			suministrar o administrar medicamentos o dispositivos médicos.
		</p>
		<p>
			La sección para el público se ha diseñado como una biblioteca de
			consulta que proporciona a los consumidores de España información de
			referencia relativa a los medicamentos de GSK, entre los que se
			incluyen los medicamentos sujetos a prescripción médica. Dentro de
			la sección pública hay páginas específicas solo para pacientes a los
			que se les haya prescrito el medicamento al que se refieren esas
			páginas. Asimismo, están ocultas en el mapa de navegación del sitio
			y solo se puede acceder a ellas una vez que el paciente ha
			confirmado que se le ha prescrito un medicamento en particular.
		</p>
		<p>
			SOLO PARA PROFESIONALES SANITARIOS: Tenga en cuenta que la
			información de prescripción aprobada debe guiar el uso adecuado de
			todos los medicamentos. Antes de prescribir cualquier medicamento,
			los profesionales sanitarios deben consultar la información de
			prescripción aprobada para ese medicamento en su país.
		</p>
		<p class="txt18 text-orange bold">Cuentas, contraseñas, seguridad</p>
		<p>
			Si elige abrir una cuenta (incluyendo la configuración de usuario y
			contraseña), usted es el único responsable de mantener la
			confidencialidad de la información contenida en su cuenta y todas
			las actividades que se realicen con la misma.
		</p>

		<p class="txt18 text-orange bold">
			Condiciones de uso de los seminarios web
		</p>
		<p>
			Al participar en seminarios web de GSK organizados y promovidos en
			este sitio web, es posible que otros participantes puedan ver su
			identidad y otros datos personales que usted proporcione al
			registrarse. Al participar en seminarios web, usted consiente la
			difusión de esta información. Al participar en seminarios web, por
			favor identifíquese con un nombre con el que usted esté de acuerdo
			en que otros participantes le vean.
		</p>
		<p>
			El uso de la plataforma del seminario web se realizará bajo su
			propia responsabilidad. GSK no confirma ni garantiza la exactitud,
			idoneidad o integridad de la información compartida por terceros
			durante seminarios web. GSK no se hace responsable de los daños y
			perjuicios ocurridos por el uso de la plataforma del seminario web.
			Nada de lo dispuesto en esta limitación de responsabilidad, o de
			otro modo, limitará la responsabilidad de GSK por daños personales
			debidos a negligencia de GSK.
		</p>

		<p class="txt18 text-orange bold">Grabación</p>
		<p>
			Los seminarios web organizados por GSK pueden ser grabados. GSK
			puede utilizar esta grabación (en parte o en su totalidad)
			presentándola a otro público en el futuro a su propia discreción.
			Por ejemplo, GSK puede colgar el seminario web grabado en sitios web
			a los que pueden acceder personas de todo el mundo. Si GSK graba su
			imagen, voz y/o apariencia como resultado de su participación en
			seminarios web, GSK obtendrá su consentimiento para ser grabado en
			el momento en el que accede a los mismos. Si no desea que se le
			grabe, debe silenciar su micrófono y no compartir su imagen o su
			pantalla. También puede elegir no participar en el seminario web.
		</p>

		<p class="txt18 text-orange bold">Productos en todo el mundo</p>
		<p>
			Los sitios web corporativos de GSK contienen información sobre sus
			productos y servicios a nivel mundial, aunque no todos están
			disponibles en todas las ubicaciones. Referencias a un producto o
			servicio de GSK en el Sitio no implica que dicho producto o servicio
			está o estará disponible en su ubicación.
		</p>

		<p class="txt18 text-orange bold">Actualización de la información</p>
		<p>
			GSK hace todos los esfuerzos razonables para mantener actualizados
			sus Sitios, aunque es posible que parte de la información quede
			desactualizada con el tiempo. GSK no puede garantizar ni confirmar
			la fiabilidad, exactitud, idoneidad, integridad o actualización de
			la información contenida en este sitio web, incluyendo aquella
			compartida en el seminario web. La información en este Sitio se
			proporciona tal cual.
		</p>

		<p class="txt18 text-orange bold">Uso y acceso</p>
		<p>
			Usted puede navegar libremente por este Sitio, aunque solo puede
			acceder a o descargar material de este Sitio para su uso personal y
			no comercial. Los derechos de autor y otras advertencias sobre
			propiedad intelectual dispuestos en los materiales descargados se
			deben conservar y mantener. Sin el permiso expreso por escrito de
			GSK, no se permiten otros usos del material dispuesto en este Sitio.
		</p>
		<p>
			Se permite el acceso a este Sitio de manera temporal y GSK se
			reserva el derecho de retirar o modificar el servicio proporcionado
			en este Sitio sin previo aviso. GSK no será responsable si, por
			cualquier motivo, el Sitio no se encuentra disponible en cualquier
			momento o por cualquier periodo de tiempo. GSK se reserva el derecho
			de suspender o cerrar cualquier cuenta que haya creado en este sitio
			web si infringe las Condiciones de Uso.
		</p>

		<p class="txt18 text-orange bold">
			Marcas comerciales/derechos de propiedad intelectual e industrial
		</p>
		<p>
			Usted debe suponer que todas las marcas comerciales que aparecen en
			este Sitio, independientemente de si aparecen en letras grandes o
			con el símbolo de marca comercial, son marcas comerciales de GSK,
			sus filiales, empresas asociadas o sus licenciantes o socios de
			empresas conjuntas, a menos que se indique lo contrario. Asimismo,
			debe suponer que toda la información visible o legible en este Sitio
			está protegida por derechos de autor o que GSK dispone del permiso
			para su uso, a no ser que se especifique lo contrario, y no se puede
			utilizar de manera distinta a lo dispuesto en las Condiciones de Uso
			o en el texto del Sitio sin el permiso por escrito de GSK. El uso de
			este Sitio no le concede licencia o derecho sobre cualquier derecho
			de propiedad intelectual o industrial del grupo de empresas de GSK
			(incluyendo patentes, marcas comerciales y derechos de autor) o de
			un tercero. El uso o uso indebido de cualquiera de estas marcas
			comerciales, derechos de autor u otros materiales, salvo que se
			permita lo contrario, se prohíbe expresamente y puede infringir la
			legislación en materia de derechos de autor, marcas comerciales u
			otras leyes, estatutos o reglamentos.
		</p>
		<p>
			GSK afirma que no es titular de, ni se encuentra vinculada a,
			ninguna de las marcas comerciales de terceros que aparezcan en este
			Sitio. Dichas marcas comerciales de terceros se utilizan únicamente
			para identificar los productos y servicios de sus respectivos
			titulares y no se debe deducir ningún tipo de patrocinio o apoyo por
			parte de GSK del uso de estas marcas.
		</p>

		<p class="txt18 text-orange bold">Enlaces de hipertexto</p>
		<p>
			Los vínculos a sitios de terceros pueden proporcionarse por motivos
			de interés o comodidad para los usuarios de este Sitio. GSK no asume
			ninguna responsabilidad por el contenido de sitios que no sean de
			GSK a los que se accede a través de los vínculos proporcionados y no
			se hace responsable de la información u opiniones disponibles en
			cualquier sitio de un tercero ni de cualquier infracción u omisión
			de las políticas de privacidad de terceros.
		</p>

		<p class="txt18 text-orange bold">Información que usted nos suministra</p>
		<p>
			Salvo por la información cubierta por nuestra Declaración de
			privacidad, cualesquiera comunicación o materiales que transmita a o
			a través de nuestro Sitio, incluso durante un seminario web, tales
			como datos, preguntas, comentarios, ideas, experiencia o similares,
			es y se considerará como información no confidencial y que no es de
			su propiedad. GSK no tendrá ningún tipo de obligación con respecto a
			dicha información y será libre de utilizarla, divulgarla,
			reproducirla o publicarla sin limitación de ningún tipo, incluyendo
			para fines comerciales, sin tener que pagar a los usuarios por el
			derecho de hacerlo. Otros participantes también tendrán acceso a la
			información que usted comparta durante un seminario web.
		</p>

		<p class="txt18 text-orange bold">Responsabilidad</p>
		<p>
			Salvo cuando la legislación lo prohíba y excepto para cualquier
			garantía indicada expresamente en estas Condiciones de uso, se
			excluye cualquier condición, declaración, garantía, derecho o
			responsabilidad implícita de alguna manera en estas Condiciones de
			Uso o impuesta por la legislación.
		</p>
		<p>
			El uso que usted realice de este Sitio es bajo su propia
			responsabilidad y en ningún caso GSK será responsable de cualquier
			daño (incluidos, entre otros, daños y perjuicios directos,
			incidentales, resultantes, indirectos o punitivos o daños derivados
			de la pérdida de beneficios, pérdida de datos o interrupción del
			negocio) derivado de su acceso, uso o imposibilidad para acceder o
			utilizar este Sitio o de cualquier error u omisión en su contenido.
			Esto incluye daños en su ordenador o cualquier otra propiedad,
			incluyendo aquellos producidos por virus informáticos o cualquier
			otro material tecnológicamente dañino que pueda infectar su
			ordenador u otra propiedad debido a su uso de este Sitio, incluyendo
			la descarga de cualquier archivo u otro contenido de este Sitio.
		</p>

		<p class="txt18 text-orange bold">No es una invitación a la negociación</p>
		<p>
			Nada de lo dispuesto en este Sitio constituye una invitación u
			oferta para invertir o negociar los valores o ADR (Recibos de
			depósito americanos) de GSK. En particular, los desarrollos y
			resultados reales pueden ser sustancialmente diferentes a cualquier
			previsión, opinión o expectativa expresadas en este Sitio, y la
			rentabilidad histórica del precio de los valores no debe utilizarse
			como guía para su rendimiento futuro.
		</p>

		<p class="txt18 text-orange bold">Declaraciones prospectivas</p>
		<p>
			Este Sitio puede contener declaraciones prospectivas sujetas a
			riesgos e incertidumbres que pueden conllevar que los resultados
			actuales difieran de aquellos previstos, incluyendo los riesgos
			detallados en los informes de GSK archivados en la Security and
			Exchange Commission (“SEC”), incluido el informe de GSK más
			recientemente archivado en la SEC.
		</p>
		<p>
			Los encabezados utilizados en estas Condiciones de uso son solo por
			comodidad y no se deben utilizar para determinar el significado o la
			interpretación de estas Condiciones de uso.
		</p>
		<p class="txt12">
			© 2017-2018 GlaxoSmithKline plc. All Rights Reserved. Registered in
			England and Wales No.3888792 Registered Office: 980 Great West Road,
			Brentford, Middlesex, TW8 9GS, United Kingdom.
		</p>
	</div>`;*/

export const ROUTES = {
	HOME: "/",
	HOME_SHORT: "/short",
	HOME_LONG: "/long",

	START: "/start",
	VIDEO: "/video",
	INDIVIDUAL_GRUPAL: "/individualorgrupal",
	SHORT_OR_LONG: "/short-or-long",
	REGISTER_INDIVIDUAL: "/register",
	REGISTER_GROUP: "/registergroup",
	HISTORIAL: "/paciente-historial",
	INSTRUCTIONS: "/instructions",
	TRANSITION_SCENARIOS: "/transition-scenarios",

	P1_INTRO: "/intro-pregunta1",
	P1: "/pregunta1",
	P1_PART2: "/pregunta1-parte2",
	P1_EXPLICATION: "/pregunta1-explicacion",
	P1_EXPLICATION_EXTRA: "/pregunta1-explicacion-extra",
	P1_EXTRA: "/pregunta1-extra",
	P1_EXTRA2: "/pregunta1-extra2",
	P1_EXTRA_VF: "/pregunta1-extraVF",
	P1_EXTRA_VF2: "/pregunta1-extraVF2",
	P1_EXTRA_VF_EXTRA: "/pregunta1-extraVF-extra",
	P1_SUPERADA: "/pregunta1-superada",

	P2_INTRO: "/intro-pregunta2",
	P2: "/pregunta2",
	P2_SOLUTION: "/pregunta2-solucion",
	P2_PART2: "/pregunta2-part2",
	P2_EXTRA: "/pregunta2-extra",
	P2_SUPERADA: "/pregunta2-superada",

	P3_INTRO: "/intro-pregunta3",
	P3_EXPLICATION: "/pregunta3-explicacion",
	P3: "/pregunta3",
	P3_SOLUTION: "/pregunta3-solucion",
	P3_SUPERADA: "/pregunta3-superada",

	P4_INTRO: "/intro-pregunta4",
	P4: "/pregunta4",
	P4_EXPLICATION: "/pregunta4-explication",
	P4_SOLUTION: "/pregunta4-solucion",
	P4_EXTRA: "/pregunta4-extra",
	P4_EXTRA_VF: "/pregunta4-extraVF",
	P4_SUPERADA: "/pregunta4-superada",

	P5_INTRO: "/intro-pregunta5",
	P5: "/pregunta5",
	P5_EXTRA: "/pregunta5-extra",
	P5_AMPLIAR: "/pregunta5-ampliar",
	P5_SUPERADA: "/pregunta5-superada",

	P6_INTRO: "/intro-pregunta6",
	P6_EXPLICATION: "/pregunta6-explicacion",
	P6: "/pregunta6",
	P6_SOLUTION: "/pregunta6-solucion",
	P6_SUPERADA: "/pregunta6-superada",
	P6_AMPLIAR: "/pregunta6-ampliar",

	P7_INTRO: "/intro-pregunta7",
	P7: "/pregunta7",
	P7_SOLUTION: "/pregunta7-solucion",
	P7_SUPERADA: "/pregunta7-superada",

	IDEAS_CLAVE: "/ideas-clave",
	END: "/end",
	FINAL_SCORE: "/final-score",
	RESULTS: "/results",
	RANKING: "/ranking",
	FINAL_RANKING: "/final-ranking",
	FINES_JUEGO: "/fines-juego",
	END_QR: "/end-qr",
};

export const SCENARIOS = {
	SCENARIO_1: "659bd6394033f211c45bb89b",
	SCENARIO_2: "659bd64b4033f211c45bb89d",
	SCENARIO_3: "659bd65e4033f211c45bb89f",
	SCENARIO_4: "659bd66c4033f211c45bb8a1",
	SCENARIO_5: "659bd67f4033f211c45bb8a3",
	SCENARIO_6: "659bd6934033f211c45bb8a5",
	SCENARIO_7: "659bd6a24033f211c45bb8a7",
};

export const QUIZZES = {
	SCENARIO1_ALGORITMO_1: "65b21122cf5c28554c380d68",
	SCENARIO1_ALGORITMO_2: "65b2113ccf5c28554c380d6a",
	SCENARIO1_CURIOSIDAD_1: "65b21148cf5c28554c380d6c",
	SCENARIO1_TEST_1: "65b2117acf5c28554c380d6e",
	SCENARIO1_TEST_2: "65b2117dcf5c28554c380d70",
	SCENARIO1_VF_1: "65b21196cf5c28554c380d72",
	SCENARIO1_VF_2: "65b2119dcf5c28554c380d74",
	SCENARIO1_CURIOSIDAD_2: "65b211b2cf5c28554c380d76",

	SCENARIO2_ESCRIBIR: "65b211e0cf5c28554c380d78",
	SCENARIO2_SELECCIONAR: "65b21235cf5c28554c380d7f",
	SCENARIO2_TEST: "65b212b3cf5c28554c380d81",

	SCENARIO3_ORDENAR: "65b212e2cf5c28554c380d83",
	SCENARIO3_CURIOSIDAD: "65b21305cf5c28554c380d85",

	SCENARIO4_ORDENAR: "65b2136ecf5c28554c380d91",
	SCENARIO4_ETIQUETAS: "65b21392cf5c28554c380d93",
	SCENARIO4_VF: "65b213aacf5c28554c380d95",

	SCENARIO5_ALGORITMO: "65b213cecf5c28554c380d97",
	SCENARIO5_SELECCIONAR: "65b213f6cf5c28554c380d9e",
	SCENARIO5_CURIOSIDAD: "65b21405cf5c28554c380da0",

	SCENARIO6_CUADRORESUMEN: "65b21456cf5c28554c380da2",
	SCENARIO6_CURIOSIDAD: "65b2146acf5c28554c380da4",

	SCENARIO7_ESQUEMA: "65b21491cf5c28554c380da6",
};

export const TEST_PARTICIPANT = "659be492c3546957bcc2d9d6";

export const SCORE_TYPES = {
	SCORE: "SCORE",
	BONUS: "BONUS",
	PENALTY: "PENALTY",
};

export const NO_TIME_PROGRESS_SCREENS = [
	ROUTES.HOME,
	ROUTES.HOME_SHORT,
	ROUTES.HOME_LONG,
	ROUTES.INDIVIDUAL_GRUPAL,
	ROUTES.SHORT_OR_LONG,
	ROUTES.REGISTER_INDIVIDUAL,
	ROUTES.REGISTER_GROUP,
	ROUTES.HISTORIAL,
	ROUTES.VIDEO,
	ROUTES.START,
	ROUTES.INSTRUCTIONS,
	ROUTES.IDEAS_CLAVE,
	ROUTES.END,
	ROUTES.FINAL_SCORE,
	ROUTES.RESULTS,
	ROUTES.RANKING,
	ROUTES.FINES_JUEGO,
	ROUTES.END_QR,
];
