import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";

function Pregunta2Superada({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	return (
		<>
			<div className="pt-4 h-100 bg-fin-escenario escenario-2">
				<div className="container h-100">
					<div className="col-12 text-red txt50 bold">
						¡Segunda parte superada!
					</div>

					<div className="row mt-3">
						<div className="col-6">
							<p className="txt16">
								El aumento de IL5 ha producido la eosinofilia en
								María, que ha sido la causante de su
								empeoramiento.
							</p>
							<p className="txt16 bold">
								Uno de nuestros objetivos debería ser mantener
								los eosinófilos en niveles fisiológicos.
							</p>
						</div>
					</div>
					<div
						className="container ps-0"
						style={{
							position: "absolute",
							bottom: "12%",
						}}
					>
						<div className="d-flex align-items-center justify-content-end">
							<div>
								<NextButton
									navigateTo={ROUTES.TRANSITION_SCENARIOS}
									navigateToAfter={ROUTES.P3_INTRO}
								/>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta2Superada);
