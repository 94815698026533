import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalBiblioReferences } from "../../utils/logger";

function Pregunta4Superada({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Bachert C, Marple B, Schlosser RJ, et al.
					<span class="regular">
						Adult chronic rhinosinusitis. Nat Rev Dis Primers.
						2020;6(1):86.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Rosenberg HF, Dyer KD, Foster PS.
					<span class="regular">
						Eosinophils: changing perspectives in health and
						disease. Nat Rev Immunol. 2013;13(1):9-22.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Acharya KR, Ackerman SJ.
					<span class="regular">
						Eosinophil granule proteins: form and function. J Biol
						Chem. 2014;289(25):17406-15.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Lu Y, Huang Y, Li J, et al.
					<span class="regular">
						Eosinophil extracellular traps drive asthma progression
						through neuro-immune signals. Nat Cell Biol.
						2021;23(10):1060-72.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Choi Y, Kim YM, Lee HR, et al.
					<span class="regular">
						Eosinophil extracellular traps activate type 2 innate
						lymphoid cells through stimulating airway epithelium in
						severe asthma. Allergy. 2020;75(1):95-103.
					</span>
				</span>
			</li>
		</ol>`;

	return (
		<>
			<div className="pt-4 h-100 bg-fin-escenario escenario-4">
				<div className="container h-100">
					<div className="col-12 text-red txt50 bold">
						¡Cuarta parte superada!
					</div>
					<div className="row mt-1">
						<div className="col-7 txt16">
							<p>
								Al principio de esta parte preguntábamos:{" "}
								<span className="bold">
									¿cuáles pueden ser los mecanismos
									subyacentes al empeoramiento funcional de
									María durante este último año?
								</span>
							</p>
							<p>Ya tenemos la respuesta:</p>
							<p>
								María presenta una cierta disminución de la FVC
								(capacidad vital forzada), que podría estar en
								relación con aumento de tapones mucosos en la
								vía aérea. También presenta aumento de la
								expectoración mucosa matutina y nocturna.{" "}
								<span className="bold">
									Este aumento de la producción de moco
									producido por la hiperplasia de células
									caliciformes es producido en parte por la
									interacción de las células neuroendocrinas
									del epitelio bronquial con las redes
									extracelulares de ADN, liberadas por los
									eosinófilos<sup> 1-3</sup>.
								</span>
							</p>
							<p>
								Por otra parte, la espirometría de María también
								muestra una caída del FEV1, la cual podría estar
								indicando{" "}
								<span className="bold">
									cierto grado de remodelado bronquial
									producido por la EETosis<sup> 4,5</sup>.
								</span>
							</p>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{ position: "absolute", bottom: "8%" }}
					>
						<div className="d-flex align-items-end justify-content-between">
							<div
								className="txt12 underline text-red cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
							<div>
								<NextButton
									navigateTo={ROUTES.TRANSITION_SCENARIOS}
									navigateToAfter={ROUTES.P5_INTRO}
								></NextButton>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta4Superada);
