import React from "react";
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NextButton from "../components/NextButton";
import { useLang } from "../translations/i18n";
import { ROUTES } from "../utils/constants";

function End({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const group = useSelector((state) => state.group);

	return (
		<div className="endbg txt-white">
			<div className="row h-100 justify-content-center">
				<div className="d-flex align-items-center justify-content-center pb-5">
					<div className="text-center text-white">
						<p className="txt28 bold">
							<div>¡Enhorabuena!</div>
							<div>Has llegado al final de la actividad.</div>
						</p>
						<p>
							Muchas gracias por tu participación en Exploring
							IL5. Esperamos que hayas disfrutado de la{" "}
							<span className="bold">experiencia</span>.
						</p>
						<p>Y ahora, ¿quieres conocer tu puntuación?</p>
					</div>
				</div>
			</div>
			<div
				style={{
					position: "absolute",
					bottom: "20vh",
					right: "20%"
				}}
			>
				<div className="d-flex justify-content-center">
					<NextButton
						navigateTo={ROUTES.FINAL_SCORE}
						text={"PUNTUACIÓN"}
					></NextButton>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(End);
