import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import bgExtraInfoClick from "../../assets/bg-extra-click.png";
import dibujoInflamacionT2 from "../../assets/dibujoInflamacionT2.png";

import { useSelector } from "react-redux";
import { postAnswer } from "../../api/answer";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import { modalBiblioReferences } from "../../utils/logger";

function Pregunta1Explicacion({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const [intentosRestantes, setIntentosRestantes] = useState(4);

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Lambrecht BN, Hamad H.
					<span class="regular">
						The immunology of asthma. Nat Immunol. 2015;16(1):45-56.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Altman MC, Lai Y, Nolin JD, et al.
					<span class="regular">
						Airway epithelium-shifted mast cell infiltration
						regulates asthmatic inflammation via IL-33 signaling. J
						Clin Invest. 2019;129(11):4979-91. 2019;10:1514.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Lu Y, Huang Y, Li J, et al.
					<span class="regular">
						Eosinophil extracellular traps drive asthma progression
						through neuro-immune signals. Nat Cell Biol.
						2021;23(10):1060-72.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Acharya KR, Ackerman SJ.
					<span class="regular">
						Eosinophil granule proteins: form and function. J Biol
						Chem. 2014;289(25):17406-15.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Barretto KT, Brockman-Schneider RA, Kuipers I, et al.
					<span class="regular">
						Human airway epithelial cells express a functional IL-5
						receptor. Allergy. 2020;75(8):2127-213.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Choi Y, Kim YM, Lee HR, et al.
					<span class="regular">
						Eosinophil extracellular traps activate type 2 innate
						lymphoid cells through stimulating airway epithelium in
						severe asthma. Allergy. 2020;75(1):95-103.
					</span>
				</span>
			</li>
		</ol>`;

	const QUIZ_ID = QUIZZES.SCENARIO1_CURIOSIDAD_1;
	const SCENARIO_ID = SCENARIOS.SCENARIO_1;
	const NAVIGATE_TO_EXTRA = ROUTES.P1_EXPLICATION_EXTRA;

	const EXTRA_POINTS = 25;

	async function submitAnswer() {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: EXTRA_POINTS,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					dispatch(updateScore(EXTRA_POINTS, SCORE_TYPES.SCORE));
					dispatch(updateScore(EXTRA_POINTS, SCORE_TYPES.BONUS));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + EXTRA_POINTS,
						bonus: participant?.bonus + EXTRA_POINTS,
						lastVisited: NAVIGATE_TO_EXTRA,
					});
				}
			})
			.catch((error) => {});
	}

	return (
		<div className="h-100 bgprueba">
			<div className="container">
				<div className="row h-100">
					<div className="col-2">
						<img
							className="grow-animation"
							style={{ cursor: "pointer" }}
							src={bgExtraInfoClick}
							width={125}
							onClick={async () => {
								await submitAnswer();
								navigate(NAVIGATE_TO_EXTRA);
							}}
						></img>
					</div>
					<div className="col-8">
						<div className="d-flex justify-content-around align-items-end">
							<div className="txt12">
								<p>
									Pérdida de la integridad epitelial
									<sup>2</sup>
								</p>
								<p>
									Metaplasia de células caliciformes
									<sup>3</sup>
								</p>
								<p>
									Hiperplasia de músculo liso<sup>4</sup>
								</p>
								<p>
									Fibrosis subepitelial<sup>5</sup>
								</p>
								<p>
									Engrosamiento de la membrana basal
									<sup>6</sup>
								</p>
							</div>
							<Image
								src={dibujoInflamacionT2}
								width={400}
							></Image>
						</div>
					</div>
					<div className="col-2">
						<div className="d-flex flex-column align-items-end">
							<LifeIndicatorBar
								numIntentos={intentosRestantes}
							></LifeIndicatorBar>
						</div>
					</div>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{ position: "absolute", bottom: "10vh" }}
				>
					<div className="d-flex align-items-center justify-content-between">
						<div>
							<div
								className="txt12 underline text-red cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
						</div>
						<div>
							<NextButton
								navigateTo={ROUTES.P1_EXTRA}
							></NextButton>
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta1Explicacion);
