import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getParticipantById } from "../api/participant";
import ceroIntentos from "../assets/cero-intentos.svg";
import cuatroIntentos from "../assets/cuatro-intentos.svg";
import dosIntentos from "../assets/dos-intentos.svg";
import maria from "../assets/maria.svg";
import tresIntentos from "../assets/tres-intentos.svg";
import unIntento from "../assets/un-intento.svg";

function LifeIndicatorBar({ numIntentos }) {
	const participant = useSelector((state) => state.group);
	const life = useSelector((state) => state.group?.life);
	const dispatch = useDispatch();

	const MAX_LIFE = 100;

	const [participantLife, setParticipantLife] = useState(life);

	useEffect(() => {
		if (participant) {
			getParticipantById(participant?._id)
				.then((res) => {
					if (res.status === 200) {
						setParticipantLife(res.data?.life);
					}
				})
				.catch((error) => {});
		}
	}, [dispatch, participant]);

	return (
		<div
			className={`d-flex flex-column align-items-center ${
				numIntentos === null ? "pe-3" : ""
			}`}
		>
			{numIntentos != null && (
				<div>
					<img
						src={
							numIntentos === 4
								? cuatroIntentos
								: numIntentos === 3
								? tresIntentos
								: numIntentos === 2
								? dosIntentos
								: numIntentos === 1
								? unIntento
								: ceroIntentos
						}
						width={71}
					></img>
				</div>
			)}
			<div
				className={`position-relative ${
					numIntentos != null ? "mt-4" : ""
				}`}
			>
				<div className="life-indicator">
					<Image
						className="indicator-image"
						style={{ top: MAX_LIFE - participantLife + "%" }}
						src={maria}
						width={43}
						roundedCircle
					></Image>
				</div>
			</div>
		</div>
	);
}
export default LifeIndicatorBar;
