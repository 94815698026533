import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import dibujoEosinofilo from "../../assets/dibujo-eosinofilo-frases.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalQuestionInstructions } from "../../utils/logger";

function Pregunta3Explicacion({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [intentosRestantes, setIntentosRestantes] = useState(4);

	const CORRECT_POINTS = 200;
	const EXTRA_POINTS = 100;
	const WRONG_POINTS = 100;

	let instructionsHtmml = `<div>
			<p class="txt16">
				¿Qué está pasando en esta imagen? Ordena las frases para obtener
				la descripción de este proceso y pulsa
				<span class="bold">COMPROBAR</span>
			</p>
			<p>
				<span class="text-red bold">4 INTENTOS </span>
			</p>
		</div>`;

	useEffect(() => {
		modalQuestionInstructions({
			instruccionsTextHtml: instructionsHtmml,
			questionNum: getScenarioByScreen(location.pathname),
		});
	}, []);

	return (
		<>
			<div className="h-100 bgprueba">
				<div className="container">
					<div className="row h-100 d-flex align-items-center">
						<div className="col-11">
							<div className="d-flex justify-content-center align-items-center">
								<Image
									src={dibujoEosinofilo}
									alt="eosinofilo"
									width={"90%"}
								></Image>
							</div>
						</div>
						<div className="col-1">
							<div className="d-flex flex-column align-items-end">
								<LifeIndicatorBar
									numIntentos={intentosRestantes}
								></LifeIndicatorBar>
							</div>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{ position: "absolute", bottom: "10vh" }}
					>
						<div className="d-flex align-items-end justify-content-between mt-2">
							<div>
								<div className="txt12">
									<div>
										<div className="d-flex align-items-center">
											<div className="bg-points-info me-1">
												+{CORRECT_POINTS}
											</div>
											puntos
										</div>
									</div>
									<div>
										<div className="d-flex align-items-center mt-2">
											<div className="bg-extra-points-info me-1">
												+{EXTRA_POINTS}
											</div>
											puntos extra por resolver en el
											primer intento
										</div>
									</div>
									<div>
										<div className="d-flex align-items-center mt-2">
											<div className="bg-wrong-points-info me-1">
												- {WRONG_POINTS}
											</div>
											puntos
										</div>
									</div>
								</div>
							</div>
							<div>
								<NextButton navigateTo={ROUTES.P3}></NextButton>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta3Explicacion);
