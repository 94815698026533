import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logov3.svg";
import star from "../assets/ranking/star.png";
import opt1 from "../assets/top/opt1.svg";
import opt2 from "../assets/top/opt2.svg";
import opt3 from "../assets/top/opt3.svg";
import { useTimer } from "../hooks/useTimer";
import { updateScore, updateTime } from "../redux/actions";
import {
	EXTRA_TIME,
	LONG_GAME_DURATION,
	NO_TIME_PROGRESS_SCREENS,
	ROUTES,
	SCORE_TYPES,
	SHORT_GAME_DURATION,
} from "../utils/constants";
import {
	formatMinutes,
	formatScoreTopBar,
	formatSeconds,
	getTimer,
	updateStats,
	updateTimer,
} from "../utils/helpers";
import { modalPDF } from "../utils/logger";

const HIDE_TIMER_SCREENS = [ROUTES.FINAL_SCORE, ROUTES.RESULTS, ROUTES.RANKING];

//screens that don't have top bar but the timer must continue running
const HIDE_TOP_BAR_SCREENS = [
	ROUTES.P1_INTRO,
	ROUTES.P2_INTRO,
	ROUTES.P3_INTRO,
	ROUTES.P4_INTRO,
	ROUTES.P5_INTRO,
	ROUTES.P6_INTRO,
	ROUTES.P7_INTRO,
];

export default function TopBar() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const participant = useSelector((state) => state?.group);

	const game = useSelector((state) => state?.game) || participant?.game;
	const location = useLocation();

	const score = useSelector((state) => state.group?.score);

	const [time, setTime] = useState(parseInt(participant?.time));

	const [timerActive, setTimerActive] = useState(false);

	const gameDuration = game?.longMode
		? LONG_GAME_DURATION * 60
		: SHORT_GAME_DURATION * 60;

	const extraTimeDuration = EXTRA_TIME * 60;

	const [timeout, setTimeout] = useState(gameDuration);

	const showTopBar = !HIDE_TOP_BAR_SCREENS.some(
		(x) => location.pathname.toString() === x
	);

	useEffect(() => {
		if (!timerActive) setTime(parseInt(participant?.time));
	}, [participant?.time]);

	useTimer(() => {
		if (!timerActive && getTimer()) setTimerActive(true);
		if (timerActive) handleTimer();
	}, 1000);

	const handleTimer = () => {
		if (!getTimer()) setTimerActive(false);
		else {
			if (
				!NO_TIME_PROGRESS_SCREENS.includes(location.pathname) &&
				!participant?.finished
			) {
				setTime(time + 1);
				updateTimer(time);
			}
		}
	};

	useEffect(() => {
		if (time === timeout) {
			if (!participant?.finished) {
				dispatch(updateTime(time));

				if (timeout !== gameDuration + extraTimeDuration) {
					setTimeout(timeout + extraTimeDuration);

					let timePenalty =
						participant?.score > 0
							? Math.abs(Math.round(0.25 * participant?.score))
							: 0;

					let newScore = participant?.score - timePenalty;

					dispatch(updateScore(newScore, SCORE_TYPES.SCORE));
					dispatch(updateScore(-timePenalty, SCORE_TYPES.PENALTY));

					updateStats({
						...participant,
						score: newScore,
						penalty: participant?.penalty - timePenalty,
						time: time,
					});
				} else {
					updateStats({
						...participant,
						time: time,
						finished: true,
						lastVisited: ROUTES.RESULTS,
					});
					navigate(ROUTES.RESULTS);
				}
			}
		}
	}, [time]);

	return (
		<div className={`${showTopBar ? "bg-white py-3" : "d-none"}`}>
			<div className="container">
				<div className="d-flex justify-content-between align-items-center text-white">
					<div>
						<img src={logo} alt="gsk" className="img-fluid mw170" />
					</div>
					<div className="d-flex justify-content-between align-items-center">
						<div className="container-score bold mx-4">
							<img
								style={{ paddingTop: "3px" }}
								src={star}
								alt="star"
								width={15}
							/>
							{formatScoreTopBar(score)}
						</div>
						<div
							className="roundBtn mx-1 cursor-pointer"
							onClick={() => {
								modalPDF();
							}}
						>
							<img src={opt1} alt="star" />
						</div>
						<div
							className="roundBtn mx-1 cursor-pointer"
							onClick={() => {
								modalPDF();
							}}
						>
							<img src={opt2} alt="star" />
						</div>
						<div
							className="roundBtn mx-1 cursor-pointer"
							onClick={() => {
								modalPDF();
							}}
						>
							<img src={opt3} alt="star" />
						</div>
						{!HIDE_TIMER_SCREENS.includes(location.pathname) && (
							<>
								<div className="mx-2"></div>
								<div className="d-flex align-items-center">
									<div
										className={`timer ${
											timeout ===
											gameDuration + extraTimeDuration
												? "extra"
												: ""
										} bold`}
									>
										{formatMinutes(
											time,
											timeout,
											participant?.finished
										)}
									</div>
									<div
										className={`px-1 ${
											timerActive ? "blink" : ""
										} ${
											timeout ===
											gameDuration + extraTimeDuration
												? "text-red"
												: "text-black"
										} bold`}
									>
										:
									</div>
									<div
										className={`timer ${
											timeout ===
											gameDuration + extraTimeDuration
												? "extra"
												: ""
										} bold`}
									>
										{formatSeconds(
											time,
											timeout,
											participant?.finished
										)}
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

{
	/* <a href="/videocontextoc1" style="bottom: 200px;position: absolute;right: 100px;" class="btn-trap">¡EMPEZAR!</a> */
}
