import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { postGroupParticipant } from "../api/participant";
import logoGSK from "../assets/logo-gsk.svg";
import logo from "../assets/logov2.svg";
import addIcon from "../assets/register/add-icon.svg";
import icofoto from "../assets/register/ico-foto.svg";
import removeIcon from "../assets/register/remove-icon.svg";
import { setGame, setGroup } from "../redux/actions";
import { useLang } from "../translations/i18n";
import { LONG_GAME_DURATION, ROUTES } from "../utils/constants";
import { formatCode, setParticipantId, updateStats } from "../utils/helpers";
import { alertError, alertSuccess } from "../utils/logger";

function getEmptyGroup(code, hospital, longMode) {
	return {
		name: "",
		game: null,
		image: "",
		members: [],
		individual: false,
		hospital: hospital,
		sessionCode: code,
		longMode: longMode,
	};
}

function Register({ intl }) {
	const [activeGame, setActiveGame] = useState(null);
	const [actualMember, setActualMember] = useState("");
	const [members, setMembers] = useState([]);
	const [selectedImage, setSelectedImage] = useState(null);
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();

	const gameSessionCode = prevState?.state?.codigoPartida || null;
	const hospital = prevState?.state?.hospital || null;
	const longMode =
		prevState?.state?.duration == null
			? null
			: prevState?.state?.duration === LONG_GAME_DURATION;

	const [activeGroup, setActiveGroup] = useState(
		getEmptyGroup(gameSessionCode, hospital, longMode)
	);

	function saveGroup(e) {
		e.preventDefault();
		if (!activeGroup.name) {
			alertError({
				error: " ",
				customMessage: "El nombre del equipo es necesario",
			});
			return;
		}
		activeGroup.members = members;

		postGroupParticipant(activeGroup, selectedImage)
			.then((res) => {
				if (res.status === 201) {
					let resGroup = res.data;

					setActiveGroup(getEmptyGroup());
					alertSuccess({
						title: "!Su registro se ha completado con éxito!",
					}).then((res) => {
						resGroup = {
							...resGroup,
							score: 0,
							time: 0,
							penalty: 0,
							bonus: 0,
						};
						setParticipantId(resGroup?._id);
						dispatch(setGroup(resGroup));
						dispatch(setGame(resGroup?.game));

						updateStats({
							...resGroup,
							lastVisited: ROUTES.VIDEO,
						});
						navigate(ROUTES.VIDEO);
					});
				}
			})
			.catch((error) => {
				alertError({
					error: " ",
					customMessage: error?.response?.data?.message || " ",
				});
			});
	}

	const handleAddMember = () => {
		if (actualMember.trim().indexOf(" ") != -1) {
			setMembers([...members, actualMember]);
			setActualMember("");
		} else {
			alertError({
				error: " ",
				customMessage:
					"Cada participante debe tener nombre y apellido!",
			});
		}
	};

	const handleChange = (element) => (event) => {
		setActiveGroup({ ...activeGroup, [element]: event.target.value });
	};

	function renderMembers() {
		return members.map((name, index) => (
			<div
				key={index}
				className="d-flex justify-content-between align-items-center mt-3 px-2"
			>
				<div className="textYellow px-4">{name}</div>
				<img
					src={removeIcon}
					alt=""
					className="btn-remove d-flex align-items-center justify-content-center"
					onClick={() =>
						setMembers(members.filter((member) => member != name))
					}
				/>
			</div>
		));
	}

	return (
		<div className="pt-4 h-100">
			<div className="container d-flex justify-content-between legalGebro pb-3">
				<div>
					<img
						src={logo}
						alt="MTxperience"
						className="img-fluid mw170"
					/>
				</div>
				<div>
					<img
						src={logoGSK}
						alt="20 years Gebro Pharma"
						className="img-fluid"
					/>
				</div>
			</div>
			<div className="container bgBlue h-100">
				<div className="row pt-4">
					<div className="col-6 text-white">
						<div className="bold">{"Haz una foto de equipo"}</div>
						<div className="txt13 my-2">(opcional)</div>
						<div className="fotoButton mt-3 image-upload text-center bg-transparent">
							<label htmlFor={"file-input"}>
								<img
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: icofoto
									}
									alt="Haz una foto de equipo"
									className={`img-fluid cursor-pointer icofoto ${
										selectedImage ? "icofoto-selected" : ""
									}`}
								/>
							</label>
							<input
								id={"file-input"}
								name={"file-input"}
								type="file"
								accept="image/*"
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
						</div>
					</div>
					<div className="col-6 text-white">
						<div className="title1 mb-1">
							<span className="bold">
								{"Añade los participantes"}
							</span>{" "}
							<span className="txt13">(opcional)</span>
						</div>
						<div className="container-add position-relative">
							<input
								id={`name`}
								autoComplete="off"
								InputLabelProps={{
									shrink: true,
								}}
								value={actualMember}
								onChange={(event) => {
									setActualMember(event.target.value);
								}}
								placeholder="Escribe el nombre y apellido"
								required
								className="form-control input-add"
							/>
							<img
								src={addIcon}
								alt=""
								className="btn-add"
								onClick={handleAddMember}
							/>
						</div>
						<div
							className="row col-6 w-100"
							style={{ maxHeight: "26vh", overflow: "auto" }}
						>
							{renderMembers()}
						</div>
					</div>
				</div>
				<div className="row mt-5">
					<div className="col-6">
						<div className="text-white mb-1">
							<span className="bold">
								{"Pon un nombre a tu equipo"}
							</span>{" "}
							<span className="txt13">
								(máximo 15 caracteres)
							</span>
						</div>
						<input
							id={`name`}
							autoComplete="off"
							InputLabelProps={{
								shrink: true,
							}}
							maxLength={15}
							onChange={handleChange("name")}
							placeholder="Escribe el nombre"
							required
							className="form-control"
						/>
					</div>
					<div className="col-6">
						<div className="text-white mb-1 bold">
							{"El código de esta partida es"}
						</div>
						<input
							id={`code`}
							autoComplete="off"
							InputLabelProps={{
								shrink: true,
							}}
							value={
								gameSessionCode
									? formatCode(gameSessionCode)
									: ""
							}
							maxLength={15}
							required
							readOnly
							className="form-control text-center"
						/>
					</div>
				</div>
				<div className="row mt-5">
					<div className="col-12 d-flex justify-content-end">
						<Link
							to={ROUTES.VIDEO}
							className="btn-registro"
							onClick={saveGroup}
						>
							{"¡A JUGAR!"}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Register);
