import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndParticipant, postAnswer } from "../../api/answer";
import bgExtraInfoClick from "../../assets/bg-extra-click.png";
import flechas from "../../assets/flechas.svg";
import iconPreguntaExtra from "../../assets/icono-pregunta-extra.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import TrueOrFalseQuestion from "../../components/TrueOrFalseQuestion";
import { useTimer } from "../../hooks/useTimer";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertQuizAlreadyAnswered,
	modalAnswerExplanationTrueFalseQuestion,
	modalBiblioReferences,
} from "../../utils/logger";

function Pregunta1ExtraVF({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const [answerChecked, setAnswerChecked] = useState(false);

	const [intentosRestantes, setIntentosRestantes] = useState(1);

	const currentQuestionNum =
		location.pathname === ROUTES.P1_EXTRA_VF2 ? 2 : 1;

	const CORRECT_POINTS = 10;
	const WRONG_POINTS = 10;
	const EXTRA_POINTS = 25;

	const SCENARIO_ID = SCENARIOS.SCENARIO_1;
	const QUIZ_ID =
		currentQuestionNum === 2
			? QUIZZES.SCENARIO1_VF_2
			: QUIZZES.SCENARIO1_VF_1;
	const QUIZ_ID_EXTRA = QUIZZES.SCENARIO1_CURIOSIDAD_2;

	const NAVIGATE_TO =
		currentQuestionNum === 2 ? ROUTES.P1_SUPERADA : ROUTES.P1_EXTRA_VF2;

	const NAVIGATE_TO_EXTRA = ROUTES.P1_EXTRA_VF_EXTRA;

	const questions = [
		{
			num: 1,
			value: "La IL5 es la principal citocina responsable del crecimiento, diferenciación, reclutamiento, activación y supervivencia de los eosinófilos.",
			correctOption: true,
			references: `<ol class="numbered-list">
					<li>
						<span class="reference-text">
							Pelaia C, Paoletti G, Puggioni F, et al.
							<span class="regular">
								Interleukin-5 in the Pathophysiology of Severe
								Asthma. Front Physiol. 2019;10:1514
							</span>
						</span>
					</li>
				</ol>`,
		},
		{
			num: 2,
			value: "Mepolizumab reduce de media los niveles de eosinófilos a 0.",
			correctOption: false,
			references: `<ol class="numbered-list">
					<li>
						<span class="reference-text">
							Pelaia C, Paoletti G, Puggioni F, et al.
							<span class="regular">
								Interleukin-5 in the Pathophysiology of Severe
								Asthma. Front Physiol. 2019;10:1514
							</span>
						</span>
					</li>
				</ol>`,
		},
	];
	const currentQuestion = questions.find((x) => x.num === currentQuestionNum);

	const [optionClicked, setOptionClicked] = useState(null);

	let info = `<div>
			<p class="bold">${currentQuestion?.value}</p>
			${
				currentQuestionNum === 2
					? `<p>
				Mepolizumab normaliza los niveles de los eosinófilos<sup>1</sup>
				. Benralizumab, al <span class="bold">eliminar</span> los
				eosinófilos de la médula ósea, los
				<span class="bold">elimina</span> de la sangre periférica<sup>2</sup>
			</p>`
					: ""
			}
		</div>`;

	const [time, setTime] = useState(0);

	useEffect(() => {
		getAnswerByQuizAndParticipant(QUIZ_ID, participant?._id)
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					alertQuizAlreadyAnswered({}).then((res) => {});
					setAnswerChecked(true);
				}
			})
			.catch((error) => {});
	}, [location]);

	useTimer(
		() => {
			setTime(time + 1);
		},
		answerChecked ? null : 1000
	);

	async function submitAnswerExtra() {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID_EXTRA,
			participant: participant?._id,
			score: EXTRA_POINTS,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					dispatch(updateScore(EXTRA_POINTS, SCORE_TYPES.SCORE));
					dispatch(updateScore(EXTRA_POINTS, SCORE_TYPES.BONUS));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + EXTRA_POINTS,
						bonus: participant?.bonus + EXTRA_POINTS,
						lastVisited: NAVIGATE_TO_EXTRA,
					});
				}
			})
			.catch((error) => {});
	}

	async function submitAnswer({ correct, score, penalty }) {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					let penaltyScore = penalty || 0;
					dispatch(updateScore(score, SCORE_TYPES.SCORE));
					dispatch(
						updateScore(
							-Math.abs(penaltyScore),
							SCORE_TYPES.PENALTY
						)
					);

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						penalty: participant?.penalty - Math.abs(penaltyScore),
						lastVisited: NAVIGATE_TO,
					});
					setAnswerChecked(true);
				}
			})
			.catch((error) => {});
	}

	function checkAnswers() {
		const correct = optionClicked === currentQuestion?.correctOption;

		modalAnswerExplanationTrueFalseQuestion({
			points: correct ? CORRECT_POINTS : WRONG_POINTS,
			info: info,
			correct: correct,
			result: currentQuestion?.correctOption,
		});

		submitAnswer({
			correct: correct,
			score: correct ? CORRECT_POINTS : -WRONG_POINTS,
			penalty: correct ? 0 : WRONG_POINTS,
		});
	}

	return (
		<div className="h-100 bg-pregunta-extra">
			<div className="container">
				<div className="row h-100">
					<div className="col-2">
						{currentQuestion?.num === 2 && answerChecked && (
							<img
								className="grow-animation"
								style={{ cursor: "pointer" }}
								src={bgExtraInfoClick}
								width={125}
								onClick={async () => {
									await submitAnswerExtra();
									navigate(NAVIGATE_TO_EXTRA);
								}}
							></img>
						)}
					</div>
					<div className="col-9 pt-5">
						<div className="d-flex flex-column justify-content-center align-items-center">
							<div className="d-flex align-items-start">
								<Image
									src={iconPreguntaExtra}
									width={52}
								></Image>
								<div className="ms-3">
									<div className="txt26 bold text-red">
										PRUEBA PUNTOS EXTRA
									</div>
									<div className="txt16 bold">
										¿Sabrías decir si las siguientes
										afirmaciones son verdaderas o falsas?
									</div>
								</div>
							</div>
						</div>
						<br />
						<div className="d-flex flex-column justify-content-center align-items-center mt-5">
							<TrueOrFalseQuestion
								question={currentQuestion?.value}
								questionNumber={currentQuestion?.num}
								onOptionClicked={(option) =>
									setOptionClicked(option)
								}
								clickedOption={optionClicked}
								answerChecked={answerChecked}
								totalQuestions={questions.length}
							></TrueOrFalseQuestion>
						</div>
					</div>
					<div className="col-1">
						<div className="d-flex flex-column align-items-end">
							<LifeIndicatorBar
								numIntentos={intentosRestantes}
							></LifeIndicatorBar>
						</div>
					</div>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{ position: "absolute", bottom: "10%" }}
				>
					<div className="d-flex align-items-end justify-content-between">
						<div>
							<div className="d-flex align-items-center txt12">
								<div className="bg-points-info me-1">
									+{CORRECT_POINTS}
								</div>
								puntos
							</div>
							<div className="d-flex align-items-center mt-2 txt12">
								<div className="bg-wrong-points-info me-1">
									-{WRONG_POINTS}
								</div>
								puntos
							</div>
							<div
								className="txt12 underline text-red cursor-pointer mt-4"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml:
											currentQuestion?.references,
									});
								}}
							>
								Ver referencias
							</div>
						</div>
						<div>
							{!answerChecked ? (
								<button
									onClick={() => {
										checkAnswers();
									}}
									className="btn-iniciar p-2 px-4"
								>
									COMPROBAR
									<img className="ms-2" src={flechas}></img>
								</button>
							) : (
								<div
									onClick={() => {
										setOptionClicked(null);
										setAnswerChecked(false);
										setTime(0);
									}}
								>
									<NextButton
										navigateTo={NAVIGATE_TO}
									></NextButton>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta1ExtraVF);
