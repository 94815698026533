import { API, baseClient } from "../index";

export const getDefaultGame = async () => {
	return baseClient().get(`${API}/game/default/`);
};

export const getGameById = async (id) => {
	return baseClient().get(`${API}/game/${id}`);
};

export const generateGameSessionCode = async () => {
	return baseClient().get(`${API}/game/generate-session-code`);
};

export const getGameBySessionCode = async (code) => {
	return baseClient().get(`${API}/game/by-session-code/${code}`);
};
