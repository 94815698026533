import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logov3.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";

function IntroPregunta5({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [scenarioBarExpanded, setScenarioBarExpanded] = useState(true);

	return (
		<>
			<div className="pt-4 h-100 bg-intro-prueba">
				<div className="container d-flex justify-content-between legalGebro pb-3">
					<div>
						<img src={logo} alt="gsk" className="img-fluid mw170" />
					</div>
				</div>
				<div
					className={`container bg-maria-scenario1-intro ${
						!scenarioBarExpanded ? "expanded" : ""
					} h-100`}
				>
					<div className="row pt-4">
						<div className="col-7 py-2">
							<div className="row d-flex align-items-center title">
								<div className="col-1">
									<div className="question-num-bg">5</div>
								</div>
								<div className="col-10 ms-4">
									<span>
										Funciones de la IL-5 en el epitelio
									</span>
								</div>
							</div>
							<br />
							<div className="paciente-historial-text">
								<p>
									Hemos visto como María presenta una prueba
									broncodilatadora positiva, así como clínica
									de sibilancias nocturnas.{" "}
									<span className="bold">
										¿Cómo se podría explicar esta
										hiperreactividad bronquial indirecta?
									</span>
								</p>
							</div>
							<br />
							<div
								style={{ position: "absolute", bottom: "30%" }}
							>
								<NextButton navigateTo={ROUTES.P5}></NextButton>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
					expanded={true}
					setScenarioBarExpanded={setScenarioBarExpanded}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(IntroPregunta5);
