import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndParticipant, postAnswer } from "../../api/answer";
import dibujoMujer from "../../assets/dibujo-mujer.png";
import flechas from "../../assets/flechas.svg";
import iconoEscribir from "../../assets/icono-escribir.svg";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertError,
	alertQuizAlreadyAnswered,
	modalQuestionInstructions,
} from "../../utils/logger";

function Pregunta2({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const [intentosRestantes, setIntentosRestantes] = useState(1);

	const CORRECT_POINTS = 10;
	const SCENARIO_ID = SCENARIOS.SCENARIO_2;
	const QUIZ_ID = QUIZZES.SCENARIO2_ESCRIBIR;
	const NAVIGATE_TO = ROUTES.P2_SOLUTION;

	const [enteredValues, setEnteredValues] = useState([
		{ index: 1, value: "" },
		{ index: 2, value: "" },
		{ index: 3, value: "" },
		{ index: 4, value: "" },
		{ index: 5, value: "" },
		{ index: 6, value: "" },
		{ index: 7, value: "" },
		{ index: 8, value: "" },
		{ index: 9, value: "" },
		{ index: 10, value: "" },
	]);

	let instructionsHtml = `<div class="txt16">
			<p>
				¿Cuántas enfermedades causadas por los eosinófilos conoces?
			</p>
			<p>
				<span class="bg-points-info" style="border-radius: 16px;">+ 10 puntos</span>
			</p>
			<p>
				por enfermedad
			</p>
		</div>`;

	useEffect(() => {
		getAnswerByQuizAndParticipant(QUIZ_ID, participant?._id)
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					alertQuizAlreadyAnswered({}).then((res) => {});
					setAnswerChecked(true);
				}
			})
			.catch((error) => {
				modalQuestionInstructions({
					instruccionsTextHtml: instructionsHtml,
					questionNum: getScenarioByScreen(location.pathname),
				});
			});
	}, [location]);

	const handleInputChange = (index, newValue) => {
		const updatedValues = enteredValues.map((item) =>
			item.index === index ? { ...item, value: newValue } : item
		);
		setEnteredValues(updatedValues);
	};

	const [answerChecked, setAnswerChecked] = useState(false);

	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		answerChecked ? null : 1000
	);

	async function submitAnswer({ correct, score }) {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					dispatch(updateScore(score, SCORE_TYPES.SCORE));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						lastVisited: NAVIGATE_TO,
					});
					setAnswerChecked(true);
				}
			})
			.catch((error) => {});
	}

	async function checkAnswers() {
		setAnswerChecked(true);
		let correctOptions = enteredValues.filter(
			(x) => x.value.trim().length > 0
		);
		if (correctOptions.length === 0) {
			alertError({
				customMessage: "Debes escribir almenos una enfermedad",
			});
			return;
		}
		await submitAnswer({
			correct: true,
			score: correctOptions.length * CORRECT_POINTS,
		});
		navigate(NAVIGATE_TO);
	}

	return (
		<>
			<div className="h-100 bgprueba">
				<div className="container">
					<div className="row h-100">
						<div className="col-6">
							{enteredValues.map((item) => {
								return (
									<div className="d-flex align-items-center input-sentence-container py-1 px-2 mt-2">
										<div className="txt18 text-red bold me-1">
											{item.index}.
										</div>
										<div
											className="input-container"
											style={{
												position: "relative",
												width: "100%",
											}}
										>
											<input
												type="text"
												value={item.value}
												onChange={(e) =>
													handleInputChange(
														item.index,
														e.target.value
													)
												}
											/>
										</div>
									</div>
								);
							})}
						</div>
						<div className="col-5 d-flex justify-content-center align-items-center">
							<img src={dibujoMujer} height={450}></img>
						</div>
						<div className="col-1">
							<div className="d-flex flex-column align-items-end">
								<LifeIndicatorBar
									numIntentos={intentosRestantes}
								></LifeIndicatorBar>
							</div>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{ position: "absolute", bottom: "6vh" }}
					>
						<div className="d-flex align-items-end justify-content-between">
							<div>
								<div className="txt12">
									<div>
										<div className="d-flex align-items-center">
											<div className="bg-points-info me-1">
												+{CORRECT_POINTS}
											</div>
											puntos por enfermedad
										</div>
									</div>
									<div className="d-flex align-items-center mt-2">
										<img
											src={iconoEscribir}
											alt="escribe"
											width={40}
										></img>
										<div>
											Escribe el texto en la casilla
											correspondiente.
										</div>
									</div>
								</div>
							</div>
							<div>
								<button
									onClick={() => {
										checkAnswers();
									}}
									className="btn-iniciar p-2 px-4"
								>
									COMPROBAR
									<img className="ms-2" src={flechas}></img>
								</button>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta2);
