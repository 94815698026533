export const SET_GAME = "SET_GAME";
export const SET_GROUP = "SET_GROUP";
export const SET_GAME_DURATION = "SET_GAME_DURATION";
export const UPDATE_SCORE = "UPDATE_SCORE";
export const UPDATE_LIFE = "UPDATE_LIFE";
export const UPDATE_TIME = "UPDATE_TIME";
export const RESET_STATS = "RESET_STATS";
export const SUM_SCORES = "SUM_SCORES";

export function setGame(game) {
	return {
		type: SET_GAME,
		game,
	};
}
export function setGroup(group) {
	return {
		type: SET_GROUP,
		group,
	};
}
export function setGameDuration(gameDuration) {
	return {
		type: SET_GAME_DURATION,
		gameDuration,
	};
}
export function updateScore(score, typeOfScore) {
	return {
		type: UPDATE_SCORE,
		score,
		typeOfScore,
	};
}
export function updateLife(life) {
	return {
		type: UPDATE_LIFE,
		life,
	};
}
export function sumScores() {
	return {
		type: SUM_SCORES,
	};
}
export function updateTime(time) {
	return {
		type: UPDATE_TIME,
		time,
	};
}
export function resetStats() {
	return {
		type: RESET_STATS,
	};
}
