import {
	DndContext,
	MouseSensor,
	TouchSensor,
	closestCenter,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import {
	SortableContext,
	arrayMove,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndParticipant, postAnswer } from "../../api/answer";
import iconAmpliar from "../../assets/ampliar-foto.png";
import iconArrastrar from "../../assets/arrastrar.png";
import detalleEosinofilo from "../../assets/detalle-eosinofilo.png";
import dibujoEosinofilo from "../../assets/dibujo-eosinofilo-frases.png";
import flechas from "../../assets/flechas.svg";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getParticipantId,
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertQuizAlreadyAnswered,
	modalCorrectIncorrectAnswer,
	modalImage,
} from "../../utils/logger";

function Pregunta3({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const [intentosRestantes, setIntentosRestantes] = useState(4);
	const [checkedAnswers, setCheckedAnswers] = useState(false);

	const touchSensor = useSensor(TouchSensor);
	const mouseSensor = useSensor(MouseSensor);

	const sensors = useSensors(touchSensor, mouseSensor);

	const CORRECT_POINTS = 200;
	const EXTRA_POINTS = 100;
	const WRONG_POINTS = 100;

	const SCENARIO_ID = SCENARIOS.SCENARIO_3;
	const QUIZ_ID = QUIZZES.SCENARIO3_ORDENAR;
	const NAVIGATE_TO = ROUTES.P3_SOLUTION;

	const [sentences, setSentences] = useState([
		{
			id: 1,
			value: "Las células dendríticas captan el antígeno y lo presentan a los linfocitos T naïve, que se activan para dar lugar a células Th2 y células B productoras de IgE.",
			correctOrder: 3,
		},
		{
			id: 2,
			value: "La IL5 provoca la activación de los eosinófilos. Esta activación induce la liberación de diferentes mediadores, entre ellos la Galectina-10. La galectina 10 es una de las proteínas citoplasmáticas más abundantes del eosinófilo y es la responsable de la formación de los CLC.",
			correctOrder: 0,
		},
		{
			id: 3,
			value: "En el asma, los CLC activan los inflamasomas de las células dendríticas y de los macrófagos de la mucosa respiratoria.",
			correctOrder: 2,
		},
		{
			id: 4,
			value: "Estos cristales están implicados en procesos de reparación y defensa frente a parásitos, pero también se asocian a enfermedades como el asma. Estos cristales están presentes en las mucosas de las vías respiratorias bronquiales y nasosinusales.",
			correctOrder: 1,
		},
		{
			id: 5,
			value: "Esto explicaría porqué mepolizumab, en el estudio español en vida real REDES, redujo la tasa de exacerbaciones en un 80 % en pacientes con asma alérgica (n = 193) y un 76% en pacientes con asma no alérgica (n = 125).",
			correctOrder: 5,
		},
		{
			id: 6,
			value: "Por lo tanto, la IL5 también participa en la fase de sensibilización de la inflamación T2 alérgica.",
			correctOrder: 4,
		},
	]);

	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		checkedAnswers ? null : 1000
	);

	useEffect(() => {
		getAnswerByQuizAndParticipant(QUIZ_ID, getParticipantId())
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					alertQuizAlreadyAnswered({}).then((res) => {});
					setCheckedAnswers(true);
				}
			})
			.catch((error) => {});
	}, [location]);

	async function submitAnswer({ correct, score, bonus, penalty }) {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					let bonusScore = bonus || 0;
					let penaltyScore = penalty || 0;

					dispatch(updateScore(score, SCORE_TYPES.SCORE));
					dispatch(updateScore(bonusScore, SCORE_TYPES.BONUS));
					dispatch(
						updateScore(
							-Math.abs(penaltyScore),
							SCORE_TYPES.PENALTY
						)
					);

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						bonus: participant?.bonus + Math.abs(bonusScore),
						penalty: participant?.penalty - Math.abs(penaltyScore),
						lastVisited: NAVIGATE_TO,
					});
					setCheckedAnswers(true);
				}
			})
			.catch((error) => {});
	}

	function checkAnswers() {
		let wrongSentences = sentences.filter(
			(x, index) => index !== x.correctOrder
		);

		let correct = wrongSentences.length === 0;
		let submitAnswers = correct || intentosRestantes === 1;
		const finalScore = correct
			? CORRECT_POINTS + (intentosRestantes === 4 ? EXTRA_POINTS : 0)
			: WRONG_POINTS;

		const bonusScore = correct ? EXTRA_POINTS : 0;
		const penaltyScore = correct ? 0 : WRONG_POINTS;

		let textHtml =
			correct && intentosRestantes === 4
				? `<p class="txt16">
					Has reconstruido correctamente el proceso al primer intento.
				</p>`
				: correct
				? `<p class="txt16">Has reconstruido correctamente el proceso.</p>`
				: !correct && intentosRestantes !== 1
				? `<div class="txt16">
					<p>
						Hay
						<span class="bold text-red">
							${wrongSentences.length} frases
						</span>
						mal colocadas
					</p>
					<p>Fíjate bien y prueba de nuevo.</p>
					<p>
						Te quedan
						<span class="text-red bold">
							${intentosRestantes - 1} intentos.
						</span>
					</p>
				</div>`
				: `<p class="txt16">
					En la siguiente pantalla te mostramos cuál es el orden
					correcto de las frases.
				</p>`;

		modalCorrectIncorrectAnswer({
			title:
				correct && intentosRestantes === 4
					? "¡Fantástico!"
					: correct
					? "¡Ahora sí!"
					: "No es correcto",
			points: finalScore,
			textHtml: textHtml,
			correct: correct,
			allowBack: !submitAnswers,
		}).then((res) => {
			if (res.isConfirmed && submitAnswers) navigate(NAVIGATE_TO);
		});

		if (submitAnswers)
			submitAnswer({
				correct: correct,
				score: correct ? finalScore : -finalScore,
				bonus: bonusScore,
				penalty: penaltyScore,
			});

		setIntentosRestantes(intentosRestantes - 1);
	}

	const SortableItem = (props) => {
		const { attributes, listeners, setNodeRef, transform, transition } =
			useSortable({ id: props.id });

		const style = {
			transform: CSS.Translate.toString(transform),
			transition,
		};
		const sentence = sentences?.find((x) => x.id === props.id);
		const index = sentences.findIndex((item) => item.id === props.id);
		return (
			<div ref={setNodeRef} style={style} {...attributes} {...listeners}>
				<div
					className={`txt14 sentence-bg p-1 ${
						index > 0 ? "mt-2" : ""
					}`}
					style={{
						cursor: "grab",
					}}
				>
					{sentence.value}
				</div>
			</div>
		);
	};

	const handleDragEnd = ({ active, over }) => {
		if (active?.id !== over?.id) {
			setSentences((items) => {
				const activeIndex = items.findIndex(
					(item) => item.id === active.id
				);
				const overIndex = items.findIndex(
					(item) => item.id === over.id
				);

				if (activeIndex !== -1 && overIndex !== -1) {
					return arrayMove(items, activeIndex, overIndex);
				}

				return items;
			});
		}
	};

	return (
		<>
			<div className="h-100 bgprueba">
				<div className="container">
					<div className="row h-100">
						<div className="col-2">
							<div className="d-flex align-items-center">
								<Image
									src={detalleEosinofilo}
									width={76}
								></Image>
								<Image
									style={{
										marginLeft: "-10%",
										cursor: "pointer",
									}}
									onClick={() => {
										modalImage({
											image: dibujoEosinofilo,
										});
									}}
									src={iconAmpliar}
									width={90}
								></Image>
							</div>
						</div>
						<div className="col-9">
							<div className="d-flex flex-column justify-content-center align-items-center">
								<div>
									<DndContext
										collisionDetection={closestCenter}
										onDragEnd={handleDragEnd}
										sensors={sensors}
										modifiers={[restrictToWindowEdges]}
									>
										<SortableContext
											items={sentences}
											strategy={
												verticalListSortingStrategy
											}
										>
											{sentences?.map(
												(sentence, index) => {
													return (
														<SortableItem
															key={sentence.id}
															id={sentence.id}
														></SortableItem>
													);
												}
											)}
										</SortableContext>
									</DndContext>
								</div>
							</div>
						</div>
						<div className="col-1">
							<div className="d-flex flex-column align-items-end">
								<LifeIndicatorBar
									numIntentos={intentosRestantes}
								></LifeIndicatorBar>
							</div>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{ position: "absolute", bottom: "10vh" }}
					>
						<div className="d-flex align-items-end justify-content-between">
							<div>
								<div className="txt12">
									<div>
										<div className="d-flex align-items-center">
											<div className="bg-points-info me-1">
												+{CORRECT_POINTS}
											</div>
											puntos
										</div>
									</div>
									<div>
										<div className="d-flex align-items-center mt-2">
											<div className="bg-extra-points-info me-1">
												+{EXTRA_POINTS}
											</div>
											puntos extra por resolver en el
											primer intento
										</div>
									</div>
									<div>
										<div className="d-flex align-items-center mt-2">
											<div className="bg-wrong-points-info me-1">
												- {WRONG_POINTS}
											</div>
											puntos
										</div>
									</div>
									<div>
										<div className="d-flex align-items-center mt-2">
											<Image
												className="me-1"
												src={iconArrastrar}
											></Image>
											Arrastra cada etiqueta a su lugar
											correspondiente.
										</div>
									</div>
								</div>
							</div>
							<div>
								{!checkedAnswers ? (
									<button
										onClick={() => {
											checkAnswers();
										}}
										className="btn-iniciar p-2 px-4"
									>
										COMPROBAR
										<img
											className="ms-2"
											src={flechas}
										></img>
									</button>
								) : (
									<NextButton
										navigateTo={NAVIGATE_TO}
									></NextButton>
								)}
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta3);
