import { combineReducers } from "redux";
import { SCORE_TYPES } from "../utils/constants";
import {
	RESET_STATS,
	SET_GAME,
	SET_GAME_DURATION,
	SET_GROUP,
	SUM_SCORES,
	UPDATE_LIFE,
	UPDATE_SCORE,
	UPDATE_TIME,
} from "./actions";

const initialState = {
	game: null,
	group: null,
	gameDuration: null,
};

function game(state = initialState.game, action) {
	switch (action.type) {
		case SET_GAME:
			return action.game;
		default:
			return state;
	}
}

function gameDuration(state = initialState.gameDuration, action) {
	switch (action.type) {
		case SET_GAME_DURATION:
			return action.gameDuration;
		default:
			return state;
	}
}

function group(state = initialState.group, action) {
	switch (action.type) {
		case SET_GROUP:
			return action.group;
		case RESET_STATS:
			let newStats = {
				...state,
				score: 0,
				penalty: 0,
				bonus: 0,
				time: 0,
			};
			return newStats;
		case SUM_SCORES:
			let newScores = {
				...state,
				score: state?.scoreCase1 + state?.scoreCase2,
			};
			return newScores;
		case UPDATE_SCORE:
			let newScore =
				action.typeOfScore === SCORE_TYPES.SCORE
					? 0 || state.score + action.score
					: state.score;

			let newBonus =
				action.typeOfScore === SCORE_TYPES.BONUS
					? 0 || state.bonus + action.score
					: state.bonus;

			let newPenalty =
				action.typeOfScore === SCORE_TYPES.PENALTY
					? 0 || state.penalty + action.score
					: state.penalty;

			let newState = {
				...state,
				score: newScore,
				bonus: newBonus,
				penalty: newPenalty,
			};

			return newState;

		case UPDATE_LIFE:
			let newLife = action.life;

			let newStateLife = {
				...state,
				life: newLife,
			};

			return newStateLife;
		case UPDATE_TIME:
			let newState2 = {
				...state,
				time: action.time,
			};
			return newState2;
		default:
			return state;
	}
}

export default combineReducers({ game, group, gameDuration });
