import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import espiral from "../assets/espiral.svg";
import NextButton from "../components/NextButton";
import { ROUTES } from "../utils/constants";

function FinesJuego({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="pt-4 h-100">
			<div className="container h-100">
				<div className="row d-flex justify-content-center align-items-center h-100">
					<div className="col-8 d-flex align-items-center text-center text-white">
						<div>
							<img
								className="mb-5"
								src={espiral}
								alt="GSK"
								width={"20%"}
							/>
							<p className="bold txt26">
								Personajes y caso clínico ficticio únicamente
								con fines ilustrativos.
							</p>
							<p className="txt20 mt-4">
								Le recordamos que se encuentra en un entorno
								científico dirigido a profesionales de la salud
								y que el contenido no es apto para público
								general. Por ello le rogamos que tenga esto en
								consideración en los mensajes que pueda incluir
								en las Redes Sociales relacionados con esta
								reunión.
							</p>
						</div>
					</div>
				</div>
				<div
					className="container"
					style={{ position: "absolute", bottom: "8vh", right: "28%", zIndex: "99" }}
				>
					<div className="d-flex justify-content-end">
						<div>
							<NextButton navigateTo={ROUTES.END_QR}></NextButton>
						</div>
					</div>
				</div>

				<div
					className="container"
					style={{ position: "absolute", bottom: "8vh" }}
				>
					<div className="d-flex justify-content-end">
						<div className="d-flex flex-column align-items-end">
							<div className="d-flex justify-content-end">
								<div
									className="txt11 text-white"
									style={{
										marginRight: "-50%",
										marginBottom: "35vh",
										whiteSpace: "nowrap",
										transform: "rotate(270deg)",
									}}
								>
									Presentación para uso en reuniones
									organizadas por GSK
								</div>
							</div>
							<div className="text-white txt11 mt-4">
								PM-ES-MPL-EDTL-230005 (v1) 01/2024
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(FinesJuego);
