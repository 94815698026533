import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndParticipant, postAnswer } from "../../api/answer";
import dibujoEosinofilo from "../../assets/dibujo-eosinofilo.png";
import flechas from "../../assets/flechas.svg";
import iconAmpliarInfo from "../../assets/icono-ampliar-info.png";
import iconoAtencion from "../../assets/icono-atencion.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getParticipantId,
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertError,
	alertQuizAlreadyAnswered,
	modalBiblioReferences,
	modalCorrectIncorrectAnswer,
	modalImage,
	modalQuestionInstructions,
} from "../../utils/logger";

function Pregunta2Part2({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const [intentosRestantes, setIntentosRestantes] = useState(4);
	const [answerChecked, setAnswerChecked] = useState(false);

	const CORRECT_POINTS = 100;
	const EXTRA_POINTS = 100;

	const SCENARIO_ID = SCENARIOS.SCENARIO_2;
	const QUIZ_ID = QUIZZES.SCENARIO2_SELECCIONAR;
	const NAVIGATE_TO = ROUTES.P2_EXTRA;

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Klion AD, Ackerman SJ, Bochner BS.
					<span class="regular">
						Contributions of Eosinophils to Human Health and
						Disease. Annu Rev Pathol. 2020;15:179-209.
					</span>
				</span>
			</li>
		</ol>`;

	const instructionsHtml = `<div class="txt16">
			<p class="txt16">
				Entre las siguientes etiquetas, hay
				<span class="bold">
					5 funciones homeostáticas del eosinófilo
				</span>
				demostradas en modelos animales <i>(Klion et al. (2020))</i>.
				¿Sabrías identificarlas?
			</p>
			<p class="bold text-red">
				<span>4 INTENTOS</span>
			</p>
		</div>`;

	const [tags, setTags] = useState([
		{
			id: 1,
			value: "Homeostasis reproductiva y desarrollo de las glándulas mamarias",
			selected: false,
		},
		{
			id: 2,
			value: "Efectos antitumorales",
			selected: false,
		},
		{
			id: 3,
			value: "Mejora de la memoria",
			selected: false,
		},
		{
			id: 4,
			value: "Regulación del sistema inmunitario (inmunidad innata y adaptativa)",
			selected: false,
		},
		{
			id: 5,
			value: "Contribución a la digestión de alimentos",
			selected: false,
		},
		{
			id: 6,
			value: "Eliminación de toxinas que se acumulan en el cuerpo",
			selected: false,
		},
		{
			id: 7,
			value: "Fagocitosis",
			selected: false,
		},
		{
			id: 8,
			value: "Regeneración y remodelación de tejidos",
			selected: false,
		},
		{
			id: 9,
			value: "Aumento de la producción de plaquetas",
			selected: false,
		},
		{
			id: 10,
			value: "Defensa frente a infecciones parasitarias, fúngicas, bacterianas y víricas",
			selected: false,
		},
	]);

	const correctOptions = [4, 2, 1, 10, 8];

	useEffect(() => {
		getAnswerByQuizAndParticipant(QUIZ_ID, getParticipantId())
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					alertQuizAlreadyAnswered({}).then((res) => {});
					setAnswerChecked(true);
				}
			})
			.catch((error) => {
				modalQuestionInstructions({
					instruccionsTextHtml: instructionsHtml,
					questionNum: getScenarioByScreen(location.pathname),
				});
			});
	}, []);

	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		answerChecked ? null : 1000
	);

	async function submitAnswer({ correct, score, bonus }) {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					let bonusScore = bonus || 0;
					dispatch(updateScore(score, SCORE_TYPES.SCORE));
					dispatch(updateScore(bonusScore, SCORE_TYPES.BONUS));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						bonus: participant?.bonus + Math.abs(bonusScore),
						lastVisited: NAVIGATE_TO,
					});
					setAnswerChecked(true);
				}
			})
			.catch((error) => {});
	}

	function checkAnswers() {
		let selectedTags = tags.filter((x) => x.selected === true);

		let correct =
			selectedTags?.length === correctOptions.length &&
			!selectedTags
				.map((x) => x.id)
				.some((x) => !correctOptions.includes(x));

		const finalScore =
			correct && intentosRestantes === 4
				? CORRECT_POINTS + EXTRA_POINTS
				: correct
				? CORRECT_POINTS
				: 0;

		const bonusScore = intentosRestantes === 4 ? EXTRA_POINTS : 0;

		if (selectedTags.length !== correctOptions.length) {
			alertError({
				customMessage: "Debe seleccionar 5 funciones",
			});
			return;
		} else if (!correct) {
			let text = `<p class="txt16">
						Observa cuáles son estas 5 funciones homeostáticas del
						eosinófilo en la siguiente pantalla.
					</p>`;
			modalCorrectIncorrectAnswer({
				title: "No es correcto",
				textHtml: text,
				correct: false,
			}).then((res) => {
				if (res.isConfirmed) setAnswerChecked(true);
			});
		} else {
			let text = `<p class="txt16">
					Has localizado correctamente las 5 funciones homeostáticas
					del eosinófilo
				</p>`;

			modalCorrectIncorrectAnswer({
				title: intentosRestantes === 4 ? "¡Fantástico!" : "¡Ahora sí!",
				points: finalScore,
				textHtml: text,
				correct: true,
			});
		}

		submitAnswer({
			correct: correct,
			score: finalScore,
			bonus: bonusScore,
		});

		setIntentosRestantes(intentosRestantes - 1);
	}

	const handleSelectTag = (tagId) => {
		let correct = correctOptions.includes(tagId);
		let updatedTags;

		if (correct)
			updatedTags = tags.map((tag) =>
				tag.id === tagId ? { ...tag, selected: !tag.selected } : tag
			);
		else {
			updatedTags = tags.map((tag) => ({ ...tag, selected: false }));
			if (intentosRestantes === 1) {
				let text = `<p class="txt16">
						Observa cuáles son estas 5 funciones homeostáticas del
						eosinófilo en la siguiente pantalla.
					</p>`;
				modalCorrectIncorrectAnswer({
					title: "No es correcto",
					textHtml: text,
					correct: false,
				});
				submitAnswer({ correct: false, score: 0 });
			}
			setIntentosRestantes(intentosRestantes - 1);
		}

		setTags(updatedTags);
	};

	const TagBox = ({ tagId }) => {
		const tag = tags.find((x) => x.id === tagId);
		const selected =
			(!answerChecked && tag.selected) ||
			(answerChecked && correctOptions.includes(tagId));

		return (
			<div
				key={tag.id}
				className={`tag-box-solved txt12 w-fit-content p-2 mx-3 ${
					selected
						? "tag-box-selected"
						: "bg-transparent cursor-pointer"
				}`}
				style={{ maxWidth: "300px" }}
				onClick={() => handleSelectTag(tag.id)}
			>
				<span>{tag.value}</span>
			</div>
		);
	};

	return (
		<div
			className={`h-100 ${
				answerChecked ? "bg-pregunta-extra" : "bgprueba"
			}`}
		>
			<div className="container">
				<div className="row h-100">
					<div className="col-10">
						{answerChecked && (
							<div>
								Las{" "}
								<span className="bold">
									5 funciones homeostáticas
								</span>{" "}
								del eosinófilo demostradas en modelos animales
								están marcadas en verde.
							</div>
						)}
						<div className="d-flex align-items-center justify-content-center mt-5">
							<TagBox tagId={1}></TagBox>
							<TagBox tagId={2}></TagBox>
							<TagBox tagId={3}></TagBox>
						</div>
						<div className="d-flex align-items-center justify-content-center mt-4">
							<TagBox tagId={4}></TagBox>
							<TagBox tagId={5}></TagBox>
							<TagBox tagId={6}></TagBox>
						</div>
						<div className="d-flex align-items-center justify-content-center mt-4">
							<TagBox tagId={7}></TagBox>
							<TagBox tagId={8}></TagBox>
							<TagBox tagId={9}></TagBox>
						</div>
						<div className="d-flex align-items-center justify-content-center mt-4">
							<TagBox tagId={10}></TagBox>
						</div>
						{answerChecked && (
							<div className="d-flex justify-content-center mt-4">
								<img
									src={iconAmpliarInfo}
									width={144}
									style={{ cursor: "pointer" }}
									onClick={() => {
										modalImage({
											image: dibujoEosinofilo,
										});
									}}
								></img>
							</div>
						)}
					</div>
					<div className="col-2">
						<div className="d-flex flex-column align-items-end">
							<LifeIndicatorBar
								numIntentos={intentosRestantes}
							></LifeIndicatorBar>
						</div>
					</div>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{
						position: "absolute",
						bottom: "10%",
						width: "100%",
					}}
				>
					<div className="d-flex align-items-end justify-content-between">
						<div>
							<div className="txt12">
								{!answerChecked && (
									<>
										<div>
											<div className="d-flex align-items-center">
												<div className="bg-points-info me-1">
													+{CORRECT_POINTS}
												</div>
												puntos
											</div>
											<div className="d-flex align-items-center mt-2">
												<div className="bg-extra-points-info me-1">
													+{EXTRA_POINTS}
												</div>
												puntos extras por resolver en el
												primer intento
											</div>
										</div>
										<div className="d-flex align-items-center mt-2">
											<img
												src={iconoAtencion}
												alt="atencion"
												width={46}
												className="me-1"
											></img>
											<div>
												Si marcas una etiqueta mal,
												tendrás que empezar de cero.
											</div>
										</div>
									</>
								)}
								<div
									className="txt12 underline text-red cursor-pointer mt-4"
									onClick={(e) => {
										e.stopPropagation();
										modalBiblioReferences({
											referencesHtml: REFERENCES,
										});
									}}
								>
									Ver referencias
								</div>
							</div>
						</div>
						<div>
							{!answerChecked ? (
								<button
									onClick={() => {
										checkAnswers();
									}}
									className="btn-iniciar p-2 px-4"
								>
									COMPROBAR
									<img className="ms-2" src={flechas}></img>
								</button>
							) : (
								<NextButton
									navigateTo={NAVIGATE_TO}
								></NextButton>
							)}
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta2Part2);
