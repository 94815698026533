import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logov3.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalBiblioReferences } from "../../utils/logger";

function IntroPregunta1({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [scenarioBarExpanded, setScenarioBarExpanded] = useState(true);

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Hammad H, Lambrecht BN.
					<span class="regular">
						The basic immunology of asthma. Cell.
						2021;184(6):1469-85.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text mt-1">
					Lambrecht BN, Hammad H.
					<span class="regular">
						The immunology of asthma. Nature immunology.
						2015;16(1):45-56.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text mt-1">
					Pelaia C, Paoletti G, Puggioni F, et al.
					<span class="regular">
						Interleukin-5 in the Pathophysiology of Severe Asthma.
						Front Physiol. 2019;10:1514.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text mt-1">
					Barretto KT, Brockman Schneider RA, Kuipers I, et al.
					<span class="regular">
						Human airway epithelial cells express a functional IL-5
						receptor. Allergy. 2020 Aug;75(8):2127-2130.
					</span>
				</span>
			</li>
		</ol>`;

	return (
		<>
			<div className="pt-4 h-100 bg-intro-prueba">
				<div className="container d-flex justify-content-between legalGebro pb-3">
					<div>
						<img src={logo} alt="gsk" className="img-fluid mw170" />
					</div>
				</div>
				<div
					className={`container bg-maria-scenario1-intro ${
						!scenarioBarExpanded ? "expanded" : ""
					} h-100`}
				>
					<div className="row pt-4">
						<div className="col-8 py-2">
							<h4 className="title d-flex align-items-center">
								<div className="question-num-bg">1</div>
								La inflamación T2
							</h4>
							<div className="txt18-bold mt-4">
								<strong>
									Busquemos las posibles causas del
									empeoramiento de María.
								</strong>
							</div>
							<br />
							<div
								className="paciente-historial-text"
								style={{ maxWidth: "88%" }}
							>
								<p>
									Una de ellas puede ser el{" "}
									<span className="bold">
										aumento de la inflamación T2
									</span>
									. ¿Conoces su proceso subyacente? Puede
									estar implicada la{" "}
									<span className="bold">
										inmunidad adaptativa
									</span>{" "}
									o la{" "}
									<span className="bold">
										activación de la respuesta inmune innata
									</span>{" "}
									entre otros factores, quizás con cierto
									grado de{" "}
									<span className="bold">remodelación</span>
									<sup>1-4</sup>.
								</p>
							</div>
							<br />
							<div className="mt-2">
								<NextButton navigateTo={ROUTES.P1}></NextButton>
							</div>
							<div
								className="txt12 underline text-red cursor-pointer"
								style={{ position: "absolute", bottom: "20vh" }}
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
					expanded={true}
					setScenarioBarExpanded={setScenarioBarExpanded}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(IntroPregunta1);
