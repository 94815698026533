import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import { getParticipantById } from "./api/participant";
import TopBar from "./components/TopBar";
import "./customStyles/modals.css";
import { ROUTES } from "./utils/constants";
import { clearTimer, getParticipantId, reconnect } from "./utils/helpers";

const NO_TOP_BAR_SCREENS = [
	ROUTES.HOME,
	ROUTES.HOME_SHORT,
	ROUTES.HOME_LONG,
	ROUTES.INDIVIDUAL_GRUPAL,
	ROUTES.SHORT_OR_LONG,
	ROUTES.REGISTER_INDIVIDUAL,
	ROUTES.REGISTER_GROUP,
	ROUTES.HISTORIAL,
	ROUTES.FINES_JUEGO,
	ROUTES.END_QR,
];

function App() {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const participant = useSelector((state) => state?.group);

	const [loading, setLoading] = useState(
		getParticipantId() &&
			![ROUTES.HOME, ROUTES.HOME_SHORT, ROUTES.HOME_LONG].includes(
				location.pathname
			)
	);

	useEffect(() => {
		const handleBeforeUnload = (event) => {
			const confirmationMessage =
				"¿Estás seguro de que quieres abandonar la página? Podrías perder información no guardada.";
			event.returnValue = confirmationMessage;
			return confirmationMessage;
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		let participantId = getParticipantId();

		if (participantId) {
			if (
				![ROUTES.HOME, ROUTES.HOME_SHORT, ROUTES.HOME_LONG].includes(
					location.pathname
				)
			) {
				setLoading(true);
				getParticipantById(participantId)
					.then((res) => {
						if (res.status === 200) {
							reconnect({
								participant: res.data,
								dispatch: dispatch,
								navigate: navigate,
							});
							setTimeout(() => {
								setLoading(false);
							}, 100);
						}
					})
					.catch((error) => {});
			}
		} else {
			clearTimer();
		}
	}, []); //TODO: if user is not allowed to go back, add location.pathname dependency

	const showTopBar = !NO_TOP_BAR_SCREENS.some(
		(x) => location.pathname.toString() === x
	);

	return loading ? (
		<>
			<div className="splash-screen">{showTopBar && <TopBar />}</div>
		</>
	) : (
		<div className="App">
			{showTopBar && <TopBar />}
			<Outlet />
		</div>
	);
}
export default App;
