import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../api";
import { getParticipantsByGame } from "../api/participant";
import groupPlaceholder from "../assets/group-profile-placeholder.png";
import logoGSK from "../assets/gsk.svg";
import logoVivactis from "../assets/logo-vivactis.png";
import starYellow from "../assets/ranking/star.png";
import NextButton from "../components/NextButton";
import { useLang } from "../translations/i18n";
import { ROUTES } from "../utils/constants";

function Ranking({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [data, setData] = useState([]);

	const participant = useSelector((state) => state?.group);
	const game =
		useSelector((state) => state.game)?._id || participant?.game?._id;

	useEffect(() => {
		if (participant && participant?.individual) {
			navigate(ROUTES.RESULTS);
			return;
		}
		const asyncFetch = async () => {
			getParticipantsByGame(game)
				.then((res) => {
					if (res.status === 200) {
						setData(res.data);
					}
				})
				.catch((error) => {});
		};
		asyncFetch();
	}, [game]);

	return (
		<div className="h-100 bg-results">
			<div className="container-fluid h-100">
				<div className="row text-center pt-4 justify-content-center">
					<div className="txt28 bold gradient-text pt-2">RANKING</div>{" "}
				</div>
				<div className="row text-center mt-3 d-flex justify-content-center">
					<div className="col-8 d-flex flex-column align-items-center">
						{data?.map((team, index) => {
							return (
								<div
									className={`row bg-ranking-item ${
										team?._id === participant?._id
											? "current-team"
											: ""
									} w-100 py-2 px-4 d-flex align-items-center ${
										index > 0 ? "mt-2" : ""
									}`}
								>
									<div className="col-2 d-flex align-items-center justify-content-between pe-4">
										<div className="bold text-red txt24">
											{index + 1}
										</div>
										<Image
											src={
												team?.image
													? `${SERVER_URL}/${team?.image}`
													: groupPlaceholder
											}
											roundedCircle
											width={54}
											height={54}
											style={{ objectFit: "cover" }}
										></Image>
									</div>
									<div className="col-4 d-flex justify-content-center">
										<div className="txt16 bold">
											{team?.game?.hospital}
										</div>
									</div>
									<div className="col-4 d-flex justify-content-center">
										<div className="txt16">
											{team?.name}
										</div>
									</div>
									<div className="col-2 d-flex align-items-center justify-content-between">
										<div>
											<img
												src={starYellow}
												width={15}
											></img>
										</div>
										<div>
											<span className="bold me-1">
												{team?.score}
											</span>{" "}
											puntos
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div
				className="w-100 d-flex legalGebro position-absolute justify-content-center align-items-start"
				style={{ bottom: "5%" }}
			>
				<div className="d-flex flex-column align-items-center">
					<div className="mb-4 text-black txt12">Organizado por:</div>
					<img src={logoGSK} alt="GSK" />
				</div>
				<div className="d-flex flex-column align-items-center ms-5">
					<div className="mb-2 text-black txt12">
						Desarrollado por:
					</div>
					<img
						src={logoVivactis}
						alt="Vivactis Euromedice"
						width={110}
					/>
				</div>
			</div>
			<div
				className="container"
				style={{ position: "absolute", bottom: "8vh", right: "20%" }}
			>
				<div className="d-flex justify-content-end">
					<NextButton navigateTo={ROUTES.FINES_JUEGO}></NextButton>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Ranking);
