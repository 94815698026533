import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import iconAmpliar from "../../assets/ampliar-foto.png";
import iconoDudaRojo from "../../assets/icono-duda-rojo.png";
import pregunta6Ampliar from "../../assets/pregunta6-ampliar.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalBiblioReferences, modalImage } from "../../utils/logger";

function Pregunta6Ampliar({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [intentosRestantes, setIntentosRestantes] = useState(null);

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Howarth P, Chupp G, Nelsen LM, et al.
					<span class="regular">
						Severe eosinophilic asthma with nasal polyposis: A
						phenotype for improved sinonasal and asthma outcomes
						with mepolizumab therapy. J Allergy Clin Immunol.
						2020;145(6):1713-5.
					</span>
				</span>
			</li>
		</ol>`;

	return (
		<>
			<div className="h-100 bg-ampliar-info">
				<div className="container">
					<div className="row h-100 d-flex align-items-start">
						<div className="d-flex align-items-center">
							<img src={iconoDudaRojo} width={56}></img>
							<div className="ms-3">
								<div className="text-red bold">Has ganado</div>
								<div className="bg-points-info">+25 puntos</div>
							</div>
						</div>
						<div className="col-11">
							<div className="d-flex flex-column justify-content-center align-items-center">
								<div className="row d-flex align-items-start mt-4">
									<div className="col-4 d-flex justify-content-center mb-3">
										<p className="txt16">
											En pacientes como María, que aparte
											del asma presentan rinosinusitis
											crónica con pólipos nasales como
											comorbilidad, Nucala® aporta datos
											de{" "}
											<span className="bold">
												reducción de la tasa de
												exacerbaciones graves de hasta
												un 80 %
											</span>
											, así como una{" "}
											<span className="bold">
												reducción en el cuestionario
												SNOT-22 de poliposis.
											</span>
										</p>
									</div>
									<div className="col-8 d-flex flex-column align-items-center">
										<div
											style={{
												paddingLeft: "5em",
												paddingRight: "5em",
											}}
										>
											<div className="bold">
												Nucala® en el paciente comórbido
											</div>
											<div className="p-3 mt-2 d-flex justify-content-center align-items-center bg-white border-green">
												<Image
													src={pregunta6Ampliar}
													width={"100%"}
												></Image>
											</div>
										</div>
										<Image
											className="cursor-pointer"
											style={{ marginTop: "-10px" }}
											src={iconAmpliar}
											width={100}
											onClick={() =>
												modalImage({
													image: pregunta6Ampliar,
												})
											}
										></Image>
									</div>
								</div>
							</div>
						</div>
						<div className="col-1">
							<div className="d-flex flex-column align-items-end">
								<LifeIndicatorBar
									numIntentos={intentosRestantes}
								></LifeIndicatorBar>
							</div>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{ position: "absolute", bottom: "8vh" }}
					>
						<div className="d-flex align-items-end justify-content-between">
							<div
								className="txt12 underline text-red cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
							<div>
								<NextButton
									navigateTo={ROUTES.TRANSITION_SCENARIOS}
									navigateToAfter={ROUTES.P7_INTRO}
								></NextButton>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta6Ampliar);
