import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import iconAmpliar from "../../assets/icono-mas-info.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import {
	modalBiblioReferences,
	modalQuestion5ZoomOption,
} from "../../utils/logger";

function Pregunta7Solucion({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const MEJORA_ID = 1;
	const PRODUCCION_ID = 2;
	const EFICACIA_AGE_ID = 3;
	const AUMENTO_ID = 4;
	const EFICACIA_ALERGICA_ID = 5;
	const LIBERACION_ID = 6;
	const ACTIVACION_ID = 7;
	const INFLAMACION_ID = 8;
	const REDUCCION_ID = 9;

	const options = [
		{
			id: MEJORA_ID,
			value: (
				<div>
					Mejora en la función pulmonar y reducción del remodelado
					<span class="text-red">*</span>
				</div>
			),
			explanation: `<div>
					<div class="d-flex align-items-start justify-content-center">
						<div class="text-red bold txt26">*</div>
						<div class="ms-2">
							Mejora de la función pulmonar de hasta 340 ml de
							media en el FEV1 en estudios en vida real1.
						</div>
					</div>
				</div>`,
		},
		{
			id: PRODUCCION_ID,
			value: (
				<div>
					Producción de moco, aumento de su viscosidad y pérdida de la
					integridad epitelial
					<sup>5</sup>
				</div>
			),
		},
		{
			id: EFICACIA_AGE_ID,
			value: (
				<div>
					Eficacia en pacientes con AGE y PN concomitante
					<span class="text-red">*</span>
				</div>
			),
			explanation: `<div>
					<div class="d-flex align-items-start justify-content-center">
						<div class="text-red bold txt26">*</div>
						<div class="ms-2">
							En REDES, los pacientes con AGE y PN redujeron en un
							85% la media de exacerbaciones clínicamente
							relevantes4.
						</div>
					</div>
				</div>`,
		},
		{
			id: AUMENTO_ID,
			value: (
				<div>
					Aumento en la producción de moco e implicación en el
					remodelado tisular
					<sup>4</sup>
				</div>
			),
		},
		{
			id: EFICACIA_ALERGICA_ID,
			value: (
				<div>
					Eficacia en pacientes con sensibilización alérgica
					<span class="text-red">*</span>
				</div>
			),
			explanation: `<div>
					<div class="d-flex align-items-start justify-content-center">
						<div class="text-red bold txt26">*</div>
						<div class="ms-2">
							Reducción de la tasa de exacerbaciones en un 77 % en
							pacientes con asma grave y sensibilización
							alérgica3.
						</div>
					</div>
				</div>`,
		},
		{
			id: LIBERACION_ID,
			value: (
				<div>
					Liberación de gránulos específicos con proteínas catiónicas
					<sup>1</sup>
				</div>
			),
		},
		{
			id: ACTIVACION_ID,
			value: (
				<div>
					Activación de los inflamasomas de las células dendríticas y
					de los macrófagos que activan respuesta inmunitaria
					adaptativa
					<sup>3</sup>
				</div>
			),
		},
		{
			id: INFLAMACION_ID,
			value: (
				<div>
					Inflamación de las vías respiratorias, remodelado tisular y
					daño tisular
					<sup>2</sup>
				</div>
			),
		},
		{
			id: REDUCCION_ID,
			value: (
				<div>
					Reducción de exacerbaciones y uso de CO
					<span class="text-red">*</span>
				</div>
			),
			explanation: `<div>
					<div class="d-flex align-items-start justify-content-center">
						<div class="text-red bold txt26">*</div>
						<div class="ms-2">
							Reducción de la dosis de CO en un 77 %1. El 57% de
							los pacientes (95/168) abandonaron el uso de COs
							tras dos años de tratamiento2.
						</div>
					</div>
				</div>`,
		},
	];

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Pelaia C, Paoletti G, Puggioni F, et al.
					<span class="regular">
						Interleukin-5 in the Pathophysiology of Severe Asthma.
						Front Physiol. 2019;10:1514.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Acharya KR, Ackerman SJ.
					<span class="regular">
						Eosinophil granule proteins: form and function. J Biol
						Chem. 2014;289(25):17406-15.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Lambrecht BN, Galli SJ.
					<span class="regular">
						SnapShot: Integrated Type 2 Immune Responses. Immunity.
						2015;43(2):408-408.e1.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Lu Y, Huang Y, Li J, et al.
					<span class="regular">
						Eosinophil extracellular traps drive asthma progression
						through neuro-immune signals. Nat Cell Biol.
						2021;23(10):1060-72.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Barretto KT, Brockman-Schneider RA, Kuipers I, et al.
					<span class="regular">
						Human airway epithelial cells express a functional IL5
						receptor. Allergy. 2020;75(8):2127-30.
					</span>
				</span>
			</li>
		</ol>`;

	return (
		<div className="h-100 bg-pregunta-extra">
			<div className="container">
				<div className="row h-100 pt-5 d-flex align-items-center">
					<div className="col-11 d-flex justify-content-center align-items-center">
						<div className="w-100">
							<div className="d-flex align-items-center justify-content-end">
								<div className="bg-info-box txt12 p-3 w-25">
									Crecimiento, diferenciación, reclutamiento,
									activación y supervivencia de los
									eosinófilos
								</div>
								<div className="schema-line"></div>
								<div className="bg-tag-pink d-flex align-items-center justify-content-center p-1 txt12 w-50">
									{
										options.find(
											(x) => x.id === LIBERACION_ID
										)?.value
									}
								</div>
								<div className="bg-tag-pink d-flex align-items-center justify-content-center p-1 ms-2 txt12 w-50">
									{
										options.find(
											(x) => x.id === INFLAMACION_ID
										)?.value
									}
								</div>
								<div className="schema-line"></div>
								<div
									className="bg-info-box txt12 p-3 text-center w-25"
									style={{
										minWidth: "8%",
										maxWidth: "8%",
									}}
								>
									Nucala®
								</div>
								<div className="schema-line"></div>
								<div className="d-flex align-items-center w-50">
									<div className="bg-tag-pink d-flex align-items-center justify-content-start p-1 txt12">
										<div className="w-75">
											{
												options.find(
													(x) => x.id === REDUCCION_ID
												)?.value
											}
										</div>
									</div>
									<img
										className="cursor-pointer"
										style={{ marginLeft: "-10%" }}
										src={iconAmpliar}
										onClick={() =>
											modalQuestion5ZoomOption({
												text: options.find(
													(x) => x.id === REDUCCION_ID
												)?.explanation,
											})
										}
										width={34}
									></img>
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-end mt-2">
								<div>
									<div className="bg-info-box txt12 px-3 py-2">
										Galectina-10
									</div>
									<div className="bg-info-box txt12 px-3 py-2 mt-1">
										CLC
									</div>
								</div>
								<div className="mx-1">
									<div className="schema-line inclined-down"></div>
									<div className="schema-line inclined-up mt-3"></div>
								</div>
								<div className="bg-tag-pink d-flex align-items-center justify-content-center p-1 txt12 w-100">
									{
										options.find(
											(x) => x.id === ACTIVACION_ID
										)?.value
									}
								</div>
								<div className="schema-line"></div>
								<div
									className="bg-info-box txt12 p-3 text-center"
									style={{
										minWidth: "90px",
										maxWidth: "90px",
									}}
								>
									Nucala®
								</div>
								<div className="schema-line"></div>
								<div className="d-flex align-items-center w-50">
									<div className="bg-tag-pink d-flex align-items-center justify-content-start p-1 txt12">
										<div className="w-75">
											{
												options.find(
													(x) =>
														x.id ===
														EFICACIA_ALERGICA_ID
												)?.value
											}
										</div>
									</div>
									<img
										className="cursor-pointer"
										style={{ marginLeft: "-10%" }}
										src={iconAmpliar}
										onClick={() =>
											modalQuestion5ZoomOption({
												text: options.find(
													(x) =>
														x.id ===
														EFICACIA_ALERGICA_ID
												)?.explanation,
											})
										}
										width={34}
									></img>
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-end mt-2">
								<div className="bg-info-box txt12 px-3 py-2">
									Redes de ADN
								</div>
								<div className="schema-line"></div>
								<div className="bg-tag-pink d-flex align-items-center justify-content-center p-1 txt12">
									{
										options?.find(
											(x) => x.id === AUMENTO_ID
										)?.value
									}
								</div>
								<div className="schema-line"></div>
								<div
									className="bg-info-box txt12 p-3 text-center"
									style={{
										minWidth: "90px",
										maxWidth: "90px",
									}}
								>
									Nucala®
								</div>
								<div className="schema-line"></div>
								<div className="d-flex align-items-center w-50">
									<div className="bg-tag-pink d-flex align-items-center justify-content-start p-1 txt12">
										<div className="w-75">
											{
												options.find(
													(x) => x.id === MEJORA_ID
												)?.value
											}
										</div>
									</div>
									<img
										className="cursor-pointer"
										style={{ marginLeft: "-10%" }}
										src={iconAmpliar}
										onClick={() =>
											modalQuestion5ZoomOption({
												text: options.find(
													(x) => x.id === MEJORA_ID
												)?.explanation,
											})
										}
										width={34}
									></img>
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-end mt-2">
								<div>
									<div className="bg-info-box txt12 px-3 py-2">
										CLC
									</div>
									<div className="bg-info-box txt12 px-3 py-2 mt-1">
										Redes de ADN
									</div>
								</div>
								<div className="mx-1">
									<div className="schema-line inclined-down"></div>
									<div className="schema-line inclined-up mt-3"></div>
								</div>
								<div className="bg-tag-pink d-flex align-items-center justify-content-center p-1 txt12">
									{
										options?.find(
											(x) => x.id === PRODUCCION_ID
										)?.value
									}
								</div>
								<div className="schema-line"></div>
								<div
									className="bg-info-box txt12 p-3 text-center"
									style={{
										minWidth: "90px",
										maxWidth: "90px",
									}}
								>
									Nucala®
								</div>
								<div className="schema-line"></div>
								<div className="d-flex align-items-center w-50">
									<div className="bg-tag-pink d-flex align-items-center justify-content-start p-1 txt12">
										<div className="w-75">
											{
												options.find(
													(x) =>
														x.id === EFICACIA_AGE_ID
												)?.value
											}
										</div>
									</div>
									<img
										className="cursor-pointer"
										style={{ marginLeft: "-10%" }}
										src={iconAmpliar}
										onClick={() =>
											modalQuestion5ZoomOption({
												text: options.find(
													(x) =>
														x.id === EFICACIA_AGE_ID
												)?.explanation,
											})
										}
										width={34}
									></img>
								</div>
							</div>
						</div>
					</div>
					<div className="col-1">
						<div className="d-flex flex-column align-items-end">
							<LifeIndicatorBar
								numIntentos={null}
							></LifeIndicatorBar>
						</div>
					</div>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{ position: "absolute", bottom: "10vh" }}
				>
					<div className="d-flex align-items-end justify-content-between">
						<div
							className="txt12 underline text-red cursor-pointer"
							onClick={(e) => {
								e.stopPropagation();
								modalBiblioReferences({
									referencesHtml: REFERENCES,
								});
							}}
						>
							Ver referencias
						</div>
						<div>
							<NextButton
								navigateTo={ROUTES.P7_SUPERADA}
							></NextButton>
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta7Solucion);
