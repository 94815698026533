import {
	DndContext,
	MouseSensor,
	TouchSensor,
	useDraggable,
	useDroppable,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnswerByQuizAndParticipant, postAnswer } from "../../api/answer";
import iconArrastrar from "../../assets/arrastrar.png";
import detalleP5 from "../../assets/detalle-p5.png";
import flechaEsquema from "../../assets/flecha-esquema.svg";
import flechas from "../../assets/flechas.svg";
import fotoPregunta5Correcta from "../../assets/foto-pregunta5-correcta.png";
import graficoP5 from "../../assets/grafico-pregunta-5.png";
import iconAmpliar from "../../assets/icono-ampliar-info.png";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getParticipantId,
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertError,
	alertQuizAlreadyAnswered,
	modalBiblioReferences,
	modalCorrectIncorrectAnswer,
	modalImage,
	modalQuestionInstructions,
} from "../../utils/logger";

function Pregunta5({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const [checkedAnswers, setCheckedAnswers] = useState(false);

	const participant = useSelector((state) => state.group);

	const touchSensor = useSensor(TouchSensor);
	const mouseSensor = useSensor(MouseSensor);

	const sensors = useSensors(touchSensor, mouseSensor);

	const CORRECT_POINTS = 100;
	const WRONG_POINTS = 100;

	const SCENARIO_ID = SCENARIOS.SCENARIO_5;
	const QUIZ_ID = QUIZZES.SCENARIO5_ALGORITMO;
	const NAVIGATE_TO = ROUTES.P5_EXTRA;

	const IMAGE_REFERENCES = `<span class="bold">Fuente: Akdis CA.</span> Does the epithelial barrier hypothesis explain the increase in allergy, autoimmunity and other chronic conditions? Nat Rev Immunol. 2021;21(11):739-51.`;
	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Barretto KT, Brockman-Schneider RA, Kuipers I, et al.
					<span class="regular">
						Human airway epithelial cells express a functional IL-5
						receptor. Allergy. 2020;75(8):2127-30.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Akdis CA.
					<span class="regular">
						Does the epithelial barrier hypothesis explain the
						increase in allergy, autoimmunity and other chronic
						conditions? Nat Rev Immunol. 2021;21(11):739-51.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Wu CA, Peluso JJ, Zhu L, et al.
					<span class="regular">
						Bronchial epithelial cells produce IL-5: implications
						for local immune responses in the airways. Cell Immunol.
						2010;264(1):32-41.
					</span>
				</span>
			</li>
		</ol>`;

	const TAG_LIBERACION_ID = 1;
	const TAG_UNION_ID = 2;
	const TAG_ACTIVACION_ID = 3;
	const TAG_UNIONES_ID = 4;
	const TAG_DAÑO_ID = 5;

	const tagOptions = [
		{ id: TAG_LIBERACION_ID, value: "Liberación de IL5" },
		{
			id: TAG_UNION_ID,
			value: "Unión de la IL5 a las células del epitelio bronquial",
		},
		{
			id: TAG_ACTIVACION_ID,
			value: "Activación de la inmunidad T2 tanto alérgica como no alérgica ",
		},
		{
			id: TAG_UNIONES_ID,
			value: `<span>Las uniones epiteliales se debilitan y se produce una <span class="bold">pérdida de la integridad de la barrera epitelial</span></span>`,
		},
		{
			id: TAG_DAÑO_ID,
			value: `<span>Daño en la barrera epitelial que permite el paso de <span class="bold">agentes externos</span></span>`,
		},
	];

	const [tags, setTags] = useState(
		tagOptions.filter((x) => x.id !== TAG_LIBERACION_ID)
	);
	const [wrongOptions, setWrongOptions] = useState(null);

	const OFFSET = tagOptions.length; //necessary for a proper behaviour of drag & drop library

	const [boxes, setBoxes] = useState([
		{
			id: TAG_LIBERACION_ID + OFFSET,
			tag: tagOptions.find((x) => x.id === TAG_LIBERACION_ID),
			correctOptionsIds: [TAG_LIBERACION_ID],
		},
		{
			id: TAG_UNIONES_ID + OFFSET,
			tag: null,
			correctOptionsIds: [TAG_UNIONES_ID],
		},
		{
			id: TAG_ACTIVACION_ID + OFFSET,
			tag: null,
			correctOptionsIds: [TAG_ACTIVACION_ID],
		},
		{
			id: TAG_DAÑO_ID + OFFSET,
			tag: null,
			correctOptionsIds: [TAG_DAÑO_ID],
		},
		{
			id: TAG_UNION_ID + OFFSET,
			tag: null,
			correctOptionsIds: [TAG_UNION_ID],
		},
	]);

	const [intentosRestantes, setIntentosRestantes] = useState(1);

	let instructionsHtml = `<div>
			<p class="txt16">
				En este esquema del ciclo de la actividad de la IL5 sobre el
				epitelio, se han mezclado las etiquetas. ¿Podrías reubicarlas en
				el orden correcto? Arrastra la etiqueta hasta el emplazamiento
				adecuado y pulsa <span class="bold">COMPROBAR</span>
			</p>
			<p class="txt16 text-red bold">1 INTENTO</p>
		</div>`;

	useEffect(() => {
		getAnswerByQuizAndParticipant(QUIZ_ID, getParticipantId())
			.then((res) => {
				if (res.status === 200 && res.data.length > 0) {
					alertQuizAlreadyAnswered({}).then((res) => {});
					setCheckedAnswers(true);
				}
			})
			.catch((error) => {
				modalQuestionInstructions({
					instruccionsTextHtml: instructionsHtml,
					questionNum: getScenarioByScreen(location.pathname),
				});
			});
	}, [location]);

	const [time, setTime] = useState(0);

	useTimer(
		() => {
			setTime(time + 1);
		},
		checkedAnswers ? null : 1000
	);

	async function submitAnswer({ correct, score, penalty }) {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: score,
			time: time,
			correct: correct,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					let penaltyScore = penalty || 0;

					dispatch(updateScore(score, SCORE_TYPES.SCORE));
					dispatch(
						updateScore(
							-Math.abs(penaltyScore),
							SCORE_TYPES.PENALTY
						)
					);

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + score,
						penalty: participant?.penalty - Math.abs(penaltyScore),
						lastVisited: NAVIGATE_TO,
					});
					setCheckedAnswers(true);
				}
			})
			.catch((error) => {});
	}

	const TagBoxTarget = ({ boxId, tagId, defaultValue }) => {
		const box = boxes.find((x) => x.id === boxId + OFFSET);
		const tag = tags.find((x) => x.id === boxId);

		return box?.tag === null ? (
			<Droppable id={box?.id}>{tag?.value}</Droppable>
		) : (
			<Draggable id={box?.tag?.id}>{box?.tag?.value}</Draggable>
		);
	};

	const Draggable = (props) => {
		const { attributes, listeners, setNodeRef, transform } = useDraggable({
			id: props.id,
			disabled: false,
		});

		const style = {
			transform: CSS.Translate.toString(transform),
			lineHeight: "18px",
		};

		return (
			<div
				className={`tag-box-p5 p-2 txt12`}
				ref={setNodeRef}
				style={style}
				{...listeners}
				{...attributes}
				dangerouslySetInnerHTML={{ __html: props.children }}
			></div>
		);
	};

	const Droppable = (props) => {
		const { isOver, setNodeRef } = useDroppable({
			id: props.id,
			data: {
				origin: props.origin,
			},
		});

		const emptyTagOptionItem = props.origin;

		return (
			<>
				<div
					className={`tag-box-target-p5 txt12 p-2 ${
						emptyTagOptionItem ? "bg-transparent" : ""
					}`}
					style={{
						lineHeight: "18px",
					}}
					ref={setNodeRef}
					dangerouslySetInnerHTML={{ __html: props.children }}
				></div>
			</>
		);
	};

	const handleDragEnd = ({ active, over }) => {
		window.scrollTo(0, 0);
		if (!active || !over) return;

		const draggedTag = tagOptions.find((x) => x.id === active?.id);
		const targetBox = boxes.find((x) => x.id === over?.id);

		const boxId = boxes.find((x) => x.tag?.id === draggedTag?.id)?.id;

		const updatedBoxes = boxes.map((b) =>
			b.id === targetBox?.id
				? {
						...b,
						tag: draggedTag,
				  }
				: b.id === boxId
				? { ...b, tag: null }
				: b
		);

		setBoxes(updatedBoxes);

		let updatedTags;

		if (over?.data?.current?.origin) {
			updatedTags = tagOptions.filter(
				(x) => tags.some((y) => y.id === x.id) || x.id === draggedTag.id
			);
		} else {
			updatedTags = tags.filter((x) => x.id !== draggedTag.id);
		}

		setTags(updatedTags);
	};

	const TagBox = ({ tagId }) => {
		const tag = tagOptions.find((x) => x.id === tagId);
		const isDropped = !tags.some((x) => x?.id == tag?.id);

		return !isDropped ? (
			<Draggable id={tag?.id}>{tag?.value}</Draggable>
		) : (
			<Droppable id={tag?.id} origin={true}>
				{tag?.value}
			</Droppable>
		);
	};

	function checkAnswers() {
		if (boxes.some((x) => !x.tag)) {
			alertError({
				customMessage:
					"¡Todas las etiquetas deben ser colocadas antes de poder comprobar el resultado!",
			});
			return;
		}
		let normalizedBoxes = boxes.map((x) => ({ ...x, id: x.id - OFFSET }));
		let wrongOptions = normalizedBoxes.filter(
			(x) => !x.correctOptionsIds.includes(x.tag?.id)
		);

		let correct = wrongOptions.length === 0;
		let text =
			`<div class="txt16">
				<p class="txt16">
				${
					correct
						? "Has reconstruido correctamente el ciclo de la actividad de la IL5 sobre el epitelio."
						: `<span>Hay <span class="bold text-red">${wrongOptions.length} errores</span>.</span> Esta es la solución correcta:`
				}				
				</p>
				<img src=` +
			fotoPregunta5Correcta +
			` alt='GSK' width="450" />
			</div>`;

		modalCorrectIncorrectAnswer({
			title: correct ? "¡Fantástico!" : "No es correcto",
			textHtml: text,
			points: correct ? CORRECT_POINTS : WRONG_POINTS,
			correct: correct,
			loosedPoints: correct ? null : WRONG_POINTS,
		}).then((res) => {
			if (res.isConfirmed) {
				navigate(NAVIGATE_TO);
			}
		});

		submitAnswer({
			correct: correct,
			score: correct ? CORRECT_POINTS : -WRONG_POINTS,
			penalty: correct ? 0 : WRONG_POINTS,
		});
	}

	return (
		<div className="h-100 bgprueba">
			<div className="container">
				<div className="row h-100">
					<DndContext
						onDragEnd={handleDragEnd}
						sensors={sensors}
						modifiers={[restrictToWindowEdges]}
					>
						<div className="col-2">
							<div className="d-flex align-items-center">
								<Image src={detalleP5} width={76}></Image>
								<Image
									style={{
										marginLeft: "-10%",
										cursor: "pointer",
									}}
									onClick={() => {
										modalImage({
											image: graficoP5,
											imageWidth: "60%",
											references: IMAGE_REFERENCES,
										});
									}}
									src={iconAmpliar}
									width={90}
								></Image>
							</div>
						</div>
						<div className="col-9" style={{ zIndex: "10" }}>
							<div className="pb-2">
								<div className="d-flex justify-content-center">
									<TagBoxTarget
										boxId={TAG_LIBERACION_ID}
										defaultValue={"Liberacion"}
									></TagBoxTarget>
								</div>
								<div className="d-flex justify-content-around px-5">
									<img src={flechaEsquema} width={22} />
									<img
										src={flechaEsquema}
										width={22}
										style={{ transform: "rotate(90deg)" }}
									/>
								</div>
								<div className="d-flex justify-content-around mt-2">
									<TagBoxTarget
										boxId={TAG_ACTIVACION_ID}
										defaultValue={"Activacion"}
									></TagBoxTarget>
									<TagBoxTarget
										boxId={TAG_UNION_ID}
										defaultValue={"Unión"}
									></TagBoxTarget>
								</div>
								<div className="d-flex justify-content-around mt-2 px-5">
									<img
										src={flechaEsquema}
										width={22}
										style={{ transform: "rotate(-90deg)" }}
									/>
									<img
										src={flechaEsquema}
										width={22}
										style={{ transform: "rotate(180deg)" }}
									/>
								</div>
								<div className="d-flex justify-content-center mt-2">
									<TagBoxTarget
										boxId={TAG_DAÑO_ID}
										defaultValue={"Daño"}
									></TagBoxTarget>
									<img
										className="mx-5"
										src={flechaEsquema}
										width={22}
										style={{ transform: "rotate(230deg)" }}
									/>
									<TagBoxTarget
										boxId={TAG_UNIONES_ID}
										defaultValue={"Uniones"}
									></TagBoxTarget>
								</div>
								<div className="d-flex justify-content-center mt-5">
									<div className="d-flex flex-column align-items-center me-5 pe-2">
										<TagBox
											tagId={TAG_LIBERACION_ID}
										></TagBox>
										<div className="mt-2">
											<TagBox
												tagId={TAG_UNION_ID}
											></TagBox>
										</div>
										<div className="mt-2">
											<TagBox
												tagId={TAG_ACTIVACION_ID}
											></TagBox>
										</div>
									</div>
									<div className="d-flex flex-column align-items-center justify-content-start ms-5 ps-3">
										<TagBox tagId={TAG_UNIONES_ID}></TagBox>
										<div className="mt-2">
											<TagBox
												tagId={TAG_DAÑO_ID}
											></TagBox>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-1">
							<div className="d-flex flex-column align-items-end">
								<LifeIndicatorBar
									numIntentos={intentosRestantes}
								></LifeIndicatorBar>
							</div>
						</div>
					</DndContext>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{ position: "absolute", bottom: "10vh" }}
				>
					<div className="d-flex align-items-end justify-content-between mt-3">
						<div>
							<div className="d-flex align-items-center txt12">
								<div className="bg-points-info me-1">
									+{CORRECT_POINTS}
								</div>
								puntos
							</div>
							<div className="d-flex align-items-center mt-2 txt12">
								<div className="bg-wrong-points-info me-1">
									-{WRONG_POINTS}
								</div>
								puntos
							</div>
							<div className="d-flex align-items-center mt-3 txt12">
								<img
									src={iconArrastrar}
									alt="arrastra"
									className="me-2"
								></img>
								<div style={{ maxWidth: "50%" }}>
									Arrastra cada etiqueta a su lugar
									correspondiente.
								</div>
							</div>
							<div
								className="txt12 underline text-red cursor-pointer mt-3"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
						</div>
						<div style={{ zIndex: "99" }}>
							{!checkedAnswers ? (
								<button
									onClick={() => {
										checkAnswers();
									}}
									className="btn-iniciar p-2 px-4"
								>
									COMPROBAR
									<img className="ms-2" src={flechas}></img>
								</button>
							) : (
								<NextButton
									navigateTo={NAVIGATE_TO}
								></NextButton>
							)}
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta5);
