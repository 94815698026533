import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import iconAmpliar from "../../assets/ampliar-foto.png";
import detalleRedes from "../../assets/detalle-redes-adn.png";
import dibujoRedes from "../../assets/dibujo-redes-adn.jpg";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalBiblioReferences, modalImage } from "../../utils/logger";

function Pregunta4Solucion({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [intentosRestantes, setIntentosRestantes] = useState(4);

	const sentences = [
		`Los eosinófilos, activados por la IL5, pueden <span class="bold">liberar redes de ADN</span> en un proceso conocido como <span class="bold">EETosis</span>, el cual supone la muerte programada del propio eosinófilo<sup>1</sup>.`,
		`Las redes extracelulares de ADN <span class="bold">interaccionan</span> con las <span class="bold">células pulmonares neuroendocrinas</span>, unas células que se acumulan en las zonas de bifurcación bronquial y que están conectadas a varios tipos de fibras nerviosas sensoriales y motoras<sup>2,3</sup>.`,
		`La interacción entre las <span class="bold">células pulmonares neuroendocrinas</span> y las redes de ADN provoca la <span class="bold">liberación de neurotransmisores</span> como GABA o CGRP<sup>2</sup>.`,
		`Estos neurotransmisores aumentan la <span class="bold">secreción del moco</span> y provocan la <span class="bold">hiperplasia de las células caliciformes</span> (remodelado tisular)<sup>2</sup>.`,
		`Por lo tanto, la IL5, a través de su acción sobre los eosinófilos, aumenta la producción de moco y actúa en el <span class="bold">remodelado tisular</span><sup>1,4</sup>.`,
	];

	const IMAGE_REFERENCES = `<div>
			<span class="bold">TSLP:</span> linfopoyetina estromal tímica (por
			sus siglas en inglés) <span class="bold">EOS:</span> eosinófilos
			<span class="bold">CCDC25:</span> proteína que contiene el dominio
			de bobina enrollada – 25 (por sus siglas en inglés)
			<span class="bold">EPX:</span> proteína X eosinofílica
			<span class="bold">EETs:</span> trampas extracelulares de
			eosinófilos <span class="bold">CGRP:</span>
			péptido relacionado con el gen de la calcitonina
			<span class="bold">GABA:</span> ácido gamma-aminobutírico (por sus
			siglas en inglés)
		</div>`;

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Aegerter H, Smole U, Heyndrickx I, et al.
					<span class="regular">
						Charcot-Leyden crystals and other protein crystals
						driving type 2 immunity and allergy. Curr Opin Immunol.
						2021;72:72-8.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Lu Y, Huang Y, Li J, et al.
					<span class="regular">
						Eosinophil extracellular traps drive asthma progression
						through neuro-immune signals. Nat Cell Biol.
						2021;23(10):1060-72.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Noguchi M, Furukawa KT, Morimoto M.
					<span class="regular">
						Pulmonary neuroendocrine cells: physiology, tissue
						homeostasis and disease. Dis Model Mech.
						2020;13(12):dmm046920.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Acharya KR, Ackerman SJ.
					<span class="regular">
						Eosinophil granule proteins: form and function. J Biol
						Chem. 2014;289(25):17406-15.
					</span>
				</span>
			</li>
		</ol>`;

	return (
		<>
			<div className="h-100 bgprueba">
				<div className="container">
					<div className="row h-100">
						<div className="col-2">
							<div className="d-flex align-items-center">
								<Image src={detalleRedes} width={76}></Image>
								<Image
									style={{
										marginLeft: "-10%",
										cursor: "pointer",
									}}
									onClick={() => {
										modalImage({
											imageWidth: "60%",
											references: IMAGE_REFERENCES,
											image: dibujoRedes,
										});
									}}
									src={iconAmpliar}
									width={90}
								></Image>
							</div>
						</div>
						<div className="col-9">
							<div className="pt-5 pe-3">
								{sentences.map((sentence, index) => {
									return (
										<>
											<div className="d-flex align-items-start">
												<div className="text-red bold">
													{index + 1}.
												</div>
												<p
													className="txt16 ms-3"
													dangerouslySetInnerHTML={{
														__html: sentence,
													}}
												></p>
											</div>
										</>
									);
								})}
							</div>
						</div>
						<div className="col-1">
							<div className="d-flex flex-column align-items-end">
								<LifeIndicatorBar
									numIntentos={intentosRestantes}
								></LifeIndicatorBar>
							</div>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{ position: "absolute", bottom: "10%" }}
					>
						<div className="d-flex justify-content-between align-items-end">
							<div
								className="txt12 underline text-red cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
							<NextButton
								navigateTo={ROUTES.P4_EXTRA}
							></NextButton>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta4Solucion);
