import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function MultipleChoiceQuestion({
	options,
	correctOptionNumber,
	answerChecked,
	onOptionClicked,
	clickedOption,
}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<>
			<p className="txt16 bold">
				¿Cuál de las siguientes afirmaciones es correcta?
			</p>
			<ol class="numbered-list">
				{options.map((option, index) => {
					return (
						<li
							key={option.num}
							className={`${
								index !== 0 ? "mt-2" : ""
							} cursor-pointer ${
								clickedOption === option.num ? "bold" : ""
							} ${
								answerChecked &&
								correctOptionNumber === option.num
									? "text-green bold"
									: answerChecked &&
									  clickedOption === option.num &&
									  correctOptionNumber !== option.num
									? "text-red"
									: ""
							}`}
							onClick={() => {
								if (!answerChecked) {
									onOptionClicked(option.num);
								}
							}}
						>
							<span className={`regular txt16`}>
								{option.value}
							</span>
						</li>
					);
				})}
			</ol>
		</>
	);
}
export default MultipleChoiceQuestion;
