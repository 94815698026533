import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postAnswer } from "../../api/answer";
import iconoClickExtra from "../../assets/bg-extra-click-info-2.png";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { updateLife, updateScore, updateTime } from "../../redux/actions";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";

function Pregunta6Superada({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const participant = useSelector((state) => state.group);

	const EXTRA_POINTS = 25;

	const SCENARIO_ID = SCENARIOS.SCENARIO_6;
	const QUIZ_ID = QUIZZES.SCENARIO6_CURIOSIDAD;
	const NAVIGATE_TO_EXTRA = ROUTES.P6_AMPLIAR;

	async function submitAnswer() {
		let totalTime = getTimer();
		dispatch(updateTime(totalTime));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			participant: participant?._id,
			score: EXTRA_POINTS,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateLife(res.data?.participant?.life));

					dispatch(updateScore(EXTRA_POINTS, SCORE_TYPES.SCORE));
					dispatch(updateScore(EXTRA_POINTS, SCORE_TYPES.BONUS));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + EXTRA_POINTS,
						bonus: participant?.bonus + EXTRA_POINTS,
						lastVisited: NAVIGATE_TO_EXTRA,
					});
				}
			})
			.catch((error) => {});
	}

	return (
		<>
			<div className="pt-4 h-100 bg-fin-escenario escenario-6">
				<div className="container h-100">
					<div className="col-12 text-red txt50 bold">
						¡Sexta parte superada!
					</div>

					<div className="row mt-1">
						<div className="col-7 txt16 mt-2">
							<p>
								<span className="bold">
									¿Puede ser acertada la decisión de tratar a
									María con Nucala®?
								</span>
							</p>
							<p>
								Por todo lo que hemos visto hasta ahora, sí:
								Nucala® puede mantener bajo control sus síntomas
								y mejorar su función pulmonar.
							</p>
							<p>
								Además, en pacientes como María, que aparte del
								asma presentan rinosinusitis crónica con pólipos
								nasales como comorbilidad…
							</p>
							<div>
								<img
									className="grow-animation cursor-pointer pt-5"
									src={iconoClickExtra}
									width={125}
									onClick={async () => {
										await submitAnswer();
										navigate(NAVIGATE_TO_EXTRA);
									}}
								></img>
							</div>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{ position: "absolute", bottom: "10%" }}
					>
						<div className="d-flex justify-content-end">
							<div>
								<NextButton
									navigateTo={ROUTES.TRANSITION_SCENARIOS}
									navigateToAfter={ROUTES.P7_INTRO}
								></NextButton>
							</div>
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(Pregunta6Superada);
