import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { postIndividualParticipant } from "../api/participant";
import logoGSK from "../assets/logo-gsk.svg";
import logo from "../assets/logov2.svg";
import icofoto from "../assets/register/ico-foto.svg";
import { setGame, setGroup } from "../redux/actions";
import { useLang } from "../translations/i18n";
import {
	LONG_GAME_DURATION,
	POLITICA_PRIVACIDAD_URL,
	ROUTES,
} from "../utils/constants";
import { setParticipantId, updateStats } from "../utils/helpers";
import { alertError, alertSuccess } from "../utils/logger";

function getEmptyParticipant(duration) {
	return {
		name: "",
		apellidos: "",
		email: "",
		confirmEmail: "",
		hospital: "",
		game: null,
		image: "",
		individual: true,
		longMode: duration === LONG_GAME_DURATION,
	};
}

function Register({ intl }) {
	const [activeGame, setActiveGame] = useState(null);
	const [isProfessionalChecked, setIsProfessionalChecked] = useState(false);
	const [conditionsAccepted, setConditionsAccepted] = useState(false);

	const [selectedImage, setSelectedImage] = useState(null);
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();

	const [participant, setParticipant] = useState(
		getEmptyParticipant(prevState?.state?.duration)
	);

	function saveParticipant(e) {
		e.preventDefault();
		if (
			!participant.name ||
			!participant.apellidos ||
			!participant.email ||
			!participant.confirmEmail ||
			!participant.hospital
		) {
			alertError({
				error: " ",
				customMessage:
					"Complete adecuadamente todos los campos del registro",
			});
			return;
		}

		if (participant.email !== participant.confirmEmail) {
			alertError({
				error: " ",
				customMessage: "Los email no coinciden",
			});
			return;
		}

		if (!conditionsAccepted || !isProfessionalChecked) {
			alertError({
				error: " ",
				customMessage: "Debe aceptar las condiciones",
			});
			return;
		}

		postIndividualParticipant(participant, selectedImage)
			.then((res) => {
				if (res.status === 201) {
					let resParticipant = res.data;
					setParticipant(getEmptyParticipant());
					alertSuccess({
						title: "!Su registro se ha completado con éxito!",
					}).then((res) => {
						if (res.isConfirmed) {
							resParticipant = {
								...resParticipant,
								score: 0,
								time: 0,
								penalty: 0,
								bonus: 0,
							};
							setParticipantId(resParticipant?._id);
							dispatch(setGroup(resParticipant));
							dispatch(setGame(resParticipant?.game));

							updateStats({
								...resParticipant,
								lastVisited: ROUTES.VIDEO,
							});
							navigate(ROUTES.VIDEO);
						}
					});
				}
			})
			.catch((error) => {
				alertError({
					error: " ",
					customMessage: error?.response?.data?.message || " ",
				});
			});
	}

	const handleChange = (element) => (event) => {
		setParticipant({ ...participant, [element]: event.target.value });
	};

	return (
		<div className="pt-4 h-100">
			<div className="container d-flex justify-content-between legalGebro pb-3">
				<div>
					<img src={logo} alt="gsk" className="img-fluid mw170" />
				</div>
				<div>
					<img
						src={logoGSK}
						alt="20 years Gebro Pharma"
						className="img-fluid"
					/>
				</div>
			</div>
			<div className="container h-100">
				<div className="row pt-2">
					<div className="col-12 py-2">
						<h4 className="text-white">
							Para <strong>acceder al juego</strong> debes
							introducir los siguientes{" "}
							<strong>datos de registro</strong>:
						</h4>
					</div>
					<div className="col-3">
						<div className="text-white">
							<div className="bold">Hazte una foto</div>
							<div className="txt13 my-2">(opcional)</div>
						</div>
						<div className="fotoButton mt-3 image-upload text-center bg-transparent">
							<label htmlFor={"file-input"}>
								<img
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: icofoto
									}
									alt="Hazte una foto"
									className={`img-fluid cursor-pointer icofoto ${
										selectedImage ? "icofoto-selected" : ""
									}`}
								/>
							</label>
							<input
								id={"file-input"}
								name={"file-input"}
								type="file"
								accept="image/*"
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
						</div>
					</div>
					<div className="col-9">
						<div className="container">
							<div className="row">
								<div className="col-6">
									<div className="text-white my-2 bold">
										Nombre
									</div>
									<input
										id={`nombre`}
										autoComplete="off"
										InputLabelProps={{
											shrink: true,
										}}
										onChange={handleChange("name")}
										placeholder="Escribe aquí tu nombre"
										required
										className="form-control"
									/>
								</div>
								<div className="col-6">
									<div className="text-white my-2 bold">
										Apellidos
									</div>
									<input
										id={`fullName`}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={handleChange("apellidos")}
										placeholder="Escribe aquí tus apellidos"
										required
										className="form-control"
									/>
								</div>
								<div className="col-6">
									<div className="text-white my-2 bold">
										Email
									</div>
									<input
										id={`email`}
										autoComplete="off"
										InputLabelProps={{
											shrink: true,
										}}
										onChange={handleChange("email")}
										placeholder="Escribe aquí el email"
										required
										className="form-control"
									/>
								</div>
								<div className="col-6">
									<div className="text-white my-2 bold">
										Confirma tu email
									</div>
									<input
										id={`confirmEmail`}
										autoComplete="off"
										InputLabelProps={{
											shrink: true,
										}}
										onChange={handleChange("confirmEmail")}
										placeholder="Escribe aquí el email"
										required
										className="form-control"
									/>
								</div>
								<div className="col-6">
									<div className="text-white my-2 bold">
										Hospital
									</div>
									<input
										id={`hospital`}
										autoComplete="off"
										InputLabelProps={{
											shrink: true,
										}}
										onChange={handleChange("hospital")}
										placeholder="Escribe el nombre del hospital"
										required
										className="form-control"
									/>
								</div>
							</div>
							<div className="row my-4 d-flex align-items-end">
								<div className="col-9 text-white">
									<div className="legal position-relative mb-2">
										<input
											type="checkbox"
											onChange={(e) => {
												setIsProfessionalChecked(
													e.target.checked
												);
											}}
											className="check position-absolute"
										/>{" "}
										<div>
											Declaro que soy profesional de la
											salud
										</div>
									</div>
									<div className="legal position-relative">
										<input
											type="checkbox"
											onChange={(e) => {
												setConditionsAccepted(
													e.target.checked
												);
											}}
											className="check position-absolute"
										/>{" "}
										<div>
											Declaro haber sido informado acerca
											del tratamiento de mis datos de
											acuerdo con lo previsto en la{" "}
											<a
												style={{ cursor: "pointer" }}
												onClick={(event) => {
													event.stopPropagation();
													window.open(
														POLITICA_PRIVACIDAD_URL,
														"_blank"
													);
												}}
												className="text-white"
											>
												política de privacidad
											</a>
										</div>
									</div>
								</div>
								<div className="col-3 d-flex justify-content-end">
									<div>
										<Link
											to={ROUTES.VIDEO}
											className="btn-registro"
											onClick={saveParticipant}
										>
											{"REGISTRARSE"}
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 text-white">
						¿Dudas, problemas? Envíenos su consulta a{" "}
						<a
							href="mailto:info@exploringil5.com"
							className="text-yellow"
						>
							info@exploringil5.com
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Register);
