import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import iconoDudaRojo from "../../assets/icono-duda-rojo.png";
import maria from "../../assets/maria-tosiendo-small.svg";
import LifeIndicatorBar from "../../components/LifeIndicatorBar";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalBiblioReferences } from "../../utils/logger";

function Pregunta1ExplicacionExtra({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();
	const location = useLocation();

	const [intentosRestantes, setIntentosRestantes] = useState(4);

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Hammad H, Lambrecht BN.
					<span class="regular">
						The basic immunology of asthma. Cell.
						2021;184(6):1469-85.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Persson EK, Verstraete K, Heyndrickx I, et al.
					<span class="regular">
						Protein crystallization promotes type 2 immunity and is
						reversible by antibody treatment. Science.
						2019;24;364(6442):eaaw4295.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Pelaia C, Paoletti G, Puggioni F, et al.
					<span class="regular">
						Interleukin-5 in the Pathophysiology of Severe Asthma.
						Front Physiol. 2019;10:1514.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Acharya KR, Ackerman SJ.
					<span class="regular">
						Eosinophil granule proteins: form and function. J Biol
						Chem. 2014;289(25):17406-15.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Lu Y, Huang Y, Li J, et al.
					<span class="regular">
						Eosinophil extracellular traps drive asthma progression
						through neuro-immune signals. Nat Cell Biol.
						2021;23(10):1060-72.
					</span>
				</span>
			</li>
		</ol>`;

	return (
		<div className="h-100 bg-ampliar-info">
			<div className="container">
				<div className="row h-100">
					<div className="col-10">
						<div className="d-flex align-items-center">
							<img src={iconoDudaRojo} width={56}></img>
							<div className="ms-3">
								<div className="text-red bold">Has ganado</div>
								<div className="bg-points-info">+25 puntos</div>
							</div>
						</div>
						<div className="d-flex align-items-center mt-4">
							<Image
								className="bg-white pt-4"
								src={maria}
								width={250}
								roundedCircle
							></Image>
							<div className="ms-4">
								<p>
									Estas son algunas de las{" "}
									<span className="bold">
										posibles explicaciones del empeoramiento
										de María:
									</span>
								</p>
								<p>
									<span className="bold">
										Aumento de la inflamación T2:
									</span>
								</p>
								<div>
									<div className="row">
										<div className="col-1 w-fit-content">
											•
										</div>
										<div className="col-11">
											Inmunidad adaptativa: podría estar
											implicada<sup>1,2</sup>.
										</div>
									</div>
									<div className="row">
										<div className="col-1 w-fit-content">
											•
										</div>
										<div className="col-11">
											Activación de la respuesta inmune
											innata: más inflamación para la
											paciente; estamos, ya no ante una
											cascada, sino ante un «ciclo
											vicioso»<sup>1</sup>.
										</div>
									</div>
									<div className="row">
										<div className="col-1 w-fit-content">
											•
										</div>
										<div className="col-11">
											Aumento de IL53.
										</div>
									</div>
									<div className="row">
										<div className="col-1 w-fit-content">
											•
										</div>
										<div className="col-11">
											Cierto grado de remodelado: refleja
											el aumento de IL5 e IL13
											<sup>4,5</sup>.{" "}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="mt-4">
							<div className="bold">Aumento de IL5:</div>
							<div className="row">
								<div className="col-1 w-fit-content">•</div>
								<div className="col-11">
									Parece claro el efecto del aumento de la
									producción de IL5, principal citocina
									responsable del crecimiento, diferenciación,
									reclutamiento, activación y supervivencia de
									los eosinófilos<sup>3</sup>. De ahí la
									eosinofilia de María en el último año.
								</div>
							</div>
						</div>
					</div>
					<div className="col-2">
						<div className="d-flex flex-column align-items-end">
							<LifeIndicatorBar
								numIntentos={intentosRestantes}
							></LifeIndicatorBar>
						</div>
					</div>
				</div>
				<div
					className="container ps-0 pe-4"
					style={{ position: "absolute", bottom: "10vh" }}
				>
					<div className="d-flex justify-content-between align-items-center">
						<div>
							<div
								className="txt12 underline text-red cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									modalBiblioReferences({
										referencesHtml: REFERENCES,
									});
								}}
							>
								Ver referencias
							</div>
						</div>
						<div>
							<NextButton
								navigateTo={ROUTES.P1_EXTRA}
							></NextButton>
						</div>
					</div>
				</div>
			</div>
			<ScenarioBar
				scenarioNumber={getScenarioByScreen(location.pathname)}
			></ScenarioBar>
		</div>
	);
}

export default injectIntl(Pregunta1ExplicacionExtra);
