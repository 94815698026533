import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "./App";
import End from "./pages/End";
import EndQR from "./pages/EndQR";
import FinalScore from "./pages/FinalScore";
import FinesJuego from "./pages/FinesJuego";
import Home from "./pages/Home";
import IndividualOrGrupal from "./pages/IndividualOrGrupal";
import Instructions from "./pages/Instructions";
import Ranking from "./pages/Ranking";
import Register from "./pages/Register";
import RegisterGroup from "./pages/RegisterGroup";
import Results from "./pages/Results";
import ShortOrLongMode from "./pages/ShortOrLongMode";
import Start from "./pages/Start";
import StartPacienteHistorial from "./pages/StartPacienteHistorial";
import TransitionScenarios from "./pages/TransitionScenarios";
import Video from "./pages/Video";
import IntroPregunta1 from "./pages/pregunta1/IntroPregunta1";
import Pregunta1 from "./pages/pregunta1/Pregunta1";
import Pregunta1Explicacion from "./pages/pregunta1/Pregunta1Explicacion";
import Pregunta1ExplicacionExtra from "./pages/pregunta1/Pregunta1ExplicacionExtra";
import Pregunta1Extra from "./pages/pregunta1/Pregunta1Extra";
import Pregunta1ExtraVF from "./pages/pregunta1/Pregunta1ExtraVF";
import Pregunta1ExtraVFExtra from "./pages/pregunta1/Pregunta1ExtraVFExtra";
import Pregunta1Part2 from "./pages/pregunta1/Pregunta1Part2";
import Pregunta1Superada from "./pages/pregunta1/Pregunta1Superada";
import IntroPregunta2 from "./pages/pregunta2/IntroPregunta2";
import Pregunta2 from "./pages/pregunta2/Pregunta2";
import Pregunta2Extra from "./pages/pregunta2/Pregunta2Extra";
import Pregunta2Part2 from "./pages/pregunta2/Pregunta2Part2";
import Pregunta2Solucion from "./pages/pregunta2/Pregunta2Solucion";
import Pregunta2Superada from "./pages/pregunta2/Pregunta2Superada";
import IntroPregunta3 from "./pages/pregunta3/IntroPregunta3";
import Pregunta3 from "./pages/pregunta3/Pregunta3";
import Pregunta3Explicacion from "./pages/pregunta3/Pregunta3Explicacion";
import Pregunta3Solucion from "./pages/pregunta3/Pregunta3Solucion";
import Pregunta3Superada from "./pages/pregunta3/Pregunta3Superada";
import IntroPregunta4 from "./pages/pregunta4/IntroPregunta4";
import Pregunta4 from "./pages/pregunta4/Pregunta4";
import Pregunta4Explicacion from "./pages/pregunta4/Pregunta4Explicacion";
import Pregunta4Extra from "./pages/pregunta4/Pregunta4Extra";
import Pregunta4ExtraVF from "./pages/pregunta4/Pregunta4ExtraVF";
import Pregunta4Solucion from "./pages/pregunta4/Pregunta4Solucion";
import Pregunta4Superada from "./pages/pregunta4/Pregunta4Superada";
import IntroPregunta5 from "./pages/pregunta5/IntroPregunta5";
import Pregunta5 from "./pages/pregunta5/Pregunta5";
import Pregunta5AmpliarInfo from "./pages/pregunta5/Pregunta5AmpliarInfo";
import Pregunta5Extra from "./pages/pregunta5/Pregunta5Extra";
import Pregunta5Superada from "./pages/pregunta5/Pregunta5Superada";
import IntroPregunta6 from "./pages/pregunta6/IntroPregunta6";
import Pregunta6 from "./pages/pregunta6/Pregunta6";
import Pregunta6Ampliar from "./pages/pregunta6/Pregunta6Ampliar";
import Pregunta6Explicacion from "./pages/pregunta6/Pregunta6Explicacion";
import Pregunta6Solucion from "./pages/pregunta6/Pregunta6Solucion";
import Pregunta6Superada from "./pages/pregunta6/Pregunta6Superada";
import IdeasClave from "./pages/pregunta7/IdeasClave";
import IntroPregunta7 from "./pages/pregunta7/IntroPregunta7";
import Pregunta7 from "./pages/pregunta7/Pregunta7";
import Pregunta7Solucion from "./pages/pregunta7/Pregunta7Solucion";
import Pregunta7Superada from "./pages/pregunta7/Pregunta7Superada";
import { ROUTES } from "./utils/constants";

export default function Rutas() {
	const isRegistered = useSelector((state) => state.group, shallowEqual);

	return (
		<BrowserRouter>
			<Routes>
				<Route path={ROUTES.HOME} element={<App />}>
					<Route path={ROUTES.HOME} element={<Home />} />
					<Route path={ROUTES.HOME_SHORT} element={<Home />} />
					<Route path={ROUTES.HOME_LONG} element={<Home />} />
					<Route
						path={ROUTES.INDIVIDUAL_GRUPAL}
						element={<IndividualOrGrupal />}
					/>
					<Route
						path={ROUTES.SHORT_OR_LONG}
						element={<ShortOrLongMode />}
					/>
					<Route
						path={ROUTES.REGISTER_INDIVIDUAL}
						element={<Register />}
					/>
					<Route
						path={ROUTES.REGISTER_GROUP}
						element={<RegisterGroup />}
					/>

					{/*---------*/}
					<Route
						path={ROUTES.HISTORIAL}
						element={<StartPacienteHistorial />}
					/>
					<Route
						path={ROUTES.INSTRUCTIONS}
						element={<Instructions />}
					/>

					{/* PREGUNTA 1*/}
					<Route
						path={ROUTES.P1_INTRO}
						element={<IntroPregunta1 />}
					/>
					<Route path={ROUTES.P1} element={<Pregunta1 />} />
					<Route
						path={ROUTES.P1_PART2}
						element={<Pregunta1Part2 />}
					/>
					<Route
						path={ROUTES.P1_EXPLICATION}
						element={<Pregunta1Explicacion />}
					/>
					<Route
						path={ROUTES.P1_EXPLICATION_EXTRA}
						element={<Pregunta1ExplicacionExtra />}
					/>
					<Route
						path={ROUTES.P1_EXTRA}
						element={<Pregunta1Extra />}
					/>
					<Route
						path={ROUTES.P1_EXTRA2}
						element={<Pregunta1Extra />}
					/>
					<Route
						path={ROUTES.P1_EXTRA_VF}
						element={<Pregunta1ExtraVF />}
					/>
					<Route
						path={ROUTES.P1_EXTRA_VF2}
						element={<Pregunta1ExtraVF />}
					/>
					<Route
						path={ROUTES.P1_EXTRA_VF_EXTRA}
						element={<Pregunta1ExtraVFExtra />}
					/>

					<Route
						path={ROUTES.P1_SUPERADA}
						element={<Pregunta1Superada />}
					/>

					{/* PREGUNTA 2*/}
					<Route
						path={ROUTES.P2_INTRO}
						element={<IntroPregunta2 />}
					/>
					<Route path={ROUTES.P2} element={<Pregunta2 />} />
					<Route
						path={ROUTES.P2_SOLUTION}
						element={<Pregunta2Solucion />}
					/>
					<Route
						path={ROUTES.P2_PART2}
						element={<Pregunta2Part2 />}
					/>
					<Route
						path={ROUTES.P2_EXTRA}
						element={<Pregunta2Extra />}
					/>
					<Route
						path={ROUTES.P2_SUPERADA}
						element={<Pregunta2Superada />}
					/>

					{/* PREGUNTA 3*/}
					<Route
						path={ROUTES.P3_INTRO}
						element={<IntroPregunta3 />}
					/>
					<Route
						path={ROUTES.P3_EXPLICATION}
						element={<Pregunta3Explicacion />}
					/>
					<Route path={ROUTES.P3} element={<Pregunta3 />} />
					<Route
						path={ROUTES.P3_SOLUTION}
						element={<Pregunta3Solucion />}
					/>
					<Route
						path={ROUTES.P3_SUPERADA}
						element={<Pregunta3Superada />}
					/>

					{/* PREGUNTA 4*/}
					<Route
						path={ROUTES.P4_INTRO}
						element={<IntroPregunta4 />}
					/>
					<Route
						path={ROUTES.P4_EXPLICATION}
						element={<Pregunta4Explicacion />}
					/>
					<Route path={ROUTES.P4} element={<Pregunta4 />} />
					<Route
						path={ROUTES.P4_SOLUTION}
						element={<Pregunta4Solucion />}
					/>
					<Route
						path={ROUTES.P4_EXTRA}
						element={<Pregunta4Extra />}
					/>
					<Route
						path={ROUTES.P4_EXTRA_VF}
						element={<Pregunta4ExtraVF />}
					/>
					<Route
						path={ROUTES.P4_SUPERADA}
						element={<Pregunta4Superada />}
					/>

					{/* PREGUNTA 5*/}
					<Route
						path={ROUTES.P5_INTRO}
						element={<IntroPregunta5 />}
					/>
					<Route path={ROUTES.P5} element={<Pregunta5 />} />
					<Route
						path={ROUTES.P5_EXTRA}
						element={<Pregunta5Extra />}
					/>
					<Route
						path={ROUTES.P5_AMPLIAR}
						element={<Pregunta5AmpliarInfo />}
					/>
					<Route
						path={ROUTES.P5_SUPERADA}
						element={<Pregunta5Superada />}
					/>

					{/* PREGUNTA 6*/}
					<Route
						path={ROUTES.P6_INTRO}
						element={<IntroPregunta6 />}
					/>
					<Route
						path={ROUTES.P6_EXPLICATION}
						element={<Pregunta6Explicacion />}
					/>
					<Route path={ROUTES.P6} element={<Pregunta6 />} />
					<Route
						path={ROUTES.P6_SOLUTION}
						element={<Pregunta6Solucion />}
					/>
					<Route
						path={ROUTES.P6_SUPERADA}
						element={<Pregunta6Superada />}
					/>
					<Route
						path={ROUTES.P6_AMPLIAR}
						element={<Pregunta6Ampliar />}
					/>

					{/* PREGUNTA 7*/}
					<Route
						path={ROUTES.P7_INTRO}
						element={<IntroPregunta7 />}
					/>
					<Route path={ROUTES.P7} element={<Pregunta7 />} />
					<Route
						path={ROUTES.P7_SOLUTION}
						element={<Pregunta7Solucion />}
					/>
					<Route
						path={ROUTES.P7_SUPERADA}
						element={<Pregunta7Superada />}
					/>

					<Route path={ROUTES.IDEAS_CLAVE} element={<IdeasClave />} />

					<Route path={ROUTES.END} element={<End />} />
					<Route path={ROUTES.FINAL_SCORE} element={<FinalScore />} />
					<Route path={ROUTES.RESULTS} element={<Results />} />
					<Route path={ROUTES.RANKING} element={<Ranking />} />

					<Route path={ROUTES.FINES_JUEGO} element={<FinesJuego />} />
					<Route path={ROUTES.END_QR} element={<EndQR />} />
					<Route path={ROUTES.START} element={<Start />} />
					<Route path={ROUTES.VIDEO} element={<Video />} />
					<Route
						path={ROUTES.TRANSITION_SCENARIOS}
						element={<TransitionScenarios />}
					/>

					{!isRegistered ? (
						/*Render register page when not registered.*/
						<Route
							path="*"
							element={<Navigate to={ROUTES.HOME} replace />}
						/>
					) : (
						<Route></Route>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	);
}
