import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ampliarFoto from "../../assets/ampliar-foto.png";
import fotoPregunta4 from "../../assets/foto-pregunta4.png";
import logo from "../../assets/logov3.svg";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { ROUTES } from "../../utils/constants";
import { getScenarioByScreen } from "../../utils/helpers";
import { modalBiblioReferences, modalImage } from "../../utils/logger";

function IntroPregunta4({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const REFERENCES = `<ol class="numbered-list">
			<li>
				<span class="reference-text">
					Tomassen P, Vandeplas G, Van Zele T, et al.
					<span class="regular">
						Inflammatory endotypes of chronic rhinosinusitis based
						on cluster analysis of biomarkers. J Allergy Clin
						Immunol. 2016;137(5):1449-56.e4.
					</span>
				</span>
			</li>
			<li>
				<span class="reference-text">
					Imagen extraída de: Jeffery PK, Haahtela T.
					<span class="regular">
						Allergic rhinitis and asthma: inflammation in a
						one-airway condition. BMC Pulm Med. 2006;6(Suppl 1):S5.
					</span>
				</span>
			</li>
		</ol>`;

	return (
		<>
			<div className="pt-4 h-100 bg-intro-prueba">
				<div className="container d-flex justify-content-between legalGebro pb-3">
					<div>
						<img src={logo} alt="gsk" className="img-fluid mw170" />
					</div>
				</div>
				<div className="container h-100">
					<h4 className="col-12 title d-flex align-items-center pt-3">
						<div className="question-num-bg">4</div>
						La IL5 en el eosinófilo
					</h4>
					<div className="row pt-3 d-flex align-items-start">
						<div className="col-7">
							<p className="bold txt18">
								Liberación de mediadores: redes de ADN
								<sup>1</sup>.
							</p>
							<div className="paciente-historial-text">
								<p>
									<span className="bold">
										María presenta una cierta disminución de
										la FVC (capacidad vital forzada)
									</span>
									, que podría estar en relación con el
									aumento de tapones mucosos en la vía aérea.
									También presenta aumento de la expectoración
									mucosa matutina y nocturna.
								</p>
								<p>
									Por otra parte, la{" "}
									<span className="bold">espirometría</span>{" "}
									de María también muestra una{" "}
									<span className="bold">
										caída del FEV1.
									</span>
								</p>
								<p className="bold">
									¿Cuáles pueden ser los mecanismos
									subyacentes al empeoramiento funcional de
									María durante este último año?
								</p>
							</div>
						</div>
						<div className="col-5 d-flex justify-content-end align-items-start">
							<div className="d-flex flex-column justify-content-center align-items-center">
								<img src={fotoPregunta4} width={390}></img>
								<img
									style={{ marginTop: "-20px" }}
									className="cursor-pointer"
									src={ampliarFoto}
									width={120}
									onClick={() =>
										modalImage({
											imageWidth: "95%",
											fullscreen: true,
											image: fotoPregunta4,
										})
									}
								></img>
							</div>
						</div>
					</div>
					<div
						className="container ps-0 pe-4"
						style={{ position: "absolute", bottom: "20%" }}
					>
						<div>
							<NextButton navigateTo={ROUTES.P4_EXPLICATION} />
						</div>
						<div
							className="txt12 underline text-red cursor-pointer mt-4"
							onClick={(e) => {
								e.stopPropagation();
								modalBiblioReferences({
									referencesHtml: REFERENCES,
								});
							}}
						>
							Ver referencias
						</div>
					</div>
				</div>
				<ScenarioBar
					scenarioNumber={getScenarioByScreen(location.pathname)}
					expanded={true}
				></ScenarioBar>
			</div>
		</>
	);
}

export default injectIntl(IntroPregunta4);
